import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../dialogctrl/dialogctrl';
import LoaderSpinnerCtrl from '../loaderspinnerctrl/loaderspinnerctrl';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch, { fetchData } from '../../customhooks/useFetch';

function LoginForm() {

    const [formData, setFormData] = useState({ userName: null, password: null });
    const [errorList, setErrorList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [loaderSchema, setLoaderSchema] = useState({});
    const navigate = useNavigate();


    return (
        <>
            <div className="segment-title" style={{ marginBottom: "5px", fontSize: "34px", textAlign: "center" }}>Login Here</div>
            <div style={{ marginBottom: "40px", textAlign: "center" }}><small>Enter your credentials to access your account</small></div>
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-12">
                                <TextField id="userName" autoFocus required fullWidth label="User Name" autoComplete='off' variant="outlined" size="medium" helperText={errorList['userName'] ? <span style={{ color: "red" }}>{errorList['userName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.userName || ''} InputLabelProps={{ shrink: formData.userName }} onChange={(e) => setFormData(formData => ({ ...formData, userName: e.target.value.toLowerCase() }))} />
                                <TextField id="password" required fullWidth type="password" autoComplete='off' label="Password" variant="outlined" size="medium" helperText={errorList['password'] ? <span style={{ color: "red" }}>{errorList['password']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.password || ''} InputLabelProps={{ shrink: formData.password }} onChange={(e) => setFormData(formData => ({ ...formData, password: e.target.value }))} onKeyPress={handlePasswordKeyPress} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <FormControlLabel control={<Checkbox />} label="Remember me" />
                            </div>
                            <div className="col-sm-6" style={{ textAlign: "right" }}>
                                <Button variant="text" style={{ fontSize: "14px", textTransform: "none" }} onClick={() => { navigate('/forgotpassword');}}>Forgot Password?</Button>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-12">
                                <Button variant="contained" size="large" fullWidth onClick={() => { validateLogin(); }}>LOGIN</Button>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: "20px", textAlign: "center" }}>
                            <div className="col-sm-12">
                                <p>Don't have an account? <a href="javascript:void(0)" onClick={() => { navigate('/createaccount'); }}>Sign Up</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );

    function handlePasswordKeyPress(event) {
        if (event.key === 'Enter') {
            validateLogin();
        }
    }

    function validateLogin() {
        if (isCustomFormValidationValid() == true) {
            fetchData({
                callName: "ValidateLogin",
                method: "GET",
                url: "security/user/login?username=" + formData.userName + "&password=" + formData.password,
                token: "fg1234",
                body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function onAPISuccess(data, header, callName) {
        switch (callName) {
            case "ValidateLogin":
                var isValid = true;
                var tempErrorList = {};
                if (data == null || data == '') {
                    isValid = false;
                    tempErrorList['userName'] = "User Name or password is invalid";
                    tempErrorList['password'] = "User Name or password is invalid";
                    setErrorList(tempErrorList);
                } else {
                    // set the state of the user
                    //setUser(data)
                    // store the user in localStorage
                    localStorage.setItem('fg-user', JSON.stringify(data))
                    if (data.currentBusinessAccountDTO.active == true) {
                        if (data.timezoneName != null && data.timezoneName != '') {
                            navigate('/welcome');
                        } else {
                            navigate('/userprofile');
                        }                        
                    } else {
                        navigate('/inactive');
                    }
                }
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error.response.data.message;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function isCustomFormValidationValid() {
        var isValid = true;
        var tempErrorList = {};
        if (formData.userName == null || formData.userName == '') {
            isValid = false;
            tempErrorList['userName'] = "User Name is required";
        }
        if (formData.password == null || formData.password == '') {
            isValid = false;
            tempErrorList['password'] = "Password is required";
        }
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default LoginForm;