import React, { useContext, useState, useEffect } from 'react';
import WidgetRouter, { BuildPropDefault, MergeStylesToCurrBP} from '../WidgetRouter';
import { DataBrokerContext } from "../PageBuilderCtrl";
import HoverCtrl, { OnWidgetClick, OnWidgetMouseEnter, OnWidgetMouseLeave, OnWidgetMouseOut, OnWidgetMouseOver, RenderWidgetLabel, RenderWidgetContextMenu } from '../HoverCtrl';

function DividerWidget({ params, tmpElements, currBP, }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseOver, onWidgetMouseLeave, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, handleUpdateChanges, handleMergeVariables } = useContext(DataBrokerContext);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [hoverSettings, setHoverSettings] = useState({ style: { pointerEvents: "none", position: "absolute", top: 0, left: 0, width: 0, height: 0, border: "1px solid #5E95DE" }, labelPosition: "top", labels: [], show: false });
    const [selectedSettings, setSelectedSettings] = useState({ style: { pointerEvents: "none", position: "absolute", top: 0, left: 0, width: 0, height: 0, border: "1px solid #5E95DE" }, labelPosition: "top", labels: [], show: false });
    const [widgetData, setWidgetData] = useState(params);


    var timer = 0;
    var delay = 100;
    var prevent = false;

    function handleOnClick(e, id, widgetData) {
        timer = setTimeout(() => {
            if (!prevent) {
                onWidgetClick(e, id, widgetData, (updatedWidgetData) => handleOnPropertyChange(updatedWidgetData), () => handleOnHideHighlight(), () => handleOnSave());
            }
        }, delay);
        if (e != undefined) {
            e.stopPropagation();
        }
    }

    function handleOnSave() {
        //setElements({
        //    ...elements, // copy the old fields
        //    [widgetData.id]: widgetData //override with updated target widget
        //});
        //console.log('save widget data')
    }


    function handleOnHideHighlight() {
        setSelectedSettings({
            ...selectedSettings, // copy the old fields
            show: false
        });
        setHoverSettings({
            ...hoverSettings, // copy the old fields
            show: false
        });
        setShowContextMenu(false);
    }

    function handleOnPropertyChange(updatedWidgetData) {
        setWidgetData((...widgetData) => { return updatedWidgetData });
        handleUpdateChanges(updatedWidgetData);
    }

    function handleOnSettingsClick() {
        alert('show settings');
    }
    
    return (
        <>     
            <div id={params.id} elementname={params.elementName} style={{padding:"5px 0px"}} className={"pb-widget"} onMouseEnter={(e) => { onWidgetMouseEnter(e, params.id); }} onMouseOver={(e) => { onWidgetMouseOver(e, params.id); }} onMouseLeave={(e) => { onWidgetMouseLeave(e, params.id); }} onClick={(e) => { handleOnClick(e, params.id, params); }} onDragStart={(e) => handleOnDragFromCanvas(e, params.id, "section", "widget")} onDragOver={(e) => { handleOnDragOver(e, params.id); }} onDragLeave={(e) => { handleOnDragLeave(e, params.id); }} onDrop={(e) => { handleOnDrop(e, params.id); }} >
                <div style={MergeStylesToCurrBP(params, currBP)} className={"pb-noclick " + params.className}>&nbsp;</div>
            </div>
        </>
    )
}

DividerWidget.getPropSchema = function () {
    return {
        "reactUseStates": BuildPropDefault("reactUseStates")
    };
};

export default DividerWidget;