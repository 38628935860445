import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../dialogctrl/dialogctrl';
import LoaderSpinnerCtrl from '../loaderspinnerctrl/loaderspinnerctrl';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch, { fetchData } from '../../customhooks/useFetch';
import { BAChangeDefaultHeaders } from '../../customhooks/useBAFetch';

function AcceptAccountInviteForm({iid}) {

    const navigate = useNavigate();
    const wlInfo = JSON.parse(localStorage.getItem("wl-info"));

    const [formData, setFormData] = useState({ firstName: null, lastName: null, email: null, phone: null, timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone, ccHolder: null, ccNumber: null, ccExpireDate: null, ccCVC: null });
    const [userData, setUserData] = useState({});
    const [errorList, setErrorList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [loaderSchema, setLoaderSchema] = useState({});
    const [status, setStatus] = useState("Open");

    function login() {
        fetchData({
            callName: "Login",
            method: "GET",
            url: "security/user/login/?username=" + userData.userName + '&password=' + userData.password,
            token: wlInfo.token,
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function createAccount() {
        if (isCustomFormValidationValid() == true) {
            var filtering = {
                "LogicalOperator": "and", "ConditionGroups": [{
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "username", "ConditionOperator": "EQUAL", "Value": formData.email, "DataType": "String" },
                        { "Name": "phone", "ConditionOperator": "EQUAL", "Value": formData.phone, "DataType": "String" }
                    ]
                }]
            };
            fetchData({
                callName: "ValidateLogin",
                method: "GET",
                url: "security/user/getlist/?filtering=" + JSON.stringify(filtering),
                token: wlInfo.token,
                body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "AcceptInvite":
                var isValid = true;
                var tempErrorList = {};
                if (data != null) {
                    if (data.userId != null) {
                        setUserData(data);
                        setStatus("Complete");
                    }
                }
                break;
            case "Login":
                localStorage.setItem('fg-user', JSON.stringify(data))
                navigate('/overview');
                break;
        }
    }

    function onAPIError(error, callName) {
        setStatus("Error");
    }

    function isCustomFormValidationValid() {
        var isValid = true;
        var tempErrorList = {};
        if (formData.email == null || formData.email == '') {
            isValid = false;
            tempErrorList['email'] = "Email is required";
        }
        if (formData.phone == null || formData.phone == '') {
            isValid = false;
            tempErrorList['phone'] = "Phone is required";
        }
        setErrorList(tempErrorList);
        return isValid;
    }

    useEffect(() => {

        var bamInviteId = iid.split("_")[0];
        var baid = iid.split("_")[1];

        
        if (bamInviteId != null) {
            fetchData({
                callName: "AcceptInvite",
                method: "POST",
                url: "security/businessaccount/acceptinvite/?iid=" + iid + '&timezonename=' + Intl.DateTimeFormat().resolvedOptions().timeZone,
                token: wlInfo.token,
                body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }, []);

    useEffect(() => {
        BAChangeDefaultHeaders(wlInfo.token);
    }, []);

    return (
        <>

            <div className="segment-title" style={{ marginBottom: "5px", fontSize: "34px", textAlign: "center" }}>Account Invite</div>
            {status == "Open" ?
                <div>
                    <div style={{ marginBottom: "40px", textAlign: "center" }}><small>Someone invited you to join <span>ACME Corp.</span> Business Account. Fill out the form below to accept.</small></div>
                    <div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <TextField id="firstName" autoFocus required fullWidth label="First Name" variant="outlined" size="medium" helperText={errorList['firstName'] ? <span style={{ color: "red" }}>{errorList['firstName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.firstName || ''} InputLabelProps={{ shrink: formData.firstName }} onChange={(e) => setFormData(formData => ({ ...formData, firstName: e.target.value }))} />
                                        <TextField id="lastName" required fullWidth label="Last Name" variant="outlined" size="medium" helperText={errorList['lastName'] ? <span style={{ color: "red" }}>{errorList['lastName']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.lastName || ''} InputLabelProps={{ shrink: formData.lastName }} onChange={(e) => setFormData(formData => ({ ...formData, lastName: e.target.value }))} />
                                        <TextField id="email" required fullWidth label="Email" variant="outlined" size="medium" helperText={errorList['email'] ? <span style={{ color: "red" }}>{errorList['email']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.email || ''} InputLabelProps={{ shrink: formData.email }} onChange={(e) => setFormData(formData => ({ ...formData, email: e.target.value.toLowerCase() }))} />
                                        <TextField id="phone" required fullWidth type="First Name" autoComplete="current-phone" label="Phone" variant="outlined" size="medium" helperText={errorList['phone'] ? <span style={{ color: "red" }}>{errorList['phone']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.phone || ''} InputLabelProps={{ shrink: formData.phone }} onChange={(e) => setFormData(formData => ({ ...formData, phone: e.target.value }))} />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-sm-12">
                                        <Button variant="contained" size="large" fullWidth onClick={() => { createAccount(); }}>ACCEPT</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}

            {status == "Complete" ?
                <div>
                    <div style={{ marginBottom: "40px", textAlign: "center", fontSize: "24px" }}>You're all set. Click the login button below</div>
                    <div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-sm-12">
                                        <Button variant="contained" size="large" fullWidth onClick={() => { login(); }}>LOGIN</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}

            {status == "Expired" ?
                <div>
                    <div style={{ marginBottom: "40px", textAlign: "center", fontSize: "24px", color:"red" }}>Invite has expired.</div>
                </div> : null}
            {status == "Error" ?
                <div>
                    <div style={{ marginBottom: "40px", textAlign: "center", fontSize: "20px", color: "red" }}>This invite doesn't exist or there is another problem that is causing error. Please contact administrator.</div>
                </div> : null}

            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
    );

}

export default AcceptAccountInviteForm;