import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../dialogctrl/dialogctrl';
import FileManager from '../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import 'bootstrap/dist/css/bootstrap.min.css';

function ImageFieldCtrl({imgSrc, imgType, onChange, label, returnFullPath, imgPath, imgWidth, imgHeight, recType, recId }) {

    const [imgUrl, setImgUrl] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    function iconOnly() {
        var answer = false;
        if (imgType == 'icon') {
            answer = true;
        }
        return answer;
    }

    function openFileManager() {
        var actionType = "FullPath";
        var msg = '';
        setGenericDialogSchema({ title: 'File Manager', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={setReturnValue} returnFullPath={returnFullPath} recType={recType} recId={recId} iconOnly={iconOnly()}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleUpdateImage(returnValues, actionType); } })
        setOpenDialog(true);
    }

    function handleOnOtherChange(value) {
        if (imgType != 'icon') {
            setImgUrl((...imgUrl) => { return imgPath + value });
        } else {
            setImgUrl((...imgUrl) => { return value });
        }
        
        onChange(value);
    }

    function handleUpdateImage(imgUrl, actionType) {
        handleOnOtherChange(imgUrl);
    }

    function removeImage() {
        onChange('');
    }

    useEffect(() => {
        if (imgSrc != '') {
            var path = (imgPath != undefined ? imgPath : '');
            setImgUrl(path + imgSrc);
        } else {
            setImgUrl(imgSrc);
        }        
    }, [imgSrc])

    return (
        <>
            {label != "" ?
                <div style={{ fontSize: "12px", marginBottom: "6px", lineHeight: "100%", color:"rgba(0, 0, 0, 0.6)"}}>{label}</div>
                : null}
            {imgType == "icon" ?
                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px 30px", borderBottom: "1px solid #c0c0c0", padding: "0px" }}>
                    <div style={{ height: "30px", width: "30px", border: "1px solid #c0c0c0" }}>
                        <span className={imgUrl} style={{fontSize:"22px",paddingTop:"4px"}}></span>
                    </div>
                    <div><input className="form-control form-control fg-pb-property-field" value={imgUrl || ''} onChange={(e) => { handleOnOtherChange(e.target.value); }} style={{ borderRadius: "0px", border: "none", height: "30px", paddingLeft: "5px" }} /></div>
                    <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }} onClick={() => { openFileManager(); }}><MoreHorizIcon /></div>
                    <div style={{ textAlign: "right", color: "#757575", cursor: "pointer" }} onClick={(e) => { removeImage(e); }}><CloseIcon /></div>
                </div>
                : null}
            {imgType == "inline" ?
                <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 30px 30px", borderBottom: "1px solid #c0c0c0", padding: "0px" }}>
                    <div style={{ height: "30px", width: "30px", backgroundImage: "url(" + imgUrl + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", border: "1px solid #c0c0c0" }}>&nbsp;</div>
                    <div><input className="form-control form-control fg-pb-property-field" value={imgUrl || ''} onChange={(e) => { handleOnOtherChange(e.target.value); }} style={{ borderRadius: "0px", border: "none", height: "30px", paddingLeft: "5px" }} /></div>
                    <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }} onClick={() => { openFileManager(); }}><MoreHorizIcon /></div>
                    <div style={{ textAlign: "right", color: "#757575", cursor: "pointer" }} onClick={(e) => { removeImage(e); }}><CloseIcon /></div>
                </div>
                : null}
            {imgType == "large" ?
                <>
                    <div>
                        <div style={{ height: imgHeight  , width: imgWidth, backgroundImage: "url(" + imgUrl + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", border: "1px solid #c0c0c0" }}>&nbsp;</div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 30px 30px", borderBottom: "1px solid #c0c0c0", padding: "10px",marginBottom:"20px" }}>                       
                        <div><input className="form-control form-control fg-pb-property-field" value={imgUrl || ''} onChange={(e) => { handleOnOtherChange(e.target.value); }} style={{ borderRadius: "0px", border: "none", height: "30px", paddingLeft: "5px" }} /></div>
                        <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }} onClick={() => { openFileManager(); }}><MoreHorizIcon /></div>
                        <div style={{ textAlign: "right", color: "#757575", cursor: "pointer" }} onClick={(e) => { removeImage(e); }}><CloseIcon /></div>
                    </div>
                </>
                : null}
            {imgType == "sm-img-only" ?
                <div style={{ height: "30px", width: "30px", backgroundImage: "url(" + imgUrl + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", border: "1px solid #c0c0c0" }}>&nbsp;</div>
            : null}
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );
}

export default ImageFieldCtrl;