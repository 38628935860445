import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import './css/ctrlpagetitle.css';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import helpers from '../../../helpers/Helpers';





export default function CtrlPageTitle({ pageTitle, recordText, headerOptions }) {
    const myContent = '';

    var Link = require('react-router-dom').Link
    //var i;
    //for (i = 0; i < headerOptions.elements.length; i++) {
    //    var element = headerOptions.elements[i]
    //    switch (element.type) {
    //        case "button":
    //            myContent += <button type="button" className="btn btn-primary fg-btn-new"><i className="bi bi-plus-lg" />New</button>
    //            break;
    //    }
    //}
    var counter = 1;

    const handleClose = (menuItem) => {
        //setAnchorEl(null);
        if (menuItem.url != null) {
            window.open(menuItem.url, menuItem.target);
        } else if (menuItem.function != null) {
            menuItem.function();
        }        
    };

    function buildElement(myElement) {
        let classNameInfo = ""
        let dropdownClassNames = "";
        let dropdownBtnClassNames = "";

        switch (myElement.type) {
            case "button":
                classNameInfo = "btn fg-app-page-btn fg-btn-new " + myElement.color;
                //var id = helpers.getUUID();
                //var hide = false;
                //if (myElement.hasOwnProperty('hide') == true) {
                //    hide = myElement.hide;
                //}
                //if (myElement.hasOwnProperty('name') == true) {
                //    id = myElement.name.replaceAll(" ", "_");
                //}
                return (
                    <>
                        {myElement.url != null &&
                            <Link tag={Link} className={classNameInfo} to={myElement.url}><i className={myElement.icon} />{myElement.text}</Link>
                        }
                        {myElement.function != null &&
                                <button type="button" className={classNameInfo} onClick={myElement.function}><i className={myElement.icon} />{myElement.text}</button>
                        }
                        {(myElement.function == null && myElement.url == null) &&
                                <button type="button" className={classNameInfo}><i className={myElement.icon} />{myElement.text}</button>
                        }
                    </>
                    )
            case "dropdown":
                //classNameInfo = "btn dropdown-toggle " + myElement.color;
                classNameInfo = myElement.color;
                var counter = 0;
                var tmpKey = null;

                return (
                    <>

                        <Dropdown style={{ display: "inline-block", marginLeft: "10px" }} className={classNameInfo + (myElement.dropdownClassNames != undefined ? " " + myElement.dropdownClassNames  : "")}>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" className={(myElement.dropdownBtnClassNames != undefined ? " " + myElement.dropdownBtnClassNames : "")}>
                                {myElement.text}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {myElement.elements.map(element => {
                                    counter++;
                                    tmpKey = element.name.replaceAll(" ", "_");
                                    if (element.url != null) {
                                        return <Dropdown.Item key={tmpKey} onClick={() => handleClose(element)}>{element.text}</Dropdown.Item>
                                        //return <li key={tmpKey}><Link className="dropdown-item fg-dropdown-item" to={element.url}>{element.text}</Link></li>
                                    } else if (element.function != null) {
                                        return <Dropdown.Item key={tmpKey} onClick={() => handleClose(element)}>{element.text}</Dropdown.Item>
                                        //return <li key={tmpKey}><div className="dropdown-item fg-dropdown-item" onClick={element.function}>{element.text}</div></li>
                                    } else {
                                        return <Dropdown.Item key={tmpKey} href={element.url}>{element.text}</Dropdown.Item>
                                        //return <li key={tmpKey}><div className="dropdown-item fg-dropdown-item">{element.text}</div></li>
                                    }

                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                )
        }
    }

    function buildBreadcrumbs(headerOptions) {
        let counter = 0;
        let delimeter = "";
        if (headerOptions.hasOwnProperty("breadcrumbs")) {
            if (headerOptions.breadcrumbs.length > 0) {
                return (

                    <div className="fg-breadcrumbs">
                        {headerOptions.breadcrumbs.map(breadcrumb => {
                            counter++;
                            if (counter > 1) delimeter = " > ";
                            if (breadcrumb.url != null) {
                                return <span key={counter} >{delimeter}<Link tag={Link} to={breadcrumb.url} >{breadcrumb.name}</Link></span>
                            } else {
                                return <span key={counter} >{delimeter}{breadcrumb.name}</span>
                            }
                        })}
                    </div>
                )
            }
        }
    }
    //useEffect(() => {
    //    setheaderOptions(headerOptions);
    //}, [headerOptions]);

        return (
            <>
                <div className="fg-app-page-header-box fg-shadow-1">
                    <div className="fg-app-page-title-box">
                        <h3 className="fg-app-page-title">{pageTitle}</h3>
                        {recordText != null ?
                            <small style={{ fontSize: "14px" }}>{recordText}</small>
                            : null}
                        {/*{buildBreadcrumbs(headerOptions)}*/}
                    </div>
                    <div className="fg-app-page-btn-box">
                        {headerOptions.elements.map((myElement, index) => {
                            counter++;
                            return <React.Fragment key={index}>{buildElement(myElement)}</React.Fragment>;
                        })}  
                    </div>
                </div>
            </>
        );
}
