import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import MasterVariantsCtrl from '../components/formcontrols/MasterVariantsCtrl';
import ContactSearch from './ContactSearch';
import LookupFieldCtrl from '../components/formcontrols/LookupFieldCtrl';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TransactionDetailCtrl from '../components/formcontrols/TransactionDetailCtrl';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';
import { useGlobalState } from '../globalstates/GState';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function Invoice({dialogType, handlerReturnValue, headerId, setAcceptAfterUpdate, contactId }) {
    const [currentUser] = useGlobalState("userInfo");

    const navigate = useNavigate();
    let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());
    const [refreshId, setRefreshId] = useState(0);
    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState({});

    const [primaryKeyValue, setPrimaryKeyValue] = useState(dialogType == undefined ? params.headerId : headerId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = {
        "LogicalOperator": "and", "ConditionGroups": [{
            "LogicalOperator": "and", "Conditions": [
                { "Name": "object", "ConditionOperator": "EQUAL", "Value": "product_type_settings_page", "DataType": "String" },
                { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }]
        }]
    };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('product_type_settings_page', 'header_section', 'Invoice');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (primaryKeyValue != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('product_type_settings_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: [
                    
                    
                ]
            }
        )
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('product_type_settings_page', 'header_section', 'Customer View'), name: "CustomerView", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleCustomerViewTransaction(); } });
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('product_type_settings_page', 'header_section', 'Re-send Email'), name: "ResendEmail", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleResendEmail(); } });
        if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Invoices', ['allowEdit']) == true) {
            headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('product_type_settings_page', 'header_section', 'Make Payment'), name: "MakePayment", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleMakePayment(); } });
        }
        if (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Invoices', ['allowDelete']) == true) {
            headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('product_type_settings_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
        }
    }
    if ((helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Invoices', ['allowEdit']) == true) || (helpers.permissionAllowed(currentUser.permissions, 'ECommerce-Invoices', ['allowAdd']) == true && primaryKeyValue == 0)) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('automations_search_page', 'header_section', 'Save'), name: "Save", icon: null, url: null, dropdownClassNames: "fg-save-dropdown", dropdownBtnClassNames: "fg-save-dropdown-btn", color: "btn-success", elements: [
                    { type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Save as Draft'), name: "SaveAsDraft", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { handleSaveForm(false, false) } },
                    { type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Save and Post'), name: "SaveAndPost", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { handleSaveForm(true, false) } },
                    { type: "dropdown", text: getTranslation('segmented_list_search_page', 'header_section', 'Save and Email'), name: "SaveAndEmail", icon: "", url: null, target: "_self", color: "btn-outline-secondary", options: null, function: () => { handleSaveForm(false, true) } }
                ]
            }
        )
    }
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == '0') {
            data.transactionType = 'invoice';
            data.status = "Draft";
            data.headerAmt = parseFloat(0);
            data.transactionDate = dayjs();
            data.shippingAmt = parseFloat(0);
            data.discountAmt = parseFloat(0);
            data.taxPercent = parseFloat(0);
        }
    }

    function handleResendEmail() {
        emailTransaction(formData.headerId);
    }

    function handleCustomerViewTransaction() {
        var url = '/transactionviewer/invoice/' + currentUser.currentBusinessAccountDTO.token + '/' + formData.token;
        window.open(url, '_blank');
    }

    function handleMakePayment() {

    }

    function refreshPage() {
        setFormData({});
        setRefreshId(helpers.getUUID());
    }

    function openContactSearch(actionType) {
        var msg = '';
        setGenericDialogSchema({ title: 'Contact Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <ContactSearch handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></ContactSearch>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('GetCustomerInfo', returnValues); } })
        setOpenDialog(true);
    }

    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case "GetCustomerInfo":
                BAFetchData({
                    callName: "GetContactLookup",
                    method: "GET",
                    url: "crm/contact/getrecord?contactid=" + returnValues,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
                break;
        }
    }

    function handleDetailsChanged(details) {
        var tmpFormData = { ...formData };
        tmpFormData.details = details;
        updateTotals(tmpFormData);
        setFormData(tmpFormData);
    }

    function onFieldBlur(e, propertyName, value) {
        var tmpFormData = { ...formData };

        switch (propertyName) {
            case 'billingAddress1':
            case 'billingAddress2':
            case 'billingCity':
            case 'billingState':
            case 'billingZip':
            case 'billingCountryCode':
                if (tmpFormData.copyBillingAddress == true) {
                    copyBillingAddress(tmpFormData);
                }
                break;
        }

        updateTotals(tmpFormData);
        setFormData(tmpFormData);
    }

    function onFieldValueChange(e, propertyName, value) {
        var tmpFormData = { ...formData };
        tmpFormData[propertyName] = value;
        setFormData(tmpFormData);
    }

    function onCopyBillingAddressChange(e, value) {
        var tmpFormData = { ...formData };        
        tmpFormData.copyBillingAddress = value;
        if (value == true) {
            copyBillingAddress(tmpFormData);
        }
        setFormData(tmpFormData);

    }

    function updateTotals(tmpFormData) {
        var detail = null;
        var netAmt = parseFloat(0);
        var amtToTax = parseFloat(0);
        var taxAmt = parseFloat(0);
        var totalAmtDue = parseFloat(0);


        for (var i = 0; i < tmpFormData.details.length; i++) {
            detail = tmpFormData.details[i];
            if (detail.internalState != 'deleted') {
                netAmt += parseFloat(detail.detailAmount);
                if (detail.taxable == true) {
                    amtToTax += parseFloat(detail.detailAmount);
                }
            }
        }


        tmpFormData.netAmt = parseFloat(netAmt.toFixed(2));
        tmpFormData.amtToTax = parseFloat(amtToTax.toFixed(2));
        if (tmpFormData.taxPercent > 0) {
            taxAmt = (amtToTax * (parseFloat(tmpFormData.taxPercent) / 100));
        }
        tmpFormData.taxAmt = parseFloat(taxAmt.toFixed(2));
        totalAmtDue = (netAmt + taxAmt + parseFloat(tmpFormData.shippingAmt)) - (parseFloat(tmpFormData.discountAmt));
        
        tmpFormData.headerAmt = parseFloat(totalAmtDue.toFixed(2));
    }

    function copyBillingAddress(tmpFormData) {
        tmpFormData.mailingAddress1 = tmpFormData.billingAddress1;
        tmpFormData.mailingAddress2 = tmpFormData.billingAddress2;
        tmpFormData.mailingCity = tmpFormData.billingCity;
        tmpFormData.mailingState = tmpFormData.billingState;
        tmpFormData.mailingZip = tmpFormData.billingZip;
        tmpFormData.mailingCountryCode = tmpFormData.billingCountryCode;
    }

    function postTransaction(tmpFormData, sendEmail, headerId) {
        BAFetchData({
            callName: "PostTransaction", method: "PUT", url: "accounting/transactionheader/posttransaction",
            token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError,
            packageObj: { "sendEmail":sendEmail,"headerId": headerId }
        });
    }

    function emailTransaction(headerId) {
        BAFetchData({
            callName: "EmailTransaction", method: "GET", url: "accounting/transactionheader/emailtransaction?headerid=" + headerId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError,
            packageObj: { "headerId": headerId }
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));

                break;
            case "SaveNewForm":
            case "SaveForm":                
                setPrimaryKeyValue(data.headerId);
                setFormData(data);
                if (packageObj.postTransaction == true) {
                    postTransaction(data, packageObj.sendEmail, data.headerId);
                }
                if (packageObj.postTransaction == false && packageObj.sendEmail == true) {
                    emailTransaction(data.headerId);
                }
                if (packageObj.postTransaction == false && packageObj.sendEmail == false) {
                    var msg = 'Form has been saved.';
                    if (dialogType == undefined) {
                        setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, ignoreBGClick: true, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/invoice/' + data.headerId); refreshPage(); } })
                    } else {
                        setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, ignoreBGClick: true, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); setAcceptAfterUpdate(true); handlerReturnValue(data.headerId); } })
                    }
                    setOpenDialog(true);
                }
                break;
            case "PostTransaction":
                if (packageObj.sendEmail == true) {
                    emailTransaction(packageObj.headerId);
                } else {
                    var msg = 'Invoice has been posted.';
                    setGenericDialogSchema({ title: 'Record Posted', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/invoice/' + packageObj.headerId); refreshPage(); } })
                    setOpenDialog(true);
                }
                break;
            case "EmailTransaction":
                var msg = 'Email has been sent.';
                setGenericDialogSchema({ title: 'Email sent', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/invoice/' + packageObj.headerId); refreshPage(); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/transactions/invoice'); } })
                setOpenDialog(true);
                break;
            case 'GetContactLookup':
                var tmpFormData = { ...formData };
                tmpFormData.customerId = data.contactId;
                tmpFormData.customerEmail = data.email;
                tmpFormData.customerName = data.contactName;
                tmpFormData.customer = data;

                tmpFormData.billingAddress1 = data.physicalAddress1;
                tmpFormData.billingAddress2 = data.physicalAddress2
                tmpFormData.billingCity = data.physicalCity;
                tmpFormData.billingState = data.physicalState;
                tmpFormData.billingZip = data.physicalZip;
                tmpFormData.billingCountryCode = data.physicalCountryCode;

                if (tmpFormData.copyBillingAddress == true) {
                    copyBillingAddress(tmpFormData);
                }
                setFormData((...formData) => { return tmpFormData });
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "accounting/transactionheader/getrecord?headerid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            } else {
                if (primaryKeyValue == '0') {
                    if (contactId != undefined) {
                        onPopupOK("GetCustomerInfo", contactId);
                    }
                }
            }
        }
    }, [columnSchemas, refreshId]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "accounting_transaction_headers", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);



    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} recordText={formData != null ? (formData.transactionNumber != null ? `# - ${formData.transactionNumber}` : null) : null} headerOptions={headerOptions} />

            {JSON.stringify(formData) == "{}" && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {JSON.stringify(formData) != "{}" &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "1200px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="segment">
                                        <div className="segment-title">Customer Info</div>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div style={{marginBottom:"10px"} }>
                                                                <LookupFieldCtrl dataPackage={formData.customer || ''} textFieldName="contactName" valueFieldName="contactId" label="Customer" helperText={errorList['customerId'] ? <span style={{ color: "red" }}>{errorList['customerId']}</span> : ''} onClick={() => { openContactSearch('lookup'); }} onRemove={() => { setFormData(formData => ({ ...formData, customerId: null })); setFormData(formData => ({ ...formData, customer: null })); setFormData(formData => ({ ...formData, customerEmail: null })); setFormData(formData => ({ ...formData, customerName: null })); }}></LookupFieldCtrl>
                                                            </div>
                                                            <TextField id="customerEmail" fullWidth label="Customer Email" variant="standard" helperText={errorList['customerEmail'] ? <span style={{ color: "red" }}>{errorList['customerEmail']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.customerEmail || ''} InputLabelProps={{ shrink: formData.customerEmail }} inputProps={{readOnly:true}} onChange={(e) => setFormData(formData => ({ ...formData, customerEmail: e.target.value }))} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="segment">
                                        <div className="segment-title">Date & Payment Info</div>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div style={{ marginBottom: "10px" }}>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                                    <DemoContainer components={['DatePicker']} style={{ marginBottom: "10px" }}>
                                                                        <DesktopDatePicker
                                                                            label="Invoice Date"
                                                                            variant="Standard"
                                                                            slotProps={{ textField: { fullWidth: true, helperText: errorList['transactionDate'] ? <span style={{ color: "red" }} > {errorList['transactionDate']}</span> : '' } }}
                                                                            value={formData.transactionDate != null ? dayjs(formData.transactionDate) : null}
                                                                            onChange={(newValue) => setFormData(formData => ({ ...formData, transactionDate: newValue }))} />
                                                                    </DemoContainer>
                                                                </LocalizationProvider>
                                                            </div>
                                                            <div>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                                    <DemoContainer components={['DatePicker']} style={{ marginBottom: "10px" }}>
                                                                        <DesktopDatePicker
                                                                            label="Due Date"
                                                                            variant="Standard"
                                                                            slotProps={{ textField: { fullWidth: true, helperText: errorList['dueDate'] ? <span style={{ color: "red" }} > {errorList['dueDate']}</span> : '' } }}
                                                                            value={formData.dueDate != null ? dayjs(formData.dueDate) : null}
                                                                            onChange={(newValue) => setFormData(formData => ({ ...formData, dueDate: newValue }))} />
                                                                    </DemoContainer>
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="segment">
                                        <div className="segment-title">Billing Address</div>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-7">
                                                            <TextField id="billingAddress1" fullWidth label="Street Address" variant="standard" helperText={errorList['billingAddress1'] ? <span style={{ color: "red" }}>{errorList['billingAddress1']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.billingAddress1 || ''} InputLabelProps={{ shrink: formData.billingAddress1 }} onChange={(e) => setFormData(formData => ({ ...formData, billingAddress1: e.target.value }))} onBlur={(e) => onFieldBlur(e,'billingAddress1',e.target.value)} />
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <TextField id="billingAddress2" fullWidth label="Apt or Suite #" variant="standard" helperText={errorList['billingAddress2'] ? <span style={{ color: "red" }}>{errorList['billingAddress2']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.billingAddress2 || ''} InputLabelProps={{ shrink: formData.billingAddress2 }} onChange={(e) => setFormData(formData => ({ ...formData, billingAddress2: e.target.value }))} onBlur={(e) => onFieldBlur(e, 'billingAddress2', e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <TextField id="billingCity" fullWidth label="City" variant="standard" helperText={errorList['billingCity'] ? <span style={{ color: "red" }}>{errorList['billingCity']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.billingCity || ''} InputLabelProps={{ shrink: formData.billingCity }} onChange={(e) => setFormData(formData => ({ ...formData, billingCity: e.target.value }))} onBlur={(e) => onFieldBlur(e, 'billingCity', e.target.value)} />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <TextField id="billingState" fullWidth label="State/Province" variant="standard" helperText={errorList['billingState'] ? <span style={{ color: "red" }}>{errorList['billingState']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.billingState || ''} InputLabelProps={{ shrink: formData.billingState }} onChange={(e) => setFormData(formData => ({ ...formData, billingState: e.target.value }))} onBlur={(e) => onFieldBlur(e, 'billingState', e.target.value)} />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <TextField id="billingZip" fullWidth label="Zip/Postal" variant="standard" helperText={errorList['billingZip'] ? <span style={{ color: "red" }}>{errorList['billingZip']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.billingZip || ''} InputLabelProps={{ shrink: formData.billingZip }} onChange={(e) => setFormData(formData => ({ ...formData, billingZip: e.target.value }))} onBlur={(e) => onFieldBlur(e, 'billingZip', e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <TextField id="billingCountryCode" fullWidth label="Country Code" variant="standard" helperText={errorList['billingCountryCode'] ? <span style={{ color: "red" }}>{errorList['billingCountryCode']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.billingCountryCode || ''} InputLabelProps={{ shrink: formData.billingCountryCode }} inputProps={{ maxLength: 2 }} onChange={(e) => setFormData(formData => ({ ...formData, billingCountryCode: e.target.value }))} onBlur={(e) => onFieldBlur(e, 'billingCountryCode', e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="segment">
                                        <div className="segment-title"><span>Shipping Address</span>
                                            <div style={{ display: "inline-block",marginLeft:"20px" }}>
                                                <FormControlLabel                                                    
                                                    value="end"
                                                    control={<Checkbox style={{ padding: "2px" }} checked={formData.copyBillingAddress} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} onChange={(e) => onCopyBillingAddressChange(e,e.target.checked)} />}
                                                    label="Same as billing address"
                                                    labelPlacement="end"
                                                />
                                                
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-7">
                                                            <TextField id="mailingAddress1" fullWidth label="Street Address" variant="standard" helperText={errorList['mailingAddress1'] ? <span style={{ color: "red" }}>{errorList['mailingAddress1']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingAddress1 || ''} InputLabelProps={{ shrink: formData.mailingAddress1 }} onChange={(e) => setFormData(formData => ({ ...formData, mailingAddress1: e.target.value }))} />
                                                        </div>
                                                        <div className="col-sm-5">                                                   
                                                            <TextField id="mailingAddress2" fullWidth label="Apt or Suite #" variant="standard" helperText={errorList['mailingAddress2'] ? <span style={{ color: "red" }}>{errorList['mailingAddress2']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingAddress2 || ''} InputLabelProps={{ shrink: formData.mailingAddress2 }} onChange={(e) => setFormData(formData => ({ ...formData, mailingAddress2: e.target.value }))} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <TextField id="mailingCity" fullWidth label="City" variant="standard" helperText={errorList['mailingCity'] ? <span style={{ color: "red" }}>{errorList['mailingCity']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingCity || ''} InputLabelProps={{ shrink: formData.mailingCity }} onChange={(e) => setFormData(formData => ({ ...formData, mailingCity: e.target.value }))} />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <TextField id="mailingState" fullWidth label="State/Province" variant="standard" helperText={errorList['mailingState'] ? <span style={{ color: "red" }}>{errorList['mailingState']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingState || ''} InputLabelProps={{ shrink: formData.mailingState }} onChange={(e) => setFormData(formData => ({ ...formData, mailingState: e.target.value }))} />
                                                        </div>
                                                        <div className="col-sm-3">                                                                                                       
                                                            <TextField id="mailingZip" fullWidth label="Zip/Postal" variant="standard" helperText={errorList['mailingZip'] ? <span style={{ color: "red" }}>{errorList['mailingZip']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingZip || ''} InputLabelProps={{ shrink: formData.mailingZip }} onChange={(e) => setFormData(formData => ({ ...formData, mailingZip: e.target.value }))} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <TextField id="mailingCountryCode" fullWidth label="Country Code" variant="standard" helperText={errorList['mailingCountryCode'] ? <span style={{ color: "red" }}>{errorList['mailingCountryCode']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.mailingCountryCode || ''} InputLabelProps={{ shrink: formData.mailingCountryCode }} inputProps={{ maxLength: 2 }} onChange={(e) => setFormData(formData => ({ ...formData, mailingCountryCode: e.target.value }))} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="segment">
                                        <div className="segment-title">Line Items</div>
                                        <formHelperText className="fg-helper-text">{errorList['details'] ? <span style={{ color: "red" }}>{errorList['details']}</span> : ''} </formHelperText>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <TransactionDetailCtrl details={formData.details} onChange={(e,details) => {handleDetailsChanged(e, details)}}></TransactionDetailCtrl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="segment">
                                <div className="segment-title">Totals</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div style={{ backgroundColor: "whitesmoke", padding: "15px", border: "1px solid rgba(0,0,0,.1)" }}>
                                                    <FormControl fullWidth variant="standard" helperText={errorList['netAmt'] ? <span style={{ color: "red" }}>{errorList['netAmt']}</span> : ''} style={{ marginBottom: "16px" }}>
                                                        <InputLabel htmlFor="netAmt">Net Amount</InputLabel>
                                                        <Input
                                                            id="netAmt"
                                                            readOnly={true}
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                            inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                                            value={helpers.formatNumber(formData.netAmt, 2) || '0'}
                                                            onChange={(e) => onFieldValueChange(e, 'netAmount', e.target.value)}
                                                            onBlur={(e) => onFieldBlur(e, 'netAmount', e.target.value)}
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth variant="standard" helperText={errorList['amtToTax'] ? <span style={{ color: "red" }}>{errorList['amtToTax']}</span> : ''} style={{ marginBottom: "16px" }}>
                                                        <InputLabel htmlFor="amtToTax">Amount to Tax</InputLabel>
                                                        <Input
                                                                id="amtToTax"
                                                                readOnly={true}
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                            inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                                            value={helpers.formatNumber(formData.amtToTax, 2) || '0'}
                                                            onChange={(e) => onFieldValueChange(e, 'amtToTax', e.target.value)}
                                                            onBlur={(e) => onFieldBlur(e, 'amtToTax', e.target.value)}
                                                        />
                                                    </FormControl>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <FormControl fullWidth variant="standard" helperText={errorList['taxPercent'] ? <span style={{ color: "red" }}>{errorList['taxPercent']}</span> : ''} style={{ marginBottom: "16px" }}>
                                                                <InputLabel htmlFor="netAmt">Tax Percent</InputLabel>
                                                                <Input
                                                                    id="taxPercent"
                                                                    type="number"
                                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                                    inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                                                    value={formData.taxPercent || '0'}
                                                                    onChange={(e) => onFieldValueChange(e, 'taxPercent', e.target.value)}
                                                                    onBlur={(e) => onFieldBlur(e, 'taxPercent', e.target.value)}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <FormControl fullWidth variant="standard" helperText={errorList['taxAmt'] ? <span style={{ color: "red" }}>{errorList['taxAmt']}</span> : ''} style={{ marginBottom: "16px" }}>
                                                                <InputLabel htmlFor="taxAmt">Tax Amount</InputLabel>
                                                                <Input
                                                                        id="taxAmt"
                                                                        readOnly={true}
                                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                    inputProps={{ min: 0, style: { textAlign: 'right' } }}
                                                                    value={helpers.formatNumber(formData.taxAmt, 2) || '0'}
                                                                    onChange={(e) => onFieldValueChange(e, 'taxAmt', e.target.value)}
                                                                    onBlur={(e) => onFieldBlur(e, 'taxAmt', e.target.value)}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                    <TextField id="shippingCarrier" fullWidth label="Shipping Carrier" variant="standard" helperText={errorList['shippingCarrier'] ? <span style={{ color: "red" }}>{errorList['shippingCarrier']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.shippingCarrier || ''} InputLabelProps={{ shrink: formData.shippingCarrier }} onChange={(e) => setFormData(formData => ({ ...formData, shippingCarrier: e.target.value }))} />
                                                    <FormControl fullWidth variant="standard" helperText={errorList['shippingAmt'] ? <span style={{ color: "red" }}>{errorList['shippingAmt']}</span> : ''} style={{ marginBottom: "16px" }}>
                                                        <InputLabel htmlFor="shippingAmt">Shipping Amount</InputLabel>
                                                        <Input
                                                                id="shippingAmt"
                                                                type="number"
                                                                step="any"
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                inputProps={{ min: 0, style: { textAlign: 'right' }, className: "fg-number-no-arrows" }}
                                                            value={helpers.formatNumber(formData.shippingAmt, 2) || '0'}
                                                            onChange={(e) => onFieldValueChange(e, 'shippingAmt', e.target.value)}
                                                            onBlur={(e) => onFieldBlur(e, 'shippingAmt', e.target.value)}
                                                        />
                                                    </FormControl>
                                                    <TextField id="discountCode" fullWidth label="Discount Code" variant="standard" helperText={errorList['discountCode'] ? <span style={{ color: "red" }}>{errorList['discountCode']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.discountCode || ''} InputLabelProps={{ shrink: formData.discountCode }} onChange={(e) => setFormData(formData => ({ ...formData, discountCode: e.target.value }))} />
                                                    <FormControl fullWidth variant="standard" helperText={errorList['discountAmt'] ? <span style={{ color: "red" }}>{errorList['discountAmt']}</span> : ''} style={{ marginBottom: "16px" }}>
                                                        <InputLabel htmlFor="discountAmt">Discount Amount</InputLabel>
                                                        <Input
                                                                id="discountAmt"
                                                                type="number"
                                                            step="any"
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                inputProps={{ min: 0, style: { textAlign: 'right' }, className: "fg-number-no-arrows" }}
                                                            value={helpers.formatNumber(formData.discountAmt, 2) || '0'}
                                                            onChange={(e) => onFieldValueChange(e, 'discountAmt', e.target.value)}
                                                            onBlur={(e) => onFieldBlur(e, 'discountAmt', e.target.value)}
                                                        />
                                                    </FormControl>
                                                    
                                                        <FormControl fullWidth variant="standard" helperText={errorList['headerAmt'] ? <span style={{ color: "red" }}>{errorList['headerAmt']}</span> : ''} style={{ marginBottom: "16px" }}>
                                                            <InputLabel htmlFor="headerAmt" style={{color:"black",fontWeight:"bold"}}>Amount Due</InputLabel>
                                                        <Input
                                                                id="totalAmtDue"
                                                                readOnly={true}
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                            inputProps={{ min: 0, style: { textAlign: 'right', fontSize: "28px", fontWeight: "bold",readOnly:true } }}
                                                            value={helpers.formatNumber(formData.headerAmt,2) || '0'}
                                                            onChange={(e) => onFieldValueChange(e, 'headerAmt', e.target.value)}
                                                            onBlur={(e) => onFieldBlur(e, 'headerAmt', e.target.value)}
                                                        />
                                                    </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete order. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "accounting/transactionheader/delete?headerid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    function handleSaveForm(postTransaction, sendEmail) {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm(postTransaction, sendEmail);
            }
        } else {
            var msg = 'There are form validation errors.';
            setGenericDialogSchema({ title: 'Validation Errors', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
            setOpenDialog(true);
        }
    }

    function saveForm(postTransaction, sendEmail) {
        var additionalActions = { "postTransaction": postTransaction, "sendEmail": sendEmail };
        var tmpFormData = { ...formData };
        //tmpFormData.forecastSummary = JSON.stringify(tmpFormData.forecastSummary);
        //tmpFormData.autoSurvey = JSON.stringify(tmpFormData.autoSurvey);
        if (primaryKeyValue == '0') {
            BAFetchData({
                callName: "SaveNewForm", method: "POST", url: "accounting/transactionheader/add",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError,
                packageObj: additionalActions
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "accounting/transactionheader/update",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError,
                packageObj: additionalActions
            });
        }
    }

    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        if (formData.customerId == null || formData.customerId == '') {
            isValid = false;
            tempErrorList['customerId'] = "Is required.";
        }
        if (formData.dueDate == null) {
            isValid = false;
            tempErrorList['dueDate'] = "Due Date is required.";
        } else {
            var dueDate = dayjs(formData.dueDate);
            if (dueDate < dayjs()) {
                isValid = false;
                tempErrorList['dueDate'] = "Should be today or a future date.";
            }
        }
        if (formData.taxAmt != null && formData.taxAmt != '') {
            if (parseFloat(formData.taxAmt) < 0) {
                tempErrorList['taxAmt'] = "Cannot be negative number.";
            }
        }
        if (formData.shippingAmt != null && formData.shippingAmt != '') {
            if (parseFloat(formData.shippingAmt) < 0) {
                tempErrorList['shippingAmt'] = "Cannot be negative number.";
            }
        }
        if (formData.discountAmt != null && formData.discountAmt != '') {
            if (parseFloat(formData.discountAmt) < 0) {
                tempErrorList['discountAmt'] = "Cannot be negative number.";
            }
        }
        if (helpers.isListEmpty(formData.details) == true) {
            isValid = false;
            tempErrorList['details'] = "At least one invoice is required.";
        } else {
            if (helpers.isListQtyAboveZero(formData.details) == true) {
                isValid = false;
                tempErrorList['details'] = "Line items with zero qty is not allowed. Please update qty or remove row.";
            }
        }


        setErrorList(tempErrorList);
        return isValid;
    }
}

export default Invoice;