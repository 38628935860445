import React from "react";

function DesignRuler({}){

    const rulerWidth = 3000; 
    const spacing = 10;
    const labelSpacing = 50;
    const maxBlocks = rulerWidth / spacing; 
    const blocks = Array(maxBlocks).fill(10);
    const labelBlockx = rulerWidth / labelSpacing; 
    const labels = Array(labelBlockx).fill(labelSpacing);
    

    return (
        <div style={{overflow:"hidden",borderBottom:"1px solid #c0c0c0"}}>
            <div style={{width:{rulerWidth} + "px",height:"5px"}}>
                {blocks.map((block) => (
                    <div style={{display:"inline-block",width:"10px",borderRight:"1px solid #c0c0c0",height:"5px"}}>&nbsp;</div>
                ))}        
            </div>
            <div style={{width:{rulerWidth} + "px",height:"15px",padding:"0px",fontSize:"9px"}}>
            {labels.map((label, index) => {
                    let text = label * index;
                    return (<div style={{display:"inline-block",width:"50px",borderRight:"1px solid #c0c0c0",fontSize:"9px",margin:"0px",fontWeight:"500",color:"grey"}}>{text}px</div>)
                })} 
            </div>        
        </div>
    )
}

export default DesignRuler;

