import React from "react";
import helpers from "../../../helpers/Helpers";

function BookingItemsCtrl({ bookableItemList, selectedBookItemId, onChangeBookableItem }){

    function determineSelectedStyle(bookableItemId){
        let styleBackgroundColor = "white";
        if (bookableItemId === selectedBookItemId){
            styleBackgroundColor = "whitesmoke";
        }
        return styleBackgroundColor;
    }

    return (
        <div>
            <div style={{marginBottom:"20px",fontWeight:"bold"}}>Select Bookable Item</div>
            <div style={{display:"grid",gridTempalteColumns:"1fr",gridGap:"20px"}}>
                {bookableItemList.map((bookableItem, index) => {
                  return (  
                    <div key={bookableItem.bookableItemId} style={{backgroundColor:determineSelectedStyle(bookableItem.bookableItemId),display:"grid",gridTemplateColumns:"65px 1fr",gridGap:"10px",width:"100%",padding:"10px"}}>
                        <div>
                            <img src={bookableItem.imageUrl} alt="" style={{width:"100%"}}/>
                        </div>
                        <div>
                              <div>{bookableItem.title}</div>
                              <div>{bookableItem.defaultCost ? helpers.formatCurrencyGlobal(bookableItem.defaultCost) : 'FREE'}</div>
                              <div><button style={{ border: "none", backgroundColor: "#D9DADF", padding: "5px 15px 5px 15px", borderRadius: "4px" }} onClick={(e) => { onChangeBookableItem(bookableItem.bookableItemId); }}>Select</button></div>
                        </div>
                    </div>
                )
                })}

            </div>
        </div>
    )
}

export default BookingItemsCtrl;