import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import { DataBrokerContext } from '../PageBuilderCtrl';
import Button from '@mui/material/Button';
import CssAiContentCtrl from './CssAiContentCtrl';


function CssInnerHtmlCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);


    function handleAiContentChange(value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.aiText = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnValueChangeOneProperty(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnValueChangeTwoProperties(propertyName1, propertyName2, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName1][propertyName2] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function submitAiPrompt() {

    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])

    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Text</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                        <div>
                            <div>Run-Time</div>
                            <textarea className="form-control form-control-sm fg-pb-property-field" value={widgetObj.text || ''} defaultValue={widgetObj.text || ''} onChange={(e) => { handleOnValueChangeOneProperty("text",e.target.value); }} rows={4}></textarea>
                        </div>
                        <div>
                            <div>Design-Time</div>
                            <textarea className="form-control form-control-sm fg-pb-property-field" value={widgetObj.designText || ''} defaultValue={widgetObj.designText || ''} onChange={(e) => { handleOnValueChangeOneProperty("designText", e.target.value); }} rows={4}></textarea>
                        </div>
                        {widgetData.elementName != 'fieldlabel' ?
                            <CssAiContentCtrl contentType="Text" aiImageData={widgetData.aiText} propertyChangeCallback={(value) => { handleAiContentChange(value); }} />
                            : null}
                    </div>
                </div>
            </div>
        </>
    );

}

export default CssInnerHtmlCtrl;

