
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import DialogCtrl from '../components/dialogctrl/dialogctrl';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import { useGlobalState } from '../globalstates/GState';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ImageFieldCtrl from '../components/formcontrols/ImageFieldCtrl';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import QuestionListCtrl from '../components/formcontrols/course/QuestionListCtrl';
import Snackbar from '@mui/material/Snackbar';

//import selectedDates from '../components/formcontrols/booking/datasource/selecteddates.json';
//import defaultAvailSlots from '../components/formcontrols/booking/datasource/availability.json';
//import bookableItemList from '../components/formcontrols/booking/datasource/eventlist.json';
import BookableLocationsCtrl from '../components/formcontrols/bookableitems/BookableLocationsCtrl';
import BookingCtrl from '../components/formcontrols/booking/BookingCtrl';


//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';

function BookableItem({dialogType,handlerReturnValue}) {
    const [currentUser] = useGlobalState("userInfo");
    const navigate = useNavigate();
    let params = useParams();

    const [resetId, setResetId] = useState(null);
    const [bookableItemList, setBookableItemList] = useState([]);
    const [availSlots, setAvailSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [resources, setResources] = useState([]);
    const [portalPathName, setPortalPathName] = useState(null);
    const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);

    function getPortalPathName() {
        BAFetchData({
            callName: "GetPortalPathName",
            method: "GET",
            url: "security/businessaccount/getrecord?baid=" + currentUser.currentBusinessAccountDTO.baid,
            token: "fg1234",
            body: formData,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onComplete(timezoneName, bookableItemId, selectedLocationId, selectedResourceId, confirmedDateTime, answers, payInfo) {
        let formData = new FormData();
        formData.append("eventId", bookableItemId);
        formData.append("locationId", selectedLocationId);
        formData.append("timezoneName", timezoneName);
         
        //let resourceIdList = []
        //resourceIdList.push(selectedResourceId);
        formData.append('resourceIdList', selectedResourceId);     
        const startDateTime = new Date(confirmedDateTime);
        formData.append("startDateTime", dayjs(startDateTime).format('YYYY-MM-DD HH:mm') );
        formData.append("formAnswers", JSON.stringify(answers));

        BAFetchData({
            callName: "AddCardFromEvent",
            method: "POST",
            url: "pm/card/addfromevent",
            token: "fg1234",
            body:formData,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onDateChanged(selDate, bookableItemId, locationId, resourceId) {
        if (selDate == null) {
            setAvailSlots([]);
            setSelectedDate(null);
        } else {
            const keyDate = dayjs(selDate).format('YYYY-MM-DD');
            getAvailableSlots(bookableItemId, locationId, resourceId, keyDate);
            setSelectedDate(selDate);
        }
    }

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const totalTabs = 4;

    const handleTabChange = (event, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };


    const [formData, setFormData] = useState({});
    //const [questionsObj, setQuestionsObj] = useState([]);
    const [timezoneList, setTimezoneList] = useState([]);
    const [countryList, setCountryList] = useState([]);

    const [projectList, setProjectList] = useState([]);
    const [projectBoardMapList, setProjectBoardMapList] = useState([]);
    const [columnList, setColumnList] = useState([]);

    const [primaryKeyValue, setPrimaryKeyValue] = useState(params.bookableItemId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_detail_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('contact_detail_page', 'header_section', 'Bookable Item Settings');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (params.pixelId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
        //if (helpers.permissionAllowed(currentUser.permissions, 'Reporting-TrackingPixels', ['allowDelete']) == true) {
            headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('contact_detail_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
        //}
    }

    //if ((helpers.permissionAllowed(currentUser.permissions, 'Reporting-TrackingPixels', ['allowEdit']) == true) || (helpers.permissionAllowed(currentUser.permissions, 'Reporting-TrackingPixels', ['allowAdd']) == true && primaryKeyValue == 0)) {
        headerOptions.elements.push({ type: "button", text: getTranslation('contact_detail_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    //}
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function onTitleChange(titleVal) {
        let tmpFormData = { ...formData };
        tmpFormData.title = titleVal;
        tmpFormData.slug = helpers.slugify(titleVal);
        setFormData(tmpFormData);
    }

    function changeTab(direction) {
        var tmpCurrentTabIndex = currentTabIndex;
        if (direction == 'next') {
            if (tmpCurrentTabIndex + 1 < totalTabs) {
                tmpCurrentTabIndex++;
            }
        } else if (direction == 'prev') {
            if (tmpCurrentTabIndex > 0) {
                tmpCurrentTabIndex--;
            }
        }
        setCurrentTabIndex(tmpCurrentTabIndex);
    }

    function addDefaultData(data) {
        if (primaryKeyValue == "0") {
            //set default data
            data.defaultCost = 0;
            data.resourceType = "Service";
            data.bookingType = "OneToOne";
            data.locationConfigurations = [];
            data.duration = 30;
            data.startTimeIncrement = 15;
            data.advanceDays = 14;
            data.minNoticeNumber = 4;
            data.minNoticeType = "day";
            data.timezoneSetting = "auto";
            data.confirmationType = "Default";
            data.questions = getDefaultQuestions();

        }        
    }

    function getDefaultQuestions() {
        let questions = [];
        var question1 = {
            "questionId": helpers.getUUID(),
            "fieldName": "firstName",
            "question": "What is your first name?",
            "required": true,
            "image": null,
            "questionType": "FreeTextOneLine",
            "answerOptions": []
        }
        var question2 = {
            "questionId": helpers.getUUID(),
            "fieldName": "lastName",
            "question": "What is your last name?",
            "required": true,
            "image": null,
            "questionType": "FreeTextOneLine",
            "answerOptions": []
        }
        var question3 = {
            "questionId": helpers.getUUID(),
            "fieldName": "email",
            "question": "What is your email?",
            "required": true,
            "image": null,
            "questionType": "FreeTextOneLine",
            "answerOptions": []
        }
        var question4 = {
            "questionId": helpers.getUUID(),
            "fieldName": "phone",
            "question": "What is your mobile number?",
            "required": true,
            "image": null,
            "questionType": "FreeTextOneLine",
            "answerOptions": []
        }
        let question5Id = helpers.getUUID()
        var question5 = {
            "questionId": question5Id,
            "fieldName": question5Id,
            "question": "Please share anything that will help prepare for our meeting.",
            "required": false,
            "image": null,
            "questionType": "FreeTextMultiLine",
            "answerOptions": []
        }
        questions.push(question1);
        questions.push(question2);
        questions.push(question3);
        questions.push(question4);
        questions.push(question5);
        return questions;
    }

    function getCountryList() {
        BAFetchData({
            callName: "GetCountryList",
            method: "GET",
            url: "location/country/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getTimezoneList(countryCode) {
        var filtering = {};
        if (countryCode != null) {
            filtering = {
                "LogicalOperator": "and", "ConditionGroups": [{
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "countrycode", "ConditionOperator": "EQUAL", "Value": countryCode, "DataType": "String" }
                    ]
                }]
            };
        }

        BAFetchData({
            callName: "GetTimezoneList",
            method: "GET",
            url: "time/timezone/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getBookingItemList() {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "bookableitemid", "ConditionOperator": "EQUAL", "Value": primaryKeyValue, "DataType": "String" }
                ]
            }]
        };


        BAFetchData({
            callName: "GetBookableItemList",
            method: "GET",
            url: "bookableitems/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }


    function getResources(bookableItemId, locationId) {
        BAFetchData({
            callName: "GetResources",
            method: "GET",
            url: "bookableitems/getresources?bookableitemid=" + bookableItemId + "&locationid=" + locationId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getAvailableDates(bookableItemId, locationId, resourceId) {
        let test = '';
        test = '';
        BAFetchData({
            callName: "GetAvailableDates",
            method: "GET",
            url: "bookableitems/getavailabledates?bookableitemid=" + bookableItemId + "&locationid=" + locationId + "&resourceId=" + resourceId,
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getAvailableSlots(bookableItemId, locationId, resourceId, date) {
        if (date != 'Invalid Date') {
            let test = "";
            test = "";
            BAFetchData({
                callName: "GetAvailableSlots",
                method: "GET",
                url: "bookableitems/getavailableslots?bookableitemid=" + bookableItemId + "&locationid=" + locationId + "&resourceid=" + resourceId + "&date=" + date,
                token: "fg1234",
                toggleRefetch: null,
                onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            setAvailSlots([]);
        }
    }


    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                if (data.questions == null || data.questions == '') {
                    data.questions = [];
                } else {
                    data.questions = JSON.parse(data.questions);
                }
                if (data.locationConfigurations == null || data.locationConfigurations == '') {
                    data.locationConfigurations = [];
                } else {
                    data.locationConfigurations = JSON.parse(data.locationConfigurations);
                }
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                getCountryList();
                getTimezoneList((data.mailingCountry != null ? data.mailingCountry : null));
                getProjectListData();
                getBoardListData(data.projectId);
                getColumnListData(data.boardId);
                getBookingItemList();
                break;
            case "SaveForm":            
                if (data.questions == null || data.questions == '') {
                    data.questions = [];
                } else {
                    data.questions = JSON.parse(data.questions);
                }
                if (data.locationConfigurations == null || data.locationConfigurations == '') {
                    data.locationConfigurations = [];
                } else {
                    data.locationConfigurations = JSON.parse(data.locationConfigurations);
                }            
                setPrimaryKeyValue(data.bookableItemId);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/bookableitem/' + data.bookableItemId); } })
                setOpenDialog(true);
                setResetId(helpers.getUUID());
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/bookableitems'); } })
                setOpenDialog(true);
                break;
            case 'GetTimezoneList':
                setTimezoneList(data);
                break;
            case 'GetCountryList':
                setCountryList(data);
                break;
            case "GetProjectList":
                setProjectList(data);
                break;
            case "GetBoardList":
                setProjectBoardMapList(data);
                break;
            case "GetColumnList":
                setColumnList(data);
                break;
            case "GetBookableItemList":
                for (let i = 0; i < data.length; i++) {
                    data[i].questions = JSON.parse(data[i].questions);
                    data[i].locationConfigurations = JSON.parse(data[i].locationConfigurations);
                }
                setBookableItemList(data);
                break;
            case "GetAvailableDates":
                setSelectedDates(data);
                break;
            case "GetAvailableSlots":
                setAvailSlots(data);
                break;
            case "GetResources":
                setResources(data);
                break;
            case "AddCardFromEvent":
                var msg = 'Reservation has been created.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); navigate('/bookableitem/' + data.bookableItemId); } })
                setOpenDialog(true);
                setResetId(helpers.getUUID());
                break;
            case "GetPortalPathName":
                setPortalPathName(data.portalPathName);
                break;
        }
    }

    function onAPIError(error, callName) {
        //switch (callName) {
        //    case "AttachTag":
        //        break;
        //}
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function getProjectListData() {
        BAFetchData({
            callName: "GetProjectList",
            method: "GET",
            url: "pm/project/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getBoardListData(projectId) {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "projectid", "ConditionOperator": "EQUAL", "Value": projectId, "DataType": "String" }
                ]
            }]
        };
        BAFetchData({
            callName: "GetBoardList",
            method: "GET",
            url: "pm/projectboardmap/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getColumnListData(boardId) {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "boardid", "ConditionOperator": "EQUAL", "Value": boardId, "DataType": "String" }
                ]
            }]
        };
        BAFetchData({
            callName: "GetColumnList",
            method: "GET",
            url: "pm/column/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onProjectChange(projectId) {
        var tmpFormData = { ...formData };
        tmpFormData.projectId = projectId;
        tmpFormData.boardId = null;
        tmpFormData.columnId = null;
        setFormData(tmpFormData);
        getBoardListData(projectId);
        setColumnList([]);
    }

    function onBoardChange(boardId) {
        var tmpFormData = { ...formData };
        tmpFormData.boardId = boardId;
        tmpFormData.columnId = null;
        setFormData(tmpFormData);
        getColumnListData(boardId);
    }

    function copyUrl(locationId, resourceId) {
        const publicUrl = process.env.PUBLIC_URL;
        if (publicUrl == "/") {
            publicUrl = "";
        }

        let url = `${window.location.protocol}//${window.location.host}${publicUrl}/${portalPathName}/${formData.slug}`
        if (locationId != null) {
            url += "/" + locationId
        }
        if (resourceId != null) {
            url += "/" + resourceId
        }
        helpers.copyText(url);        
        setCopySnackbarOpen(true);
    }

    function previewUrl(locationId, resourceId) {

        const publicUrl = process.env.PUBLIC_URL;
        if (publicUrl == "/") {
            publicUrl = "";
        }

        let url = `${publicUrl}/${portalPathName}/${formData.slug}`;
        if (locationId != null) {
            url += "/" + locationId
        }
        if (resourceId != null) {
            url += "/" + resourceId
        }
        window.open(url, '_blank');        
    }

    const handleCopySnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCopySnackbarOpen(false);
    };

    useEffect(() => {
        setShowLoader(false);   
        getPortalPathName();
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (JSON.stringify(formData) == '{}') {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "bookableitems/getrecord?bookableitemid=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "bookableitems", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);

    useEffect(() => {
        getBookingItemList();
    }, [resetId])

    //useEffect(() => {
    //    if (JSON.stringify(columnSchemas) != '{}') {
    //        if (JSON.stringify(formData) == '{}') {
    //            setFormData(formData => ({ ...formData, questions: [...questionsObj] }));
    //        }
    //    }
    //}, [questionsObj])

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ maxWidth: "1400px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}

                    <div className="row">
                        <div className="col-sm-7">

                    <div className="row">
                        <div className="col-sm-12">
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{ marginBottom: "20px" }}>
                                <Tabs onChange={handleTabChange}
                                    value={currentTabIndex}
                                    variant="scroll"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    aria-label="scrollable force tabs example">

                                    <Tab label="1 -> Booking Type" value={0} style={{ padding: "3px 36px 3px 0px", minWidth: "60px" }} />
                                    <Tab label="2 -> Details" value={1} style={{ padding: "3px 36px 3px 0px", minWidth: "60px" }} />
                                    <Tab label="3 -> Location(s)" value={2} style={{ padding: "3px 36px 3px 0px", minWidth: "60px" }} />
                                    <Tab label="4 -> Form Setup" value={3} style={{ padding: "3px 36px 3px 0px", minWidth: "60px" }} />
                                </Tabs>
                            </Box>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            {currentTabIndex == 0 ?
                            <div className="segment">
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    
                                                        <div style={{paddingTop:"10px"} }>
                                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                        <InputLabel id="demo-simple-select-standard-label">Resource Type</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-standard-label"
                                                                            id="demo-simple-select-standard"
                                                                            value={formData.resourceType || ''}
                                                                            onChange={(e) => setFormData(formData => ({ ...formData, resourceType: e.target.value }))}
                                                                            label="Resource Type"
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>None</em>
                                                                            </MenuItem>
                                                                            <MenuItem value="Service">Service reservation</MenuItem>
                                                                            <MenuItem value="Equipment">Equipment reservation</MenuItem>
                                                                            <MenuItem value="Vehicle">Vehicle reservation</MenuItem>
                                                                            <MenuItem value="Room">Room reservation</MenuItem>
                                                                        </Select>
                                                                        <FormHelperText>{errorList['resourceType'] ? <span style={{ color: "red" }}>{errorList['bufferTime']}</span> : ''} </FormHelperText>
                                                                    </FormControl>
                                                                    <FormControl>
                                                            <FormLabel id="demo-radio-buttons-group-label" style={{marginBottom:"10px"} }>Select Booking Type</FormLabel>
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                value={formData.bookingType || ''}
                                                                onChange={(e) => setFormData(formData => ({ ...formData, bookingType: e.target.value }))}
                                                                name="radio-buttons-group"
                                                            >
                                                                    <FormControlLabel value="OneToOne" sx={{"& .MuiRadio-root": {paddingTop: "0px"},}} control={<Radio />} label={<div><span style={{ fontWeight: "bold" }}>One to One:</span><br /><span style={{fontSize:"14px"}}>One resource for one reservation.</span></div>} style={{ marginBottom: "10px", alignItems: "flex-start" }} />
                                                                    <FormControlLabel value="OneToMany" sx={{ "& .MuiRadio-root": { paddingTop: "0px" }, }} control={<Radio />} label={<div><span style={{ fontWeight: "bold" }}>One to Many:</span><br /><span style={{fontSize:"14px"}}>One resource for multiple reservations. Ideal for classes, presentations, and webinars.</span></div>} style={{ marginBottom: "10px", alignItems: "flex-start" }} />
                                                                            <FormControlLabel value="ManyToOne" sx={{ "& .MuiRadio-root": { paddingTop: "0px" }, }} control={<Radio />} label={<div><span style={{ fontWeight: "bold" }}>Many to One:</span><br /><span style={{ fontSize: "14px" }}>Many resources for one reservation. Use when more than one person from your company is needed to speak with one attendee.</span></div>} style={{ marginBottom: "10px", alignItems: "flex-start" }} />
                                                                            <FormControlLabel value="RoundRobin" sx={{ "& .MuiRadio-root": { paddingTop: "0px" }, }} control={<Radio />} label={<div><span style={{ fontWeight: "bold" }}>Round Robin:</span><br /><span style={{ fontSize: "14px" }}>Evenly distributed reservations among resources. Ideal for sales teams, service providers and call centers.</span></div>} style={{ marginBottom: "10px", alignItems: "flex-start" }} />
                                                                            <FormControlLabel value="WhoeverIsAvailable" sx={{ "& .MuiRadio-root": { paddingTop: "0px" }, }} control={<Radio />} label={<div><span style={{ fontWeight: "bold" }}>Whatever Resource Is Available:</span><br /><span style={{fontSize:"14px"}}>Automatically assigns a reservation to the first available resource. Ideal when you need to prioritize speed and efficiency by booking the earliest open time slot regarldess of who it is assigned to.</span></div>} style={{ marginBottom: "10px", alignItems: "flex-start" }} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                            {currentTabIndex == 1 ?
                                <>
                                <div className="segment">
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <TextField id="title" required fullWidth label="Title" variant="standard" helperText={errorList['title'] ? <span style={{ color: "red" }}>{errorList['title']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.title || ''} InputLabelProps={{ shrink: formData.title }} onChange={(e) => { onTitleChange(e.target.value); }} />
                                                        <TextField fullWidth label="Slug" variant="standard" helperText={errorList['slug'] ? <span style={{ color: "red" }}>{errorList['slug']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.slug || ''} InputLabelProps={{ shrink: formData.slug }} onChange={(e) => setFormData(formData => ({ ...formData, slug: e.target.value }))} />
                                                        <TextField id="description" multiline rows={4} fullWidth label="Description" variant="standard" helperText={errorList['description'] ? <span style={{ color: "red" }}>{errorList['description']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.description || ''} InputLabelProps={{ shrink: formData.description }} onChange={(e) => setFormData(formData => ({ ...formData, description: e.target.value }))} />
                                                            <div style={{display:"none"} }>
                                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                            <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={formData.countryCode || ''}
                                                                onChange={(e) => { setFormData(formData => ({ ...formData, countryCode: e.target.value })); getTimezoneList(e.target.value); }}
                                                                label="Age"
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {countryList.map((country, ci) => (
                                                                    <MenuItem key={"country_" + ci} value={country.countryCode}>{country.countryName}</MenuItem>
                                                                ))}

                                                            </Select>
                                                            <FormHelperText>{errorList['countryCode'] ? <span style={{ color: "red" }}>{errorList['countryCode']}</span> : ''} </FormHelperText>
                                                        </FormControl>
                                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                            <InputLabel id="demo-simple-select-standard-label">Timezone</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={formData.timezoneName || ''}
                                                                onChange={(e) => setFormData(formData => ({ ...formData, timezoneName: e.target.value }))}
                                                                label="Age"
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {timezoneList.map((timezone, tzi) => (
                                                                    <MenuItem key={"tz_" + tzi} value={timezone.timezoneName}>{timezone.timezoneName}</MenuItem>
                                                                ))}

                                                            </Select>
                                                            <FormHelperText>{errorList['timezoneName'] ? <span style={{ color: "red" }}>{errorList['timezoneName']}</span> : ''} </FormHelperText>
                                                                </FormControl>
                                                        </div>
                                                    </div>
                                                    </div>
                                                <div className="row">
                                                        <div className="col-sm-3">
                                                            <FormControl required variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                <InputLabel id="demo-simple-select-standard-label">Meeting Duration</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    value={formData.duration || ''}
                                                                    onChange={(e) => setFormData(formData => ({ ...formData, duration: e.target.value }))}
                                                                    label="Meeting Duration"
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    <MenuItem value="15">15 Minutes</MenuItem>
                                                                    <MenuItem value="30">30 Minutes</MenuItem>
                                                                    <MenuItem value="45">45 Minutes</MenuItem>
                                                                    <MenuItem value="60">1 Hour</MenuItem>
                                                                    <MenuItem value="90">90 Minutes</MenuItem>
                                                                    <MenuItem value="120">2 Hours</MenuItem>
                                                                    <MenuItem value="180">3 Hours</MenuItem>
                                                                    <MenuItem value="240">4 Hours</MenuItem>
                                                                    <MenuItem value="300">5 Hours</MenuItem>
                                                                    <MenuItem value="360">6 Hours</MenuItem>
                                                                    <MenuItem value="420">7 Hours</MenuItem>
                                                                    <MenuItem value="480">8 Hours</MenuItem>
                                                                </Select>
                                                                <FormHelperText>{errorList['duration'] ? <span style={{ color: "red" }}>{errorList['duration']}</span> : ''} </FormHelperText>
                                                            </FormControl>



                                                        </div>
                                                        <div className="col-sm-3">
                                                            <TextField id="defaultCost" fullWidth label="Default Cost" variant="standard" helperText={errorList['defaultCost'] ? <span style={{ color: "red" }}>{errorList['defaultCost']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.defaultCost || ''} InputLabelProps={{ shrink: formData.defaultCost }} onChange={(e) => setFormData(formData => ({ ...formData, defaultCost: e.target.value }))} />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <ImageFieldCtrl imgSrc={formData.imageUrl || ''} label="Image Url Associated With Bookable Item *" imgType="inline" returnFullPath={true} onChange={(imgValue) => setFormData(formData => ({ ...formData, imageUrl: imgValue }))}></ImageFieldCtrl>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="segment">
                                        <div className="segment-title" style={{ marginBottom: "15px" }}>Schedule Settings</div>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                <InputLabel id="demo-simple-select-standard-label">Start Time Increments</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    value={formData.startTimeIncrement || ''}
                                                                    onChange={(e) => setFormData(formData => ({ ...formData, startTimeIncrement: e.target.value }))}
                                                                    label="Start Time Increment"
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    <MenuItem value="15">15 Minutes</MenuItem>
                                                                    <MenuItem value="30">30 Minutes</MenuItem>
                                                                    <MenuItem value="45">45 Minutes</MenuItem>
                                                                    <MenuItem value="60">1 Hour</MenuItem>
                                                                    <MenuItem value="90">90 Minutes</MenuItem>
                                                                    <MenuItem value="120">2 Hours</MenuItem>
                                                                    <MenuItem value="180">3 Hours</MenuItem>
                                                                    <MenuItem value="240">4 Hours</MenuItem>
                                                                </Select>
                                                                <FormHelperText><span style={{ color: "black" }}>Set how often booking slots appear (e.g., every 15 mins).</span></FormHelperText>
                                                                <FormHelperText>{errorList['startTimeIncrement'] ? <span style={{ color: "red" }}>{errorList['startTimeIncrement']}</span> : ''} </FormHelperText>
                                                            </FormControl>

                                                            <TextField fullWidth label="Invitees Can Schedule How Many Days in the Future" variant="standard" helperText={errorList['advanceDays'] ? <span style={{ color: "red" }}>{errorList['advanceDays']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.advanceDays || ''} InputLabelProps={{ shrink: formData.advanceDays }} onChange={(e) => setFormData(formData => ({ ...formData, advanceDays: e.target.value }))} />
                                                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                <InputLabel id="demo-simple-select-standard-label">Buffer Time</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    value={formData.bufferTime || ''}
                                                                    onChange={(e) => setFormData(formData => ({ ...formData, bufferTime: e.target.value }))}
                                                                    label="Buffer Time"
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    <MenuItem value="15">15 Minutes</MenuItem>
                                                                    <MenuItem value="30">30 Minutes</MenuItem>
                                                                    <MenuItem value="45">45 Minutes</MenuItem>
                                                                    <MenuItem value="60">1 Hour</MenuItem>
                                                                    <MenuItem value="90">90 Minutes</MenuItem>
                                                                    <MenuItem value="120">2 Hours</MenuItem>
                                                                    <MenuItem value="180">3 Hours</MenuItem>
                                                                    <MenuItem value="240">4 Hours</MenuItem>
                                                                </Select>
                                                                <FormHelperText><span style={{ color: "black" }}>Time before and after booking to prevent back-to-back bookings.</span></FormHelperText>
                                                                <FormHelperText>{errorList['bufferTime'] ? <span style={{ color: "red" }}>{errorList['bufferTime']}</span> : ''} </FormHelperText>
                                                            </FormControl>
                                                            <div style={{display:"grid",gridTemplateColumns:"1fr 1fr 1fr",gridGap:"10px"} }>
                                                                <div>
                                                                    <TextField fullWidth label="Minimum Notice" variant="standard" helperText={<span style={{ color: "black" }}>The time required before an resource can be booked.</span>} style={{ marginBottom: "16px" }} value={formData.minNoticeNumber || ''} InputLabelProps={{ shrink: formData.minNoticeNumber }} onChange={(e) => setFormData(formData => ({ ...formData, minNoticeNumber: e.target.value }))} />
                                                                    
                                                                </div>
                                                                <div>
                                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                        <InputLabel id="demo-simple-select-standard-label">Minimum Type</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-standard-label"
                                                                            id="demo-simple-select-standard"
                                                                            value={formData.minNoticeType || ''}
                                                                            onChange={(e) => setFormData(formData => ({ ...formData, minNoticeType: e.target.value }))}
                                                                            label="Minimum Notice Type"
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>None</em>
                                                                            </MenuItem>
                                                                            <MenuItem value="minute">Hour(s)</MenuItem>
                                                                            <MenuItem value="hour">Minute(s)</MenuItem>
                                                                            <MenuItem value="day">Day(s)</MenuItem>
                                                                        </Select>
                                                                        <FormHelperText>{errorList['minNoticeType'] ? <span style={{ color: "red" }}>{errorList['minNoticeType']}</span> : ''} </FormHelperText>
                                                                    </FormControl>

                                                                        </div>
                                                                        <div>
                                                                            <TextField fullWidth label="Max Bookings Per Day" variant="standard" helperText={errorList['dailyLimits'] ? <span style={{ color: "red" }}>{errorList['dailyLimits']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.dailyLimits || ''} InputLabelProps={{ shrink: formData.dailyLimits }} onChange={(e) => setFormData(formData => ({ ...formData, dailyLimits: e.target.value }))} />
                                                                        </div>
                                                            </div>
                                                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px" }}>
                                                                        <div>
                                                                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                                <InputLabel id="demo-simple-select-standard-label">How To Determine Timezone</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-standard-label"
                                                                                    id="demo-simple-select-standard"
                                                                                    value={formData.timezoneSetting || 'auto'}
                                                                                    onChange={(e) => setFormData(formData => ({ ...formData, timezoneSetting: e.target.value }))}
                                                                                    label="How To Determine Timezone"
                                                                                >
                                                                                    <MenuItem value="auto">Show available times in my invitee's time zone</MenuItem>
                                                                                    <MenuItem value="lock">Lock the timezone (best for in-person events)</MenuItem>
                                                                                </Select>
                                                                                <FormHelperText>{errorList['timezoneSetting'] ? <span style={{ color: "red" }}>{errorList['timezoneSetting']}</span> : ''} </FormHelperText>
                                                                            </FormControl>                                                                 
                                                                        </div>
                                                                        <div>
                                                                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                                <InputLabel id="demo-simple-select-standard-label">Show location on confirmation</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-standard-label"
                                                                                    id="demo-simple-select-standard"
                                                                                    value={formData.displayLocationOnConfirm || true}
                                                                                    onChange={(e) => setFormData(formData => ({ ...formData, displayLocationOnConfirm: e.target.value }))}
                                                                                    label="Show location on confirmation"
                                                                                >
                                                                                    <MenuItem value={true} >Yes</MenuItem>
                                                                                    <MenuItem value={false}>No</MenuItem>
                                                                                </Select>
                                                                                <FormHelperText>{errorList['displayLocationOnConfirm'] ? <span style={{ color: "red" }}>{errorList['displayLocationOnConfirm']}</span> : ''} </FormHelperText>
                                                                            </FormControl>                                                                 
                                                                        </div>
                                                                    </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="segment" style={{ marginTop: "20px" }}>
                                        <div className="segment-title" style={{ marginBottom: "20px" }}>Confirmation</div>
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Confirmation Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.confirmationType || 'Default'}
                                                            onChange={(e) => setFormData(formData => ({ ...formData, confirmationType: e.target.value }))}
                                                            label="Confirmation Type"
                                                        >
                                                            <MenuItem value="Default">Use auto generated confirmation page</MenuItem>
                                                            <MenuItem value="Custom Url">Custom Url</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{errorList['confirmationType'] ? <span style={{ color: "red" }}>{errorList['confirmationType']}</span> : ''} </FormHelperText>
                                                    </FormControl>
                                                </div>
                                                <div className="col-sm-6">
                                                    <TextField fullWidth label="Confirmation URL" variant="standard" helperText={errorList['confirmationUrl'] ? <span style={{ color: "red" }}>{errorList['confirmationUrl']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.confirmationUrl || ''} InputLabelProps={{ shrink: formData.confirmationUrl }} onChange={(e) => setFormData(formData => ({ ...formData, confirmationUrl: e.target.value }))} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="segment" style={{marginTop:"20px"}}>
                                        <div className="segment-title" style={{ marginBottom: "0px" }}>Active Schedule</div>
                                        <div style={{ marginBottom: "10px" }}>
                                            <p>Select a date range when booking will be active.
                                                <ul>
                                                    <li>Booking page or control will automatically be active after the start date and will automatically become inactive after the end date.</li>
                                                    <li>If you don't specify a start date then booking will be active immediately.</li>
                                                    <li>If you don't specify a end date then booking will stay active.</li>
                                                </ul>
                                            </p>

                                        </div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer components={['DatePicker']} style={{ marginBottom: "10px" }}>
                                                    <DesktopDatePicker
                                                        label="Start Date (optional)"
                                                        variant="Standard"
                                                        slotProps={{ textField: { fullWidth: true, helperText: errorList['startDate'] ? <span style={{ color: "red" }} > {errorList['startDate']}</span> : '' } }}
                                                        value={formData.startDate != null ? dayjs(formData.startDate) : null}
                                                        onChange={(newValue) => setFormData(formData => ({ ...formData, startDate: newValue }))} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                        <div className="col-sm-6">
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer components={['DatePicker']} style={{ marginBottom: "10px" }}>
                                                    <DesktopDatePicker
                                                        label="End Date (optional)"
                                                        variant="Standard"
                                                        slotProps={{ textField: { fullWidth: true, helperText: errorList['endDate'] ? <span style={{ color: "red" }} > {errorList['endDate']}</span> : '' } }}
                                                        value={formData.endDate != null ? dayjs(formData.endDate) : null}
                                                        onChange={(newValue) => setFormData(formData => ({ ...formData, endDate: newValue }))} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                                            </div>
                                            <div className="segment" style={{ marginTop: "20px" }}>
                                                <div className="segment-title" style={{ marginBottom: "0px" }}>Project Segmentation</div>
                                                <div style={{ marginBottom: "20px" }}>
                                                    <p>Select project, board and column you want to store appointments.</p>
                                                </div>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                        <InputLabel id="demo-simple-select-standard-label">Project Name</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-standard-label"
                                                                            id="demo-simple-select-standard"
                                                                            value={formData.projectId || ''}
                                                                            onChange={(e) => { onProjectChange(e.target.value); }}
                                                                            label="Card Type"
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>None</em>
                                                                            </MenuItem>
                                                                            {projectList.map((project, index) => (
                                                                                <MenuItem key={"projectid" + project.projectId} value={project.projectId}>{project.projectName}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        <FormHelperText>{errorList['projectId'] ? <span style={{ color: "red" }}>{errorList['projectId']}</span> : ''} </FormHelperText>
                                                                    </FormControl>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                        <InputLabel id="demo-simple-select-standard-label">Workflow Board Name</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-standard-label"
                                                                            id="demo-simple-select-standard"
                                                                            value={formData.boardId || ''}
                                                                            onChange={(e) => { onBoardChange(e.target.value); }}
                                                                            label="Card Type"
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>None</em>
                                                                            </MenuItem>
                                                                            {projectBoardMapList.map((pbMap, index) => (
                                                                                <MenuItem key={"boardid" + pbMap.boardId} value={pbMap.boardId}>{pbMap.board.boardName}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        <FormHelperText>{errorList['boardId'] ? <span style={{ color: "red" }}>{errorList['boardId']}</span> : ''} </FormHelperText>
                                                                    </FormControl>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                        <InputLabel id="demo-simple-select-standard-label">Column/Status Name</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-standard-label"
                                                                            id="demo-simple-select-standard"
                                                                            value={formData.columnId || ''}
                                                                            onChange={(e) => setFormData(formData => ({ ...formData, columnId: e.target.value }))}
                                                                            label="Card Type"
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>None</em>
                                                                            </MenuItem>
                                                                            {columnList.map((column, index) => (
                                                                                <MenuItem key={"columnid" + column.columnId} value={column.columnId}>{column.columnName}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        <FormHelperText>{errorList['columnId'] ? <span style={{ color: "red" }}>{errorList['columnId']}</span> : ''} </FormHelperText>
                                                                    </FormControl>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                </>
                                : null}
                            {currentTabIndex == 2 ?
                                <div className="segment">
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                            <div className="col-sm-12">
                                                                <BookableLocationsCtrl locationConfig={formData.locationConfigurations} onChange={(value) => setFormData(formData => ({ ...formData, locationConfigurations: value }))} onPreviewLink={(locationId, resourceId) => { previewUrl(locationId, resourceId); }} onCopyLink={(locationId, resourceId) => { copyUrl(locationId, resourceId);}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {currentTabIndex == 3 ?
                                <div className="segment">
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <QuestionListCtrl isQuiz={false} questions={formData.questions} onChange={(value) => setFormData(formData => ({ ...formData, questions: value}))} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div style={{ textAlign: "right", borderTop: "5px solid #f5f5f5", paddingTop: "20px" }}>
                                {currentTabIndex > 0 ?
                                    <Button variant="outlined" style={{ display: "inline-block", backgroundColor: "grey", color: "white", border: "1px solid grey" }} onClick={() => changeTab('prev')}><ArrowBackIcon />{getTranslation('contact_search_page', 'search_grid', 'Prev')}</Button>
                                    : null}
                                {currentTabIndex < 3 ?
                                    <Button variant="outlined" style={{ display: "inline-block", marginLeft: "20px", backgroundColor: "grey", color: "white", border: "1px solid grey" }} onClick={() => changeTab('next')}>{getTranslation('contact_search_page', 'search_grid', 'Next')}<ArrowForwardIcon /></Button>
                                    : null}
                            </div>

                        </div>
                        <div className="col-sm-5" style={{borderLeft:"10px solid #f2f2f2"}}>
                            <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>Preview</h1>
                            <div style={{marginBottom:"20px"}}>Press the save button to see updated changes.</div>
                            <BookingCtrl resetId={resetId} highlightCurrentDate={true} daysInFuture={formData.advanceDays} selectedDates={new Set(selectedDates)} resources={resources} onSelectedDateChange={(selDate, bookableItemId, locationId, resourceId) => { onDateChanged(selDate, bookableItemId, locationId, resourceId); }} bookableItemList={bookableItemList} onLocationChanged={(bookableItemId, locationId) => { getResources(bookableItemId, locationId); }} onResourceChanged={(bookableItemId, locationId, resourceId) => { getAvailableDates(bookableItemId, locationId, resourceId); }} selectedDate={selectedDate} availSlots={availSlots} onComplete={(timezoneName, bookableItemId, selectedLocationId, selectedResourceId, confirmedDateTime, answers, payInfo) => { onComplete(timezoneName, bookableItemId, selectedLocationId, selectedResourceId, confirmedDateTime, answers, payInfo); }} />
                        </div>
                    </div>
                </div>
            }
            <Snackbar
                open={copySnackbarOpen}
                autoHideDuration={6000}
                onClose={handleCopySnackClose}
                message="URL copied to clipboard" />
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete tracking pixel. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }


    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "bookableitems/delete?bookableitemid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        //if (primaryKeyValue == 0) {
        //    formData.dateCreated = new Date().toISOString();
        //} else {
        //    formData.dateModified = new Date().toISOString();
        //}
    }

    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm();
            }
        }                
    }

    function saveForm() {
        let tmpFormData = { ...formData };
        tmpFormData.locationConfigurations = JSON.stringify(tmpFormData.locationConfigurations);
        tmpFormData.questions = JSON.stringify(tmpFormData.questions);

        if (primaryKeyValue == 0) {
            BAFetchData({
                callName: "SaveForm", method: "POST", url: "bookableitems/add",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "bookableitems/update",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        if (formData.confirmationType != "Default") {
            if (formData.confirmationUrl == null || formData.confirmationUrl == "") {
                isValid = false;
                tempErrorList['confirmationUrl'] = "Confirmation URL is required when ConfirmationType is set to 'Use auto generated confirmation page'.";
            }
        }
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default BookableItem;
