import React, {useEffect, useState} from "react";
import "./css/calendar.css";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function CalendarCtrl({viewType = "client-booking",highlightCurrentDate = true, showPastSelecteddDates = false, selectedDates, daysInFuture = 7300, initialDate = new Date(), handleOnDateSelected}){
    const today = new Date();
    const todayMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const futureDate = new Date(new Date().setDate(new Date().getDate() + daysInFuture));



    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    const [dates, setDates] = useState([]);
    const [monthItem, setMonthItem] = useState({month: null, year: null, daysInMonth:0, dowStart:0});

    function changeMonth(date){       
        const firstDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

        const tmpMonth = firstDateOfMonth.getMonth();
        const tmpYear = firstDateOfMonth.getFullYear();
        const tmpDowStart = firstDateOfMonth.getDay();       
        const daysInMonth = getDaysInMonth(tmpYear, tmpMonth);

        const tmpMonthItem = {...monthItem};
        tmpMonthItem.month = tmpMonth;
        tmpMonthItem.year = tmpYear;
        tmpMonthItem.daysInMonth = daysInMonth;
        tmpMonthItem.dowStart = tmpDowStart;        
        setMonthItem(tmpMonthItem);       
    }

    function buildDates(){
       let leadingDates = Array(monthItem.dowStart).fill(null);
       let trailingDates = Array(42 - (monthItem.dowStart + monthItem.daysInMonth)).fill(null);

       let tmpDates = [];

       for (let i = 1; i < monthItem.daysInMonth + 1; i++) {
        const date = new Date(monthItem.year, monthItem.month, i);
        tmpDates.push(date);
       }                  

       const finalArray = leadingDates.concat(tmpDates,trailingDates);
       setDates(finalArray);
    }

    function getDaysInMonth(year, month) {
        return new Date(year, month + 1, 0).getDate();
    }

    function onNavChange(direction){
        const currDate = new Date(monthItem.year, monthItem.month, 1);
        if (direction === 'prev'){            
            currDate.setMonth(currDate.getMonth() - 1);
        }else{
            currDate.setMonth(currDate.getMonth() + 1);
        }
        changeMonth(currDate);
    }

    useEffect(() => {
      buildDates();      
    },[monthItem])

    useEffect(() => {
        changeMonth(initialDate);      
      },[])


      function isDateWithinSet(targetDate, dateSet) {
        const targetISO = targetDate.getFullYear() + '-' + addLeadingZero(targetDate.getMonth() + 1) + '-' + addLeadingZero(targetDate.getDate()); // Normalize target date
        return dateSet.has(targetISO);
    }

    function addLeadingZero(number) {
        return number.toString().padStart(2, '0');
      }

      
    function onDateSelected(calDate){
        handleOnDateSelected(calDate);
    } 

    function setStyle(calDate){
        const todayStyle = " today ";
        const availStyle = " avail ";
        const notAvailStyle = " not-avail";


        let className = "";
        if (calDate !== null){
            if (calDate.getFullYear() === today.getFullYear() && calDate.getMonth() === today.getMonth() && calDate.getDate() === today.getDate()){
                className += todayStyle
            }

            if ((calDate >= todayMidnight && calDate <= futureDate) ){
                if (isDateWithinSet(calDate, selectedDates)){
                    className += availStyle;
                }
            }
            
        }


        return className;
    }

    return(
        <div className="cal">
            <div className="cal-header">
                <div className="cal-nav-box">
                    <div className="cal-nav prev" onClick={(e) => {onNavChange('prev');}}><KeyboardArrowLeftIcon/></div>
                    <div className="cal-monthyear">{monthNames[monthItem.month]} {monthItem.year}</div>
                    <div className="cal-nav next" onClick={(e) => {onNavChange('next');}}><KeyboardArrowRightIcon/></div>
                </div>
                <div className="cal-dow-box">
                    {days.map((day) => {
                        return(
                            <div key={day} className="cal-dow">{day}</div>
                        )
                    })}                                        
                </div>
            </div>
            <div className="cal-dom-box">
            {dates.map((calDate) => {
                        return(
                            <div className={"cal-dom" + setStyle(calDate)} onClick={(e) => {onDateSelected(calDate);}}>{(calDate != null ? calDate.getDate() : "")}</div>
                        )
                    })}                                                        
            </div>
            
            <div className="footer">
                {(viewType === 'client-booking') ? 
                <div><span className="legend">&nbsp;</span>Available dates</div>
                : 
                <div><span className="legend">&nbsp;</span>Appointments</div>
                }
            </div>
        </div>
    )
}

export default CalendarCtrl;