import React, { useContext, useState, useEffect } from 'react';
import WidgetRouter, { BuildPropDefault, MergeStylesToCurrBP } from '../WidgetRouter';
import { DataBrokerContext } from "../PageBuilderCtrl";
import HoverCtrl, { OnWidgetClick, OnWidgetMouseEnter, OnWidgetMouseLeave, OnWidgetMouseOut, OnWidgetMouseOver, RenderWidgetLabel, RenderWidgetContextMenu } from '../HoverCtrl';
import { CollectionItemContext } from './CollectionListItemWidget';

function ImageWidget({ params, tmpElements, currBP, }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseOver, onWidgetMouseLeave, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, handleUpdateChanges, handleMergeVariables } = useContext(DataBrokerContext);
    const collectionItemContext = useContext(CollectionItemContext);
    const [customInputsObj, setCustomInputsObj] = useState(params.customInputs);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [hoverSettings, setHoverSettings] = useState({ style: { pointerEvents: "none", position: "absolute", top: 0, left: 0, width: 0, height: 0, border: "1px solid #5E95DE" }, labelPosition: "top", labels: [], show: false });
    const [selectedSettings, setSelectedSettings] = useState({ style: { pointerEvents: "none", position: "absolute", top: 0, left: 0, width: 0, height: 0, border: "1px solid #5E95DE" }, labelPosition: "top", labels: [], show: false });
    const [widgetData, setWidgetData] = useState(params);

    //const imagePlaceholder = process.env.REACT_APP_CDN_BASE_URL + "systemfiles/files/image_placeholder.webp";
    const imagePlaceholder = "https://dummyimage.com/600x338/aaa/fff";


    var timer = 0;
    var delay = 100;
    var prevent = false;

    function handleOnClick(e, id, widgetData) {
        timer = setTimeout(() => {
            if (!prevent) {
                onWidgetClick(e, id, widgetData, (updatedWidgetData) => handleOnPropertyChange(updatedWidgetData), () => handleOnHideHighlight(), () => handleOnSave());
            }
        }, delay);
        if (e != undefined) {
            e.stopPropagation();
        }
    }

    function handleOnSave() {
        //setElements({
        //    ...elements, // copy the old fields
        //    [widgetData.id]: widgetData //override with updated target widget
        //});
        //console.log('save widget data')
    }


    function handleOnHideHighlight() {
        setSelectedSettings({
            ...selectedSettings, // copy the old fields
            show: false
        });
        setHoverSettings({
            ...hoverSettings, // copy the old fields
            show: false
        });
        setShowContextMenu(false);
    }

    function handleOnPropertyChange(updatedWidgetData) {
        setWidgetData((...widgetData) => { return updatedWidgetData });
        handleUpdateChanges(updatedWidgetData);
    }

    function handleOnSettingsClick() {
        alert('show settings');
    }

    function aiImageMerge(image) {
        if (params.aiImage.accept == 'true') {
            if (params.aiImage.answer != '') {
                image = params.aiImage.answer;
            }
        }
        return image;
    }

    useEffect(() => {
        setDefaults();
    }, []);

    return (
        <>
            <img id={params.id} elementname={params.elementName} style={MergeStylesToCurrBP(params, currBP)} className={"pb-widget " + params.className} onMouseEnter={(e) => { onWidgetMouseEnter(e, params.id); }} onMouseOver={(e) => { onWidgetMouseOver(e, params.id); }} onMouseLeave={(e) => { onWidgetMouseLeave(e, params.id); }} onClick={(e) => { handleOnClick(e, params.id, params); }} onDragStart={(e) => handleOnDragFromCanvas(e, params.id, "section", "widget")} onDragOver={(e) => { handleOnDragOver(e, params.id); }} onDragLeave={(e) => { handleOnDragLeave(e, params.id); }} onDrop={(e) => { handleOnDrop(e, params.id); }}
                src={handleMergeVariables(aiImageMerge(params.designSrc || params.src), false, collectionItemContext, (collectionItemContext != undefined ? collectionItemContext.collectionDSRowIndex : null), (collectionItemContext != undefined ? collectionItemContext.parentCollectionListRowIndex : null)) || imagePlaceholder} alt={params.alt} />
        </>
    )

    function setDefaults() {
        const tmpCustomInputsObj = { ...customInputsObj };
        tmpCustomInputsObj.popupInfo.property.enableImagePopup = params.customInputs.popupInfo.property.enableImagePopup || false;
        tmpCustomInputsObj.popupInfo.property.includeGallery = params.customInputs.popupInfo.property.enableGallery || false;
        tmpCustomInputsObj.popupInfo.property.dataSource = params.customInputs.popupInfo.property.dataSource || "";
        setCustomInputsObj(tmpCustomInputsObj);
    }
}

ImageWidget.getPropSchema = function () {   

    return {
        "popupInfo": {
            caption: "Popup Info", property:
            {
                "enableImagePopup": { label: "Enable Image Popup", dataType: "bool", control: { type: "switch" }, }
                , "includeGallery": { label: "Include Gallery Data", dataType: "bool", control: { type: "switch" }, }
                , "dataSource": { label: "Data Source", dataType: "string", control: { type: "text" }, }
            }
        },
        "reactUseStates": BuildPropDefault("reactUseStates")
    };
};

export default ImageWidget;