import React, { useContext, useState, useEffect } from 'react';
import './css/pagebuilderctrl.css';
import CropSquare from '@mui/icons-material/CropSquare';
import Title from '@mui/icons-material/Title';
import { DataBrokerContext } from "./PageBuilderCtrl";
import { BAFetchData } from '../../customhooks/useBAFetch';
import { useGlobalState } from '../../globalstates/GState';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DialogCtrl from '../dialogctrl/dialogctrl';
import PublicIcon from '@mui/icons-material/Public';


function SectionListCtrl({ buildMode, componentType}) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseOver, onWidgetMouseLeave, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, handleUpdateChanges, handleMergeVariables, handleSwapSection } = useContext(DataBrokerContext);
    const [currentUser] = useGlobalState("userInfo");

    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});         
    const [sectionList, setSectionList] = useState([]);
    const [templateCategoryList, setTemplateCategoryList] = useState([]);
    const [category, setCategory] = useState('');
   
    const d = new Date();

    function determineTemplateType() {
        let templateType = "";
        if (componentType == "Sections" && buildMode != "Automation-Email") {
            templateType = "Section-Page";
        } else if (componentType == "Composites" && buildMode != "Automation-Email") {
            templateType = "Component-Page";
        } else if (componentType == "Sections" && buildMode == "Automation-Email") {
            templateType = "Section-Email";
        } else if (componentType == "Composites" && buildMode == "Automation-Email") {
            templateType = "Component-Email";
        }
        return templateType;
    }

    function beginSearch() {
        const templateType = determineTemplateType();
        var filtering = {};
        if (componentType == "Sections") {
            filtering = {
                "LogicalOperator": "or", "ConditionGroups": [{
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "templatetype", "ConditionOperator": "EQUAL", "Value": templateType, "DataType": "String" },
                        { "Name": "category", "ConditionOperator": "EQUAL", "Value": category, "DataType": "String" },
                    ]
                }]
            };
        } else if (componentType == "Composites") {
            filtering = {
                "LogicalOperator": "or", "ConditionGroups": [{
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "templatetype", "ConditionOperator": "EQUAL", "Value": "Component-Page", "DataType": "String" },
                        { "Name": "category", "ConditionOperator": "EQUAL", "Value": category, "DataType": "String" },
                    ]
                }]
            };
        }


        BAFetchData({
            callName: "GetSectionList",
            method: "GET",
            url: "template/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetSectionList":
                setSectionList(data);
                
                break;
            case "DeleteSection":
                beginSearch();
                break;
            case "GetTemplateCategoryList":
                setTemplateCategoryList(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }


    function handleDeleteConfirm(e, templateId) {
        e.preventDefault();
        e.stopPropagation();
        var msg = 'You are about to section tmplate. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); handleOnDelete(templateId) } })
        setOpenDialog(true);
    }

    function handleOnDelete(templateId) {
        BAFetchData({
            callName: "DeleteSection",
            method: "DELETE",
            url: "template/delete?templateid=" + templateId,
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getTemplateCategoryList() {
        const templateType = determineTemplateType();

        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "templatetype", "ConditionOperator": "EQUAL", "Value": templateType, "DataType": "String" },
                ]
            }]
        };
        BAFetchData({
            callName: "GetTemplateCategoryList",
            method: "GET",
            url: "template/category/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    useEffect(() => {
        getTemplateCategoryList();
    }, [componentType])

    useEffect(() => {
        beginSearch();
    },[category,componentType])

    return (
        <>
            <div>
                <div style={{fontSize:"12px"}}>Use selection category to filter the sections. Then drag and the desired section on the canvas.</div>
                <div>
                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginTop:"16px",marginBottom: "16px" }}>                        
                        <InputLabel id="demo-simple-select-standard-label">Section Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Section Category"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {templateCategoryList.map((templateCategory, index) => (
                                <MenuItem key={"category_list_" + index} value={templateCategory.categoryName}>{templateCategory.categoryName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="fg-pb-widget-list" style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: "10px" }}>
                    {sectionList.map((section, index) => (                        
                        <div key={"section_" + index} widgetname="section" className="fg-pg-widget-list-item" draggable onDragStart={(e) => handleOnDragFromList(e, (section.templateType == "Section-Page" ? "section" : "divblock"), "container", "template", section.templateId)} style={{padding:"10px"}}>
                            <div><img src={process.env.REACT_APP_CDN_BASE_URL + "/accountfiles/" + section.baid + '/templates/' + section.thumbnail + '?v=' + d.getMilliseconds()} alt="" style={{ width: "100%", border: "1px solid rgba(0, 0, 0, 0.12)" }} /></div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 40px 40px", width: "100%", marginTop: "5px" }}>
                                <div className="fg-pg-template-list-item-text" style={{ paddingTop: "3px" }}>
                                    {section.systemRecord == true ?
                                        <PublicIcon style={{ marginRight: "3px",color:"black" }} /> : <PublicIcon style={{ marginRight: "3px" }} />}
                                    {section.templateName}</div>
                                <div style={{ paddingTop: "3px", cursor:"pointer" }} onClick={(e) => handleSwapSection(e, "section", "container", "template", section.templateId)}>SWAP</div>
                                {currentUser.currentBusinessAccountDTO.baid == section.baid ?
                                    <div style={{ textAlign: "right", cursor: "pointer" }} onClick={(e) => handleDeleteConfirm(e, section.templateId)}><DeleteForeverIcon></DeleteForeverIcon></div>
                                    : <div></div>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default SectionListCtrl;