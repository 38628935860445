import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import 'highlight.js/styles/github.css';
import hljs from 'highlight.js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const CodeBlock = ({ language, value, onRunSql }) => {
    const codeRef = React.useRef(null);
    const [copied, setCopied] = useState(false);
    const [executed, setExecuted] = useState(false);

    React.useEffect(() => {
        if (codeRef.current) {
            hljs.highlightElement(codeRef.current);
        }
    }, [value]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(value).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        });
    };

    const runCode = () => {
        //navigator.clipboard.writeText(value).then(() => {
        //console.log('code', value);
        onRunSql(value);
        setExecuted(true);

            setTimeout(() => setExecuted(false), 2000); // Reset after 2 seconds
        //});
    };

    return (
        <div style={{ position: 'relative', marginBottom: '20px', maxWidth:"100%" }}>           
            <pre
                style={{
                    backgroundColor: '#f4f4f4',
                    padding: '10px',
                    borderRadius: '5px',
                    overflowX: 'auto',
                }}
            >
                <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                    <div style={{flex:"1"}}>                    
                    {language}
                    </div>
                    <div style={{width:"125px",textAlign:"right"}}>
                        <button
                            onClick={copyToClipboard}
                            style={{
                                //position: 'absolute',
                                //top: '20px',
                                //right: '20px',
                                //backgroundColor: copied ? '#4caf50' : '#007bff',
                                backgroundColor: 'transparent',
                                color: copied ? 'green' : 'black',
                                border: 'none',
                                borderRadius: '3px',
                                padding: '5px 10px',
                                cursor: 'pointer',
                                fontSize: '12px',
                            }}
                        >
                            {copied ? (
                                <>
                                    <CheckIcon style={{ fontSize: '16px' }} /> Copied
                                </>
                            ) : (
                                <>
                                    <ContentCopyIcon style={{ fontSize: '16px' }} /> Copy
                                </>
                            )}
                        </button>                        
                    </div>
                    {language == "fgsql" ?
                        <div style={{ width: "125px", textAlign: "right" }}>
                            <button
                                onClick={runCode}
                                style={{
                                    //position: 'absolute',
                                    //top: '20px',
                                    //right: '20px',
                                    //backgroundColor: copied ? '#4caf50' : '#007bff',
                                    backgroundColor: 'transparent',
                                    color: executed ? 'green' : 'red',
                                    border: 'none',
                                    borderRadius: '3px',
                                    padding: '5px 10px',
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                }}
                            >
                                {executed ? (
                                    <>
                                        <CheckIcon style={{ fontSize: '16px' }} /> Executed
                                    </>
                                ) : (
                                    <>
                                        <PlayArrowIcon style={{ fontSize: '16px' }} /> Run
                                    </>
                                )}
                            </button>
                        </div>
                        : null}
                </div>
                <code ref={codeRef} className={language} style={{maxWidth:"780px"}}>
                    {value}
                </code>
            </pre>
            {/* Copy Button */}
            
            {/*<button*/}
            {/*    onClick={copyToClipboard}*/}
            {/*    style={{*/}
            {/*        position: 'absolute',*/}
            {/*        top: '20px',*/}
            {/*        right: '20px',*/}
            {/*        //backgroundColor: copied ? '#4caf50' : '#007bff',*/}
            {/*        backgroundColor:'transparent',*/}
            {/*        color: copied ? 'green' : 'black',*/}
            {/*        border: 'none',*/}
            {/*        borderRadius: '3px',*/}
            {/*        padding: '5px 10px',*/}
            {/*        cursor: 'pointer',*/}
            {/*        fontSize: '12px',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {copied ? (*/}
            {/*        <>*/}
            {/*            <CheckIcon style={{ fontSize: '16px' }} /> Copied*/}
            {/*        </>*/}
            {/*    ) : (*/}
            {/*        <>*/}
            {/*            <ContentCopyIcon style={{ fontSize: '16px' }} /> Copy*/}
            {/*        </>*/}
            {/*    )}*/}
            {/*</button>*/}
        </div>
    );
};

const ChatResponse = ({ content, onRunSql }) => {
    const [loading, setLoading] = useState(false);

    // Simulate loading state
    React.useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 1000); // Simulate API delay
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <span
                    style={{
                        display: 'inline-block',
                        width: '30px',
                        height: '30px',
                        border: '4px solid #ccc',
                        borderTop: '4px solid #007bff',
                        borderRadius: '50%',
                        animation: 'spin 1s linear infinite',
                    }}
                />
                <style>
                    {`@keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }`}
                </style>
            </div>
        );
    }

    return (
        <ReactMarkdown
            children={content} 
            components={{
                code({ inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline && match ? (
                        <CodeBlock
                            language={match[1]}
                            value={String(children).replace(/\n$/, '')}
                            onRunSql={(sql) => { onRunSql(sql);}}
                        />
                    ) : (
                        <code style={{ backgroundColor: '#f4f4f4', padding: '2px 4px' }}>
                                {children}
                        </code>
                    );
                },
            }}
        />
    );
};

export default ChatResponse;
