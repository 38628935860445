import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import { Reorder } from "framer-motion";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Editor } from '@tinymce/tinymce-react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

function QuestionCtrl({ questionItem, questionIndex, questions, setQuestions, isQuiz }) {
    const answerLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
    const [expandIds, setExpandIds] = useState([]);
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const [questionItemObj, setQuestionItemObj] = useState(questionItem);
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "contact_search_page", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };

    const translationData = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }


    function handleChangeEditor(editor) {
        var tmpQuestionItemObj = { ...questionItemObj };
        tmpQuestionItemObj.question = editor;
        setQuestionItemObj(tmpQuestionItemObj);
    }

    function toggleExpandList(questionId) {
        var tmpExpandIds = [...expandIds];
        if (tmpExpandIds.includes(questionId) == true) {
            var index = tmpExpandIds.indexOf(questionId);
            tmpExpandIds.splice(index, 1);
        } else {
            tmpExpandIds.push(questionId);
        }
        setExpandIds(tmpExpandIds);
    }

    function onHandleAddAnswerOption() {
        var newAnswerOption = {
            "text": "Answer Option",
            "image": null,
            "correctAnswer": false,
            "tag": null
        }

        var tmpQuestionItemObj = {...questionItemObj};
        tmpQuestionItemObj.answerOptions.push(newAnswerOption);
        setQuestionItemObj(tmpQuestionItemObj);
    }

    function updateAnswerOption(propertyName, value, index) {
        var tmpQuestionItemObj = { ...questionItemObj };
        tmpQuestionItemObj.answerOptions[index][propertyName] = value;
        setQuestionItemObj(tmpQuestionItemObj);
    }

    function updateQuestionTypeOptions(questionType) {
        console.log('questionType', questionType);
        var tmpQuestionItemObj = { ...questionItemObj };
        tmpQuestionItemObj.questionType = questionType;
        switch (questionType) {
            case 'MultipleChoice':
                tmpQuestionItemObj.answerOptions = [];
                break;
            case 'MultiSelect':
                tmpQuestionItemObj.answerOptions = [];
                break;
            case 'True/False':
                tmpQuestionItemObj.answerOptions = [];
                tmpQuestionItemObj.answerOptions.push({
                    "text": "True",
                    "image": null,
                    "correctAnswer": false,
                    "tag": null
                })
                tmpQuestionItemObj.answerOptions.push({
                    "text": "False",
                    "image": null,
                    "correctAnswer": false,
                    "tag": null
                })
                break;
            case 'Yes/No':
                tmpQuestionItemObj.answerOptions = [];
                tmpQuestionItemObj.answerOptions.push({
                    "text": "Yes",
                    "image": null,
                    "correctAnswer": false,
                    "tag": null
                })
                tmpQuestionItemObj.answerOptions.push({
                    "text": "No",
                    "image": null,
                    "correctAnswer": false,
                    "tag": null
                })
                break;
            case 'ExactPhrase':
                tmpQuestionItemObj.answerOptions = [];
                tmpQuestionItemObj.answerOptions.push({
                    "text": "Enter exact word or phrase",
                    "image": null,
                    "correctAnswer": true,
                    "tag": null
                })
                break;
            case 'FreeText':
                tmpQuestionItemObj.answerOptions = [];
                break;
        }
        setQuestionItemObj(tmpQuestionItemObj);
    }

    function removeQuestion() {
        var tmpQuestions = [...questions];
        tmpQuestions.splice(questionIndex, 1);
        setQuestions(tmpQuestions);
    }

    function removeAnswer(questionIndex, optionIndex) {
        var tmpQuestions = [...questions];
        tmpQuestions[questionIndex].answerOptions.splice(optionIndex, 1);
        setQuestions(tmpQuestions);
    }

    function buildGridTemplatecolumns(isQuiz) {
        let css = "30px 1fr 50px";
        if (isQuiz == true) {
            css = "30px 1fr 50px 50px";
        }
        return css;
    }

    useEffect(() => {
        var tmpQuestions = [...questions];

        for (var i = 0; i < tmpQuestions.length; i++) {
            if (tmpQuestions[i].questionId == questionItemObj.questionId) {
                tmpQuestions[i] = questionItemObj;
                setQuestions(tmpQuestions);
                break;
            }
        }
    }, [questionItemObj]);

    useEffect(() => {
        setQuestionItemObj(questionItem);
    }, [questionItem]);

    function disabledRequiredElement(fieldName) {
        let disabldRequired = true;
        if (fieldName != "firstName" && fieldName != "lastName" && fieldName != "email") {
            disabldRequired = false;
        }
        return disabldRequired;
    }

    function disabledQuestionType(fieldName) {
        let disabldRequired = true;
        if (fieldName != "firstName" && fieldName != "lastName" && fieldName != "email" && fieldName != "phone") {
            disabldRequired = false;
        }
        return disabldRequired;
    }

    return (
        <>
            <Reorder.Item key={questionItemObj.questionId} value={questionItemObj}>
                <div style={{ border: "1px solid #f1f1f1", padding: "10px 10px 0px 10px", marginTop: "20px", borderRadius: "7px", backgroundColor:"#F5F5F5"}}>
                    <div style={{ display: "grid", gridTemplateColumns: "30px 1fr 180px 20px", gridGap:"20px"}}>
                        <div>
                            <DragIndicatorIcon/>
                        </div>
                        <div>
                            <TextField id="question" required fullWidth multiline maxRows={4} label={"Question #" + (questionIndex + 1)} variant="standard" style={{ marginBottom: "16px" }} value={questionItemObj.question || ''} InputLabelProps={{ shrink: questionItemObj.question }} onChange={(e) => setQuestionItemObj(questionItemObj => ({ ...questionItemObj, question: e.target.value }))} />
                        </div>
                        <div>
                            <Button fullWidth variant="outlined" style={{ backgroundColor: "#EEEEEE", color: "black", border: "1px solid #d2d2d2" }} onClick={(e) => { toggleExpandList(questionItemObj.questionId); }}>{getTranslation('contact_search_page', 'search_grid', 'Show/Hide Details')}</Button>
                        </div>
                        <div style={{ textAlign: "right", paddingTop: "5px" }}>
                            {(questionItemObj.fieldName != "firstName" && questionItemObj.fieldName != "lastName" && questionItemObj.fieldName != "email" && questionItemObj.fieldName != "phone") ?
                                <DeleteForeverIcon style={{ cursor: "pointer" }} onClick={(e) => { removeQuestion(); }} />
                                : null}
                        </div>
                    </div>
                    {expandIds.includes(questionItemObj.questionId) == true ?
                        <div>
                            <div style={{ marginTop: "16px" }}>
                                <div style={{display:"grid",gridTemplateColumns:"1fr 200px",gridGap:"20px"}}>
                                    <div>
                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                            <InputLabel id="demo-simple-select-standard-label">Question Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={questionItemObj.questionType || ''}
                                                onChange={(e) => { updateQuestionTypeOptions(e.target.value); }}
                                                label="Question Type"
                                                disabled={disabledQuestionType(questionItemObj.fieldName)}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="MultipleChoice">Multiple Choice Question</MenuItem>
                                                <MenuItem value="MultiSelect">Multi Select Question</MenuItem>
                                                <MenuItem value="True/False">True/False Question</MenuItem>
                                                <MenuItem value="Yes/No">Yes/No Question</MenuItem>
                                                <MenuItem value="ExactPhrase">Exact Word/Phrase Match Question</MenuItem>
                                                <MenuItem value="FreeTextOneLine">Free Text - One Line</MenuItem>
                                                <MenuItem value="FreeTextMultiLine">Free Text - Multiline</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{paddingTop:"10px"}}>
                                        <FormControlLabel style={{ marginBottom: "10px" }}
                                            control={
                                                <Switch checked={questionItemObj.required} disabled={disabledRequiredElement(questionItemObj.fieldName)} name="gilad" onChange={(e) => setQuestionItemObj(questionItemObj => ({ ...questionItemObj, required: e.target.checked }))} />
                                            }
                                            label="Required"
                                        />
                                    </div>
                                </div>
                                
                            </div>
                            {(questionItemObj.questionType == "MultipleChoice" || questionItemObj.questionType == "MultiSelect" || questionItemObj.questionType == "True/False" || questionItemObj.questionType == "Yes/No" || questionItemObj.questionType == "ExactPhrase") ?
                            <div style={{ marginTop: "10px" }}>
                                    <div style={{ display: "grid", gridTemplateColumns: buildGridTemplatecolumns(isQuiz), gridGap: "10px", fontWeight: "bold", marginBottom: "10px" }}>
                                    <div>#</div>
                                    <div>Answer Options</div>
                                        {isQuiz == true ?
                                            <div>Correct</div>
                                            : null}
                                </div>
                                {questionItemObj.answerOptions.map((answerOption, index) => (
                                    <div style={{ display: "grid", gridTemplateColumns: buildGridTemplatecolumns(isQuiz), gridGap: "10px" }}>
                                        <div>{answerLabels[index]}</div>
                                        <div>
                                            <TextField fullWidth label="Answer Option" variant="standard" style={{ marginBottom: "16px" }} value={answerOption.text || ''} InputLabelProps={{ shrink: answerOption.text }} onChange={(e) => { updateAnswerOption('text', e.target.value, index); }} />
                                        </div>
                                        {isQuiz == true ?
                                            <div style={{ textAlign: "center" }}>
                                                <FormControlLabel style={{ marginBottom: "10px" }}
                                                    control={
                                                        <Switch checked={answerOption.correctAnswer} name="gilad" onChange={(e) => { updateAnswerOption('correctAnswer', e.target.checked, index); }} />
                                                    }
                                                />
                                            </div>
                                            : null}
                                        <div style={{ textAlign: "right", paddingTop: "5px" }}>
                                            <DeleteForeverIcon style={{ cursor: "pointer" }} onClick={(e) => { removeAnswer(questionIndex, index); }} />
                                        </div>
                                    </div>
                                ))}
                                    <div style={{paddingBottom:"10px"}}>
                                    <Button variant="outlined" style={{ backgroundColor: "#000000", color: "white", border: "1px solid #000000" }} onClick={(e) => { onHandleAddAnswerOption();}}>{getTranslation('contact_search_page', 'search_grid', 'Add Answer Option')}</Button>
                                </div>
                            </div>
                                : null}
                        </div>
                        : null}
                </div>
                
            </Reorder.Item>
            
        </>
    );
}

export default QuestionCtrl;
