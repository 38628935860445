import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CtrlPageTitle from '../components/applayout/pagetitle/CtrlPageTitle';
import Button from '@mui/material/Button';
import LoaderSpinnerCtrl from '../components/loaderspinnerctrl/loaderspinnerctrl';
import useBAFetch, { BAFetchData } from '../customhooks/useBAFetch';
import helpers from '../helpers/Helpers';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectCtrl from '../components/formcontrols/multiselectctrl';
import LookupFieldCtrl from '../components/formcontrols/LookupFieldCtrl';
import ContactSearch from './ContactSearch';
import BoardColumnCtrl from '../components/formcontrols/BoardColumnCtrl';
//import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
//import MaterialReactTable from 'material-react-table';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import DialogPlusCtrl from '../components/dialogctrl/dialogplusctrl';
import { Editor } from '@tinymce/tinymce-react';
import './css/apphtmleditor.css';
import { useGlobalState } from '../globalstates/GState';
import Badge from '@mui/material/Badge';
import Autocomplete from "@mui/material/Autocomplete";


function CardSettings({ dialogType, handlerReturnValue, cardId, cardType, setAcceptAfterUpdate, contactId }) {

    const [currentUser] = useGlobalState("userInfo");
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };
    //let params = useParams();

    //const [gridId, setGridId] = useState(helpers.getUUID());

    const [columnSchemas, setColumnSchemas] = useState({});
    const [errorList, setErrorList] = useState({});

    const [formData, setFormData] = useState(null);
    const [createdByName, setCreatedByName] = useState('');
    const [projectList, setProjectList] = useState([]);
    const [projectBoardMapList, setProjectBoardMapList] = useState([]);
    const [columnList, setColumnList] = useState([]);

    const [primaryKeyValue, setPrimaryKeyValue] = useState(cardId);
    const [data, setData] = useState({isCompany:false});
    const [searchRefetch, setSearchRefetch] = useState(false);
    const [timezoneList, setTimezoneList] = useState([]);

    const [tagsDataSource, setTagsDataSource] = useState([]);
    const [tagsSelected, setTagsSelected] = useState([]);

    var filtering = {
        "LogicalOperator": "and", "ConditionGroups": [{
            "LogicalOperator": "and", "Conditions": [
                { "Name": "object", "ConditionOperator": "EQUAL", "Value": "automation_settings_page", "DataType": "String" },
                { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }]
        }]
    };
    const translationData  = useBAFetch(
        {
            method: "GET",
            url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234"
        }
    );

    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < translationData.data.length; i++) {
            if (translationData.data[i].object == object && translationData.data[i].category == category && translationData.data[i].key == key) {
                value = translationData.data[i].translation;
                break;
            };
        }
        return value;
    }

    const pageTitle = getTranslation('automation_settings_page', 'header_section', 'Card Item Setting');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    const [showLoader, setShowLoader] = useState(true);
    const [loaderSchema, setLoaderSchema] = useState({});


    const headerOptions = { elements: [], breadcrumbs:[] };
    if (cardId != 0) {
        headerOptions.elements.push(
            {
                type: "dropdown", text: getTranslation('automation_settings_page', 'header_section', 'Actions'), name: "Actions", icon: "", url: null, color: "btn-outline-secondary", elements: []
            }
        )
    }

    if (helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectCards', ['allowEdit']) == true) {
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('automation_settings_page', 'header_section', 'Archive'), name: "Archive", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleArchiveClick(); } });
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('automation_settings_page', 'header_section', 'Roadblock'), name: "Roadblock", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRoadblockClick(); } });
    }
    if (helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectCards', ['allowDelete']) == true) {
        headerOptions.elements[0].elements.push({ type: "dropdown", text: getTranslation('automation_settings_page', 'header_section', 'Delete'), name: "Delete", icon: "", url: null, target: "_blank", color: "btn-outline-secondary", options: null, function: () => { handleRecordDeleteConfirm(); } });
    }
    if ((helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectCards', ['allowEdit']) == true) || (helpers.permissionAllowed(currentUser.permissions, 'ProjectManagement-ProjectCards', ['allowAdd']) == true && primaryKeyValue == '0')) {
        headerOptions.elements.push({ type: "button", text: getTranslation('automation_settings_page', 'header_section', 'Save'), caption: "Save", icon: "bi bi-plus-lg", url: null, target: "", color: "btn-primary", options: null, function: () => { handleSaveForm() } })
    }
    headerOptions.breadcrumbs = [{ name: "Home", url: "/" }, { name: "Contacts", url: null, function: null }];

    function addDefaultData(data) {
        if (primaryKeyValue == '0') {
            data.cardMembers = [];
            data.estCost = 0;
            data.createdBy = currentUser.currentBusinessAccountDTO.bamId;
            if (dialogType == 'popup') {
                data.cardType = cardType;
            }
        }        
    }

    function getTimezoneList() {
        BAFetchData({
            callName: "GetTimezoneList",
            method: "GET",
            url: "time/timezone/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getCreatedByName(data) {
        if (primaryKeyValue != '0') {
            BAFetchData({
                callName: "GetCreatedByName", method: "GET", url: "security/businessaccountmember/getmembername?bamid=" + data.createdBy,
                token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }

    const tags_onTagDBInsert = (criteria, handleOnDBInsertCallbackComplete) => {
        const test = 'test';
        var tag = {
            "tagId": 0,
            "baid": 0,
            "tagName": criteria,
            "tagType": "Appointment",
            "active": true,
            "dateCreated": new Date().toISOString(),
            "total": 0,
            "internalState": "added"
        }

        BAFetchData({
            callName: "AddTag", method: "POST", url: "crm/tag/add",
            token: "fg1234", body: tag, onSuccess: handleOnDBInsertCallbackComplete, onError: onAPIError
        });
    }

    const tags_onTagAdded = (id, text) => {
        var found = false;
        var temp = JSON.parse(JSON.stringify(formData));
        for (var i = 0; i < temp.tagMappings.length; i++) {
            if (temp.tagMappings[i].tagId == id) {
                found = true;
                if (temp.tagMappings[i].tagMapId > 0) {
                    temp.tagMappings[i].internalState = "unchanged";
                }
                break;
            }
        }
        if (found == false) {
            var tagMappingDTO = {
                "tagMapId": 0,
                "baid": 0,
                "tagId": id,
                "cardId": primaryKeyValue,
                "refType": "Appointment",
                "dateCreated": new Date().toISOString(),
                "tagName": text,
                "internalState": "added"
            }
            temp.tagMappings.push(tagMappingDTO);
        }
        setFormData(temp);
    }

    const tags_onTagRemoved = (id) => {
        var found = false;
        var remove = false;
        var temp = JSON.parse(JSON.stringify(formData));
        for (var i = 0; i < temp.tagMappings.length; i++) {
            if (temp.tagMappings[i].tagId == id) {
                found = true;
                if (temp.tagMappings[i].tagMapId == 0) {
                    temp.tagMappings.splice(i, 1);
                } else {
                    temp.tagMappings[i].internalState = "deleted";
                }
                break;
            }
        }
        setFormData(temp);
    }

    function tags_onTagSearch(criteria, handleOnSearchCallbackComplete) {
        if (criteria != null && criteria != '') {

            var filtering = {
                "LogicalOperator": "and", "ConditionGroups": [{
                    "LogicalOperator": "and", "Conditions": [
                        { "Name": "TagType", "ConditionOperator": "EQUAL", "Value": "Appointment", "DataType": "String" },
                        { "Name": "TagName", "ConditionOperator": "STARTSWITH", "Value": criteria, "DataType": "String" }
                    ]
                }]
            };


            BAFetchData({
                callName: "GetTags", method: "GET", url: "crm/tag/getlist?filtering=" + JSON.stringify(filtering),
                token: "fg1234", onSuccess: handleOnSearchCallbackComplete, onError: onAPIError
            });

        }
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "GetMainRecord":
                addDefaultData(data);
                setFormData(data);
                setColumnSchemas(helpers.updateSchemaAliases(JSON.parse(JSON.stringify(columnSchemas)), data));
                getProjectListData();
                getBoardListData(data.projectId);
                getColumnListData(data.boardId);
                getCreatedByName(data);
                setTagsSelected(data.tagMappings);
                //getTimezoneList();
                break;
            case "GetTags":
                setTagsDataSource(data);
                break;
            case "GetCreatedByName":
                if (data != "") {
                    setCreatedByName(data);
                }
                break;
            case "GetProjectList":
                setProjectList(data);
                break;
            case "GetBoardList":
                setProjectBoardMapList(data);
                break;
            case "GetColumnList":
                setColumnList(data);
                break;
            case 'SaveNewForm':
            case "SaveForm":
                setPrimaryKeyValue(data.cardId);
                setFormData(data);
                var msg = 'Form has been saved.';
                setGenericDialogSchema({ title: 'Form Saved', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); setAcceptAfterUpdate(true); handlerReturnValue(data.cardId); } })
                setOpenDialog(true);
                break;
            case "GetColumnSchema":
                //merge 
                var dbColumnSchemas = data;
                var customSchemas = [];
                //customSchemas.push({ id: null, tableSchema: null, nestedRecord: null, tableName: "crm_contacts", columnName: "phone", isPrimaryKey: null, isForeignKey: null, columnDefault: null, isRequired: null, dataType: null, minLength: null, maxLength: 12, minRecords: null, maxRecords: null, numericPrecision: null, numericScale: null, udtName: null, alias: null });

                setColumnSchemas(helpers.mergeSchemas(dbColumnSchemas, customSchemas));
                break;
            case 'DeleteRecord':
                var msg = 'Record(s) has been deleted.';
                setGenericDialogSchema({ title: 'Deletion Completed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); setAcceptAfterUpdate(true); handlerReturnValue(0); } })
                setOpenDialog(true);
                break;
            case 'GetContactLookup':
                var tmpFormData = { ...formData };
                if (packageObj == 'CardMemberSearch') {
                    var cardMemberObj = {
                        cardMemberId: helpers.getUUID(), baid: 0, cardId: formData.cardId, contactId: data.contactId,
                        contact: { contactId: data.contactId, contactName: data.contactName, firstName: data.firstName, lastName: data.lastName, profileImg: data.profileImg },
                        internalState: "added"
                    };
                    tmpFormData.cardMembers.push(cardMemberObj);
                } else if (packageObj == 'ReferenceContactSearch') {
                    tmpFormData.contactRefId = data.contactId;
                    tmpFormData.referenceContact = { "contactId": data.contactId, "firstName": data.firstName, "lastName": data.lastName, "businessName": data.businessName, "isCompany": data.isCompany, "contactName": data.contactName, "email": data.email, "phone": data.phone, "systemRecord": data.systemRecord };
                }
                setFormData(tmpFormData);
                break;
            case 'ReferenceContactSearchStartUp':
                var tmpFormData = { ...formData };
                tmpFormData.contactRefId = data.contactId;
                tmpFormData.referenceContact = { "contactId": data.contactId, "firstName": data.firstName, "lastName": data.lastName, "businessName": data.businessName, "isCompany": data.isCompany, "contactName": data.contactName, "email": data.email, "phone": data.phone, "systemRecord": data.systemRecord };
                if (cardType == 'Appointment') {
                    var cardMemberObj = {
                        cardMemberId: helpers.getUUID(), baid: 0, cardId: formData.cardId, contactId: data.contactId,
                        contact: { contactId: data.contactId, contactName: data.contactName, firstName: data.firstName, lastName: data.lastName, profileImg: data.profileImg },
                        internalState: "added"
                    };
                    tmpFormData.cardMembers.push(cardMemberObj);
                }
                setFormData(tmpFormData);
                break;
            case 'GetTimezoneList':
                setTimezoneList(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }

    function onColumnsChange(e, tmpColumnList) {
        var tmpFormData = { ...formData };
        tmpFormData.columns = tmpColumnList;
        setFormData(tmpFormData);
    }

    function openContactSearch(e, returnCategory) {
        if (e != undefined) {
            e.preventDefault();
            e.stopPropagation();
        }

        var msg = '';
        setGenericDialogSchema({ title: 'Contact Search', component: (dialogType, setReturnValue, handleDialogOnOK, handleCancelClick, setAcceptAfterUpdate) => <ContactSearch handlerReturnValue={setReturnValue} handleDialogOnOK={handleDialogOnOK} handleCancelClick={handleCancelClick} setAcceptAfterUpdate={setAcceptAfterUpdate} isLookup={true}></ContactSearch>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK(returnCategory, returnValues); } })
        setOpenDialog(true);

    }



    function onPopupOK(returnCategory, returnValues) {
        switch (returnCategory) {
            case 'CardMemberSearch':
                BAFetchData({
                    callName: "GetContactLookup",
                    method: "GET",
                    url: "crm/contact/getrecord?contactid=" + returnValues,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError,
                    packageObj: returnCategory
                });
                break;
            case 'ReferenceContactSearch':
                BAFetchData({
                    callName: "GetContactLookup",
                    method: "GET",
                    url: "crm/contact/getrecord?contactid=" + returnValues,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError,
                    packageObj: returnCategory
                });
                break;
            case 'ReferenceContactSearchStartUp':
                BAFetchData({
                    callName: returnCategory,
                    method: "GET",
                    url: "crm/contact/getrecord?contactid=" + returnValues,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError,
                    packageObj: returnCategory
                });
                break;
        }
    }

    function getProjectListData() {
        BAFetchData({
            callName: "GetProjectList",
            method: "GET",
            url: "pm/project/getlist?filtering={}",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getBoardListData(projectId) {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "projectid", "ConditionOperator": "EQUAL", "Value": projectId, "DataType": "String" }
                ]
            }]
        };
        BAFetchData({
            callName: "GetBoardList",
            method: "GET",
            url: "pm/projectboardmap/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function getColumnListData(boardId) {
        var filtering = {
            "LogicalOperator": "and", "ConditionGroups": [{
                "LogicalOperator": "and", "Conditions": [
                    { "Name": "boardid", "ConditionOperator": "EQUAL", "Value": boardId, "DataType": "String" }
                ]
            }]
        };
        BAFetchData({
            callName: "GetColumnList",
            method: "GET",
            url: "pm/column/getlist?filtering=" + JSON.stringify(filtering),
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onProjectChange(projectId) {
        var tmpFormData = { ...formData };
        tmpFormData.projectId = projectId;
        tmpFormData.boardId = null;
        tmpFormData.columnId = null;
        setFormData(tmpFormData);
        getBoardListData(projectId);
        setColumnList([]);
    }

    function onBoardChange(boardId) {
        var tmpFormData = { ...formData };
        tmpFormData.boardId = boardId;
        tmpFormData.columnId = null;
        setFormData(tmpFormData);
        getColumnListData(boardId);
    }

    function handleChangeEditor(propertyName,editor) {
        var tmpFormData = { ...formData };
        tmpFormData[propertyName] = editor;
        setFormData((...formData) => { return tmpFormData });
    }

    function removeCardMember(e, index) {
        var tmpFormData = { ...formData };
        if (tmpFormData.cardMembers[index].internalState != 'added') {
            tmpFormData.cardMembers[index].internalState = 'deleted';
        } else {
            tmpFormData.cardMembers.splice(index, 1);
        }
        setFormData(tmpFormData);
    }

    useEffect(() => {
        setShowLoader(false);            
    }, []);

    useEffect(() => {
        if (JSON.stringify(columnSchemas) != '{}') {
            if (formData == null) {
                BAFetchData({
                    callName: "GetMainRecord",
                    method: "GET",
                    url: "pm/card/getrecord?cardId=" + primaryKeyValue,
                    token: "fg1234",
                    toggleRefetch: searchRefetch,
                    onSuccess: onAPISuccess, onError: onAPIError
                });
            } else {
                if (primaryKeyValue == '0') {
                    if (contactId != undefined) {
                        onPopupOK("ReferenceContactSearchStartUp", contactId);
                    }
                }
            }
        }
    }, [columnSchemas]);

    useEffect(() => {

        var columnSchemaFiltering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "tablename", "ConditionOperator": "EQUAL", "Value": "pm_cards", "DataType": "String" }] }] }
        BAFetchData({
            callName: "GetColumnSchema",
            method: "GET",
            url: "columnschema/getlist?filtering=" + JSON.stringify(columnSchemaFiltering),
            token: "fg1234",
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }, []);

    function handleAvatarMouseEnter(e,id) {
        var el = document.getElementById(id);
        el.style.display = "inline";
    }

    function handleAvatarMouseLeave(e,id) {
        var el = document.getElementById(id);
        el.style.display = "none";
    }

    return (
        <>
            <CtrlPageTitle pageTitle={pageTitle} recordText={formData != null ? (formData.cardNumber != null ? `#-${formData.cardNumber}` : null ): null} headerOptions={headerOptions} />

            {formData == null && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
            {formData != null &&
                
                <div className="fg-app-page-box" style={{ width: "900px" }}>
                    {(JSON.stringify(errorList) != "{}") ? <div className="fg-page-error-box">There are errors on this page</div> : null}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="segment">
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    {formData.roadblock == true ?
                                                        <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "15px", color: "#BC2130" }}>This card item has a roadblock.&nbsp;<small style={{ fontSize: "14px", fontWeight: "normal", textDecoration: "underline", cursor:"pointer" }} onClick={(e) => setFormData(formData => ({ ...formData, roadblock: false }))}>Remove</small></div>
                                                        : null}
                                                    {formData.archive == true ?
                                                        <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "15px", color: "#1B6EC2" }}>This card item has been archived.&nbsp;<small style={{ fontSize: "14px", fontWeight: "normal", textDecoration: "underline", cursor: "pointer" }} onClick={(e) => setFormData(formData => ({ ...formData, archive: false }))}>Unarchive</small></div>
                                                        : null}
                                                    <TextField id="title"  autoFocus required fullWidth multiline maxRows={2} label="Title" variant="standard" helperText={errorList['title'] ? <span style={{ color: "red" }}>{errorList['title']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.title || ''} InputLabelProps={{ shrink: formData.title }} onChange={(e) => setFormData(formData => ({ ...formData, title: e.target.value }))} />
                                                    {(formData.cardType == 'Appointment' || formData.cardType == 'Incident' || formData.cardType == 'Opportunity' || formData.cardType == 'Task' || formData.cardType == 'User Story') ?
                                                        <>
                                                            <div style={{ fontSize: "13px", marginBottom: "10px", marginTop:"20px", padding: "0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Description</div>
                                                            <div>
                                                        <Editor
                                                            tinymceScriptSrc={process.env.REACT_APP_BASE_URL + '/tinymce/tinymce.min.js'}
                                                            onInit={(evt, editor) => editorRef.current = editor}
                                                            value={formData.description}
                                                            onEditorChange={(editor) => handleChangeEditor('description',editor)}
                                                            init={{
                                                                height: 242,
                                                                menubar: false,
                                                                toolbar_mode: 'floating',
                                                                autoresize_bottom_margin: 50,
                                                                plugins: [
                                                                    'advlist', 'autolink', 'blockquote', 'emoticons', 'lists', 'link', 'image', 'charmap',
                                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'codesample', 'fullscreen',
                                                                    'insertdatetime', 'media', 'table', 'preview', 'searchreplace', 'strikethrough', 'help', 'wordcount'
                                                                ],
                                                                toolbar: 'fullscreen | bold italic forecolor  | alignleft aligncenter ' +
                                                                    ' | bullist numlist outdent indent | code codesample | ' +
                                                                    'link image media table',
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        />
                                                    </div>
                                                        </>
                                                        : null}
                                                    {(formData.cardType == 'Bug' || formData.cardType == 'Incident') ?
                                                        <>
                                                            <div style={{ fontSize: "13px", marginBottom: "10px", marginTop: "20px", padding: "0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Steps to Reproduce</div>
                                                            <div>
                                                        <Editor
                                                            tinymceScriptSrc={process.env.REACT_APP_BASE_URL + '/tinymce/tinymce.min.js'}
                                                            onInit={(evt, editor) => editorRef.current = editor}
                                                            value={formData.stepsToReproduceError}
                                                            onEditorChange={(editor) => handleChangeEditor('stepsToReproduceError', editor)}
                                                            init={{
                                                                height: 242,
                                                                menubar: false,
                                                                toolbar_mode: 'floating',
                                                                autoresize_bottom_margin: 50,
                                                                plugins: [
                                                                    'advlist', 'autolink', 'blockquote', 'emoticons', 'lists', 'link', 'image', 'charmap',
                                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'codesample', 'fullscreen',
                                                                    'insertdatetime', 'media', 'table', 'preview', 'searchreplace', 'strikethrough', 'help', 'wordcount'
                                                                ],
                                                                toolbar: 'fullscreen | bold italic forecolor  | alignleft aligncenter ' +
                                                                    ' | bullist numlist outdent indent | code codesample | ' +
                                                                    'link image media table',
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        />
                                                    </div>
                                                        </>
                                                        : null}
                                                    {(formData.cardType == 'Bug' || formData.cardType == 'User Story') ?
                                                        <>
                                                            <div style={{ fontSize: "13px", marginBottom: "10px", marginTop: "20px", padding: "0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Acceptance Criteria</div>
                                                            <div>
                                                                <Editor
                                                                    tinymceScriptSrc={process.env.REACT_APP_BASE_URL + '/tinymce/tinymce.min.js'}
                                                                    onInit={(evt, editor) => editorRef.current = editor}
                                                                    value={formData.acceptanceCriteria}
                                                                    onEditorChange={(editor) => handleChangeEditor('acceptanceCriteria', editor)}
                                                                    init={{
                                                                        height: 242,
                                                                        menubar: false,
                                                                        toolbar_mode: 'floating',
                                                                        autoresize_bottom_margin: 50,
                                                                        plugins: [
                                                                            'advlist', 'autolink', 'blockquote', 'emoticons', 'lists', 'link', 'image', 'charmap',
                                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'codesample', 'fullscreen',
                                                                            'insertdatetime', 'media', 'table', 'preview', 'searchreplace', 'strikethrough', 'help', 'wordcount'
                                                                        ],
                                                                        toolbar: 'fullscreen | bold italic forecolor  | alignleft aligncenter ' +
                                                                            ' | bullist numlist outdent indent | code codesample | ' +
                                                                            'link image media table',
                                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                        : null}
                                                    {(formData.cardType == 'Bug' || formData.cardType == 'User Story') ?
                                                        <>
                                                            <div style={{ fontSize: "13px", marginBottom: "10px", marginTop: "20px", padding: "0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>QA Notes</div>
                                                            <div>
                                                                <Editor
                                                                    tinymceScriptSrc={process.env.REACT_APP_BASE_URL + '/tinymce/tinymce.min.js'}
                                                                    onInit={(evt, editor) => editorRef.current = editor}
                                                                    value={formData.qaNotes}
                                                                    onEditorChange={(editor) => handleChangeEditor('qaNotes', editor)}
                                                                    init={{
                                                                        height: 242,
                                                                        menubar: false,
                                                                        toolbar_mode: 'floating',
                                                                        autoresize_bottom_margin: 50,
                                                                        plugins: [
                                                                            'advlist', 'autolink', 'blockquote', 'emoticons', 'lists', 'link', 'image', 'charmap',
                                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'codesample', 'fullscreen',
                                                                            'insertdatetime', 'media', 'table', 'preview', 'searchreplace', 'strikethrough', 'help', 'wordcount'
                                                                        ],
                                                                        toolbar: 'fullscreen | bold italic forecolor  | alignleft aligncenter ' +
                                                                            ' | bullist numlist outdent indent | code codesample | ' +
                                                                            'link image media table',
                                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                        : null}
                                                </div>
                                                <div className="col-sm-4">
                                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 85px",gridGap:"20px" }}>
                                                        <div>
                                                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                <InputLabel id="demo-simple-select-standard-label">Card Type</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    value={formData.cardType || ''}
                                                                    onChange={(e) => setFormData(formData => ({ ...formData, cardType: e.target.value }))}
                                                                    label="Card Type"
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    <MenuItem value="Appointment">Appointment</MenuItem>
                                                                    <MenuItem value="Bug">Bug</MenuItem>
                                                                    <MenuItem value="Incident">Incident</MenuItem>
                                                                    <MenuItem value="Opportunity">Opportunity</MenuItem>
                                                                    <MenuItem value="Task">Task</MenuItem>
                                                                    <MenuItem value="User Story">User Story</MenuItem>
                                                                </Select>
                                                                <FormHelperText>{errorList['memberType'] ? <span style={{ color: "red" }}>{errorList['memberType']}</span> : ''} </FormHelperText>
                                                            </FormControl>
                                                        </div>
                                                        <div><FormControlLabel control={<Checkbox size="large" checked={formData.completed} onChange={(e) => setFormData(formData => ({ ...formData, completed: e.target.checked }))} />} label="Done" color="success" /></div>
                                                    </div>
                                                    {formData.cardType == "Appointment" ?
                                                        <div>
                                                            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                                <InputLabel id="demo-simple-select-standard-label">Reservation Status</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    value={formData.reservationStatus || ''}
                                                                    onChange={(e) => setFormData(formData => ({ ...formData, reservationStatus: e.target.value }))}
                                                                    label="Reservation Status"
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                                                                    <MenuItem value="No Show">No Show</MenuItem>
                                                                </Select>
                                                                <FormHelperText>{errorList['reservationStatus'] ? <span style={{ color: "red" }}>{errorList['reservationStatus']}</span> : ''} </FormHelperText>
                                                            </FormControl>
                                                        </div>
                                                        : null}
                                                    <div>
                                                        <div style={{ fontSize: "12px", paddingTop: "5px" }}>Created By:&nbsp;{createdByName}</div>
                                                    </div>
                                                    <div style={{ display: "grid", gridTemplateColumns: "100px 1fr 30px" }}>
                                                        <div style={{fontSize:"12px",paddingTop:"5px"} }>Assigned To:</div>
                                                        <div style={{ textAlign: "right" }}>
                                                            {formData.cardMembers.map((cardMember, index) => (
                                                                cardMember.internalState != 'deleted' ?
                                                                    <div style={{ display: "inline", position: "relative" }} onMouseEnter={(e) => { handleAvatarMouseEnter(e, "cd_cmid_" + cardMember.cardMemberId); }} onMouseLeave={(e) => { handleAvatarMouseLeave(e, "cd_cmid_" + cardMember.cardMemberId); }}>
                                                                        <div id={"cd_cmid_" + cardMember.cardMemberId} style={{ display: "none", cursor: "pointer", position: "absolute", right: "0", width: "15px", height: "15px", backgroundColor: "red", color: "white", borderRadius: "15px", fontSize: "12px", textAlign: "center", padding: "0px", lineHeight: "110%", zIndex: "1" }} onClick={(e) => {removeCardMember(e, index)}}>x</div>
                                                                    {cardMember.contact.profileImg != null ?
                                                                        <img key={"cardsetting_" + formData.cardId + "_member_" + index} src={cardMember.contact.profileImg} style={{ width: "32px", height: "32px", display: "inline-block", borderRadius: "50%", border: "1px solid #c0c0c0", marginRight: "3px" }} title={cardMember.contact.contactName} />
                                                                        :
                                                                        <Avatar style={{ fontSize: "15px", width: "32px", height: "32px", display: "inline-flex", marginRight: "3px" }} title={cardMember.contact.contactName}>{cardMember.contact.firstName.charAt(0) + cardMember.contact.lastName.charAt(0)}</Avatar>
                                                                    }
                                                                    </div>
                                                                : null
                                                            ))}
                                                        </div>
                                                        <div onClick={(e) => { openContactSearch(e, 'CardMemberSearch') }} style={{ cursor: "pointer" }}><PersonAddAlt1Icon style={{ width: "32px", height: "32px", color: "#C0C0C0", border: "1px solid #C0C0C0", borderRadius: "50px", padding: "3px" }} /></div>
                                                    </div>
                                                    <div style={{margin:"15px 0px 0px 0px"} }>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DesktopDateTimePicker label="Start date/time" variant="Standard" slotProps={{ textField: { fullWidth: true } }} defaultValue={formData.startDateTime != null ? dayjs(formData.startDateTime).add(currentUser.currentTimezoneDTO.timezone, 'hour') : null} onChange={(newValue) => setFormData(formData => ({ ...formData, startDateTime: newValue }))} />
                                                        </LocalizationProvider>
                                                    </div>
                                                    {formData.cardType != "Appointment" ?
                                                        <div style={{ margin: "15px 0px 0px 0px" }}>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DesktopDateTimePicker label="Due date/time" variant="Standard" slotProps={{ textField: { fullWidth: true } }} defaultValue={formData.dueDateTime != null ? dayjs(formData.dueDateTime).add(currentUser.currentTimezoneDTO.timezone, 'hour') : null} onChange={(newValue) => setFormData(formData => ({ ...formData, dueDateTime: newValue }))} />
                                                            </LocalizationProvider>
                                                        </div>
                                                        : null}
                                                    <div style={{ display: "grid", gridTemplateColumns: "160px 1fr", }}>
                                                        <div><FormControlLabel control={<Checkbox size="large" checked={formData.allDayEvent} onChange={(e) => setFormData(formData => ({ ...formData, allDayEvent: e.target.checked }))}   />} label="All day event" /></div>
                                                        <div style={{fontSize:"12px",paddingTop:"7px",textAlign:"right"}}><a href="#">Recur<br/>Settings</a></div>
                                                    </div>
                                                    <div style={{ margin: "15px 0px 0px 0px" }}>
                                                        <LookupFieldCtrl dataPackage={formData.referenceContact || ''} textFieldName="contactName" valueFieldName="contactId" label="Item Pertains To Which Contact?" onClick={(e) => { openContactSearch(e,'ReferenceContactSearch'); }} onRemove={() => { setFormData(formData => ({ ...formData, contactRefId: null })); setFormData(formData => ({ ...formData, referenceContact: null })); }}></LookupFieldCtrl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="segment" style={{marginTop:"20px"}}>
                                <div className="segment-title">Segmentation</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Project Name</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.projectId || ''}
                                                            onChange={(e) => {onProjectChange(e.target.value);}}
                                                            label="Card Type"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {projectList.map((project, index) => (
                                                                <MenuItem key={"projectid" + project.projectId} value={project.projectId}>{project.projectName}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>{errorList['projectId'] ? <span style={{ color: "red" }}>{errorList['projectId']}</span> : ''} </FormHelperText>
                                                    </FormControl>
                                                </div>
                                                <div className="col-sm-4">
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Workflow Board Name</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.boardId || ''}
                                                            onChange={(e) => { onBoardChange(e.target.value);}}
                                                            label="Card Type"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {projectBoardMapList.map((pbMap, index) => (
                                                                <MenuItem key={"boardid" + pbMap.boardId} value={pbMap.boardId}>{pbMap.board.boardName}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>{errorList['boardId'] ? <span style={{ color: "red" }}>{errorList['boardId']}</span> : ''} </FormHelperText>
                                                    </FormControl>
                                                </div>
                                                <div className="col-sm-4">
                                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Column/Status Name</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={formData.columnId || ''}
                                                            onChange={(e) => setFormData(formData => ({ ...formData, columnId: e.target.value }))}
                                                            label="Card Type"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {columnList.map((column, index) => (
                                                                <MenuItem key={"columnid" + column.columnId} value={column.columnId}>{column.columnName}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>{errorList['columnId'] ? <span style={{ color: "red" }}>{errorList['columnId']}</span> : ''} </FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ marginBottom: "20px" }}>
                                        <MultiSelectCtrl id="tagMappings" label="Tags" placeholder="Enter tag name" defaultKeyValuePair={tagsSelected} dataSourceList={tagsDataSource} remoteSource={true} dataSourceIdField="tagId" dataSourceTextField="tagName" onSearchCallback={(criteria, handleonSearchCallbackComplete) => tags_onTagSearch(criteria, handleonSearchCallbackComplete)} onDatabaseInsert={(criteria, handleOnDBInsertCallbackComplete) => tags_onTagDBInsert(criteria, handleOnDBInsertCallbackComplete)} onTagAddedCallback={(id, text) => tags_onTagAdded(id, text)} onTagRemovedCallback={(id, text) => tags_onTagRemoved(id, text)} />
                                        <div>{errorList['tagMappings'] ? <span style={{ color: "red" }}>{errorList['tagMappings']}</span> : ''}</div>
                                    </div>
                                </div>
                            </div>
                            {formData.cardType != 'Opportunity' ?
                                <div className="segment">
                                    <div className="segment-title">Estimates</div>
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <TextField id="estDur" required fullWidth label="Est. Duration" variant="standard" helperText={errorList['estDur'] ? <span style={{ color: "red" }}>{errorList['estDur']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.estDur || ''} InputLabelProps={{ shrink: formData.estDur }} onChange={(e) => setFormData(formData => ({ ...formData, estDur: e.target.value }))} />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <TextField id="estCost"  required fullWidth label="Est. Cost" variant="standard" helperText={errorList['estCost'] ? <span style={{ color: "red" }}>{errorList['estCost']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.estCost || ''} InputLabelProps={{ shrink: formData.estCost }} onChange={(e) => setFormData(formData => ({ ...formData, estCost: e.target.value }))} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :null}
                            {formData.cardType == 'Opportunity' ?
                                <div className="segment">
                                    <div className="segment-title">Expectations</div>
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <TextField id="dealValue"  required fullWidth label="Deal Value" variant="standard" helperText={errorList['dealValue'] ? <span style={{ color: "red" }}>{errorList['dealValue']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.dealValue || ''} InputLabelProps={{ shrink: formData.dealValue }} onChange={(e) => setFormData(formData => ({ ...formData, dealValue: e.target.value }))} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <TextField id="winLikelihood"  required fullWidth label="Win Likelihood" variant="standard" helperText={errorList['winLikelihood'] ? <span style={{ color: "red" }}>{errorList['winLikelihood']}</span> : ''} style={{ marginBottom: "16px" }} value={formData.winLikelihood || ''} InputLabelProps={{ shrink: formData.winLikelihood }} onChange={(e) => setFormData(formData => ({ ...formData, winLikelihood: e.target.value }))} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                            <InputLabel id="demo-simple-select-standard-label">Deal Status</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={formData.dealStatus || ''}
                                                                onChange={(e) => setFormData(formData => ({ ...formData, dealStatus: e.target.value }))}
                                                                label="Card Type"
                                                            >
                                                                <MenuItem value="">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                <MenuItem value="Open">Open</MenuItem>
                                                                <MenuItem value="Won">Won</MenuItem>
                                                                <MenuItem value="Lost">Lost</MenuItem>
                                                            </Select>
                                                            <FormHelperText>{errorList['dealStatus'] ? <span style={{ color: "red" }}>{errorList['dealStatus']}</span> : ''} </FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}                            
                        </div>
                    </div>
                </div>
            }
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
            {showLoader && <LoaderSpinnerCtrl schema={loaderSchema}></LoaderSpinnerCtrl>}
        </>
        
    );

    function handleRecordDeleteConfirm() {
        var msg = 'You are about to delete card. <span style="font-weight:bold;">Are you sure</span>?';
        setGenericDialogSchema({ title: 'Delete Confirmation', body: { innerHtml: { __html: msg } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); deleteRecord() } })
        setOpenDialog(true);
    }

    function handleArchiveClick() {
        var tmpFormData = { ...formData }
        tmpFormData.archive = true;
        setFormData(tmpFormData);
    }

    function handleRoadblockClick() {
        var tmpFormData = { ...formData }
        tmpFormData.roadblock = true;
        setFormData(tmpFormData);
    }

    
    function deleteRecord() {
        BAFetchData({
            callName: "DeleteRecord", method: "DELETE", url: "pm/card/delete?cardid=" + primaryKeyValue,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function addJustInTimeData(formData) {
        if (primaryKeyValue == 0) {
            formData.dateCreated = new Date().toISOString();
        } else {
            formData.dateModified = new Date().toISOString();
        }
    }

    function handleSaveForm() {
        //perform form data validation
        setErrorList([]);

        //Set just in time values before save
        addJustInTimeData(formData);

        if (helpers.isBasicFormValidationValid(columnSchemas, formData, setErrorList) == true) {
            if (isCustomFormValidationValid() == true) {
                saveForm();
            }
        }                
    }

    function saveForm() {
        if (primaryKeyValue == '0') {
            var tmpFormData = { ...formData };
            //tmpFormData.forecastSummary = JSON.stringify(tmpFormData.forecastSummary);
            BAFetchData({
                callName: "SaveNewForm", method: "POST", url: "pm/card/add",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        } else {
            var tmpFormData = { ...formData };
            //tmpFormData.forecastSummary = JSON.stringify(tmpFormData.forecastSummary);
            BAFetchData({
                callName: "SaveForm", method: "PUT", url: "pm/card/update",
                token: "fg1234", body: JSON.stringify(tmpFormData), onSuccess: onAPISuccess, onError: onAPIError
            });
        }
    }
    function isCustomFormValidationValid() {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        //sample custom validation
        //if (formData.isCompany == false) {
        //    if (formData.firstName == null && formData.lastName == null && formData.businessName == null) {
        //        isValid = false;
        //        tempErrorList['firstName'] = "First Name, Last Name or Business Name is required.";
        //    }
        //}
        setErrorList(tempErrorList);
        return isValid;
    }
}

export default CardSettings;