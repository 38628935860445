import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBAFetch, { BAFetchData } from '../../../customhooks/useBAFetch';
import { useGlobalState } from '../../../globalstates/GState';
import helpers from '../../../helpers/Helpers';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import ImageFieldCtrl from '../ImageFieldCtrl';
import MultiSelectCtrl from '../multiselectctrl';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function PaymentCtrl({ showPaymentMethod, payInfo, onPayInfoChange, errorList, cardOnly, onPaymentComplete }) {

    const [payInfoObj, setPayInfoObj] = useState(null);

    function onValueChange(e, propertyName, value) {
        var tmpPayInfoObj = { ...payInfoObj };
        tmpPayInfoObj[propertyName] = value;
    
        if (propertyName == 'paymentMethod') {
            if (value == 'Card') {
                clearCheckInfo(tmpPayInfoObj);
            } else if (value == 'Cash') {
                clearCardInfo(tmpPayInfoObj);
                clearCheckInfo(tmpPayInfoObj);
            } else if (value == 'Check') {
                clearCardInfo(tmpPayInfoObj);
            } else {
                clearCardInfo(tmpPayInfoObj);
                clearCheckInfo(tmpPayInfoObj);
            }
        } else if (propertyName == 'creditCardNumber') {
            if (value == '') {
                tmpPayInfoObj.paymentRef = null;
            } else {
                tmpPayInfoObj.paymentRef = value.substr(value.length - 4);
            }            
        }

        //setFormDataObj(tmpFormDataObj);
        setPayInfoObj(tmpPayInfoObj);
        onPayInfoChange(tmpPayInfoObj);
    }



    function clearCardInfo(tmpPayInfoObj) {
        tmpPayInfoObj.cardholderName = null;
        tmpPayInfoObj.cardType = null;
        tmpPayInfoObj.paymentRef = null;
        clearBillingAddress(tmpPayInfoObj);
    }

    function clearCheckInfo(tmpPayInfoObj) {
        tmpPayInfoObj.cardType = null;
        tmpPayInfoObj.paymentRef = null;
    }

    function clearBillingAddress(tmpPayInfoObj) {
        tmpPayInfoObj.cardType = null;
        tmpPayInfoObj.paymentRef = null;
        tmpPayInfoObj.billingAddress1 = null;
        tmpPayInfoObj.billingAddress2 = null;
        tmpPayInfoObj.billingCity = null;
        tmpPayInfoObj.billingState = null;
        tmpPayInfoObj.billingZip = null;
        tmpPayInfoObj.billingCountryCode = null;
    }

    function makePayment() {
        let tmpPayInfoObj = { ...payInfoObj };
        tmpPayInfoObj.paid = true;
        onPaymentComplete(tmpPayInfoObj);
    }

    useEffect(() => {
        setPayInfoObj(payInfo);
    },payInfo)

    return (
        <>
            {payInfoObj != null ?
                <>
                <div className="row">
                        <div className="col-sm-12">
                            <div style={{ marginBottom: "20px", fontWeight: "bold" }}>Enter Payment Info</div>
                            <div className="segment">
                            {showPaymentMethod == true ?
                                <div className="segment-title" style={{ fontSize: "14px", fontWeight: "500" }}>How will you be paying?</div>
                            : null}
                            <div>
                                {showPaymentMethod == true ?
                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                        <InputLabel id="paymenttype">Payment Method</InputLabel>
                                        <Select
                                            value={payInfoObj.paymentMethod}
                                            onChange={(e) => { onValueChange(e, 'paymentMethod', e.target.value) }}
                                            labelId="paymenttype"
                                            id="demo-simple-select-standard"
                                            label="Payment type"
                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            <MenuItem value="Card">Card</MenuItem>
                                            {cardOnly == undefined ?
                                                <>
                                                    <MenuItem value="Check">Check</MenuItem>
                                                    <MenuItem value="Cash">Cash</MenuItem>
                                                </>
                                                : null}
                                            <MenuItem value="ExistingPaymentMethod">Use Existing Payment Method</MenuItem>
                                        </Select>
                                        <formHelperText className="fg-helper-text">{errorList['paymentMethod'] ? <span style={{ color: "red" }}>{errorList['paymentMethod']}</span> : ''} </formHelperText>
                                    </FormControl>
                                    : null}
                                {payInfoObj.paymentMethod == 'Card' ?
                                    <div>
                                        <TextField fullWidth label="Cardholder Name" variant="standard" style={{ marginBottom: "16px" }} helperText={errorList['cardholderName'] ? <span style={{ color: "red" }}>{errorList['cardholderName']}</span> : ''} value={payInfoObj.cardholderName || ''} onChange={(e) => { onValueChange(e, 'cardholderName', e.target.value) }} />
                                        {payInfoObj.posted == false ?
                                            <>
                                                <TextField fullWidth label="Card Number" variant="standard" type="number" inputProps={{ className: "fg-number-no-arrows" }} style={{ marginBottom: "16px" }} helperText={errorList['creditCardNumber'] ? <span style={{ color: "red" }}>{errorList['creditCardNumber']}</span> : ''} value={payInfoObj.creditCardNumber || ''} onChange={(e) => { onValueChange(e, 'creditCardNumber', e.target.value) }} />
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                            <InputLabel id="expiremonth">Expire Month</InputLabel>
                                                            <Select
                                                                value={payInfoObj.creditCardExpireMonth}
                                                                onChange={(e) => { onValueChange(e, 'creditCardExpireMonth', e.target.value) }}
                                                                labelId="expiremonth"
                                                                id="demo-simple-select-standard"
                                                                label="Expire Month"
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                <MenuItem value="01">January</MenuItem>
                                                                <MenuItem value="02">February</MenuItem>
                                                                <MenuItem value="03">March</MenuItem>
                                                                <MenuItem value="04">April</MenuItem>
                                                                <MenuItem value="05">May</MenuItem>
                                                                <MenuItem value="06">June</MenuItem>
                                                                <MenuItem value="07">July</MenuItem>
                                                                <MenuItem value="08">August</MenuItem>
                                                                <MenuItem value="09">September</MenuItem>
                                                                <MenuItem value="10">October</MenuItem>
                                                                <MenuItem value="11">November</MenuItem>
                                                                <MenuItem value="12">December</MenuItem>
                                                            </Select>
                                                            <formHelperText className="fg-helper-text">{errorList['creditCardExpireMonth'] ? <span style={{ color: "red" }}>{errorList['creditCardExpireMonth']}</span> : ''} </formHelperText>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                                            <InputLabel id="expireyear">Expire Year</InputLabel>
                                                            <Select
                                                                value={payInfoObj.creditCardExpireYear}
                                                                onChange={(e) => { onValueChange(e, 'creditCardExpireYear', e.target.value) }}
                                                                labelId="expireyear"
                                                                id="demo-simple-select-standard"
                                                                label="Expire Year"
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                <MenuItem value="2024">2024</MenuItem>
                                                                <MenuItem value="2025">2025</MenuItem>
                                                                <MenuItem value="2026">2026</MenuItem>
                                                                <MenuItem value="2027">2027</MenuItem>
                                                                <MenuItem value="2028">2028</MenuItem>
                                                                <MenuItem value="2029">2029</MenuItem>
                                                                <MenuItem value="2030">2030</MenuItem>
                                                                <MenuItem value="2031">2031</MenuItem>
                                                                <MenuItem value="2032">2032</MenuItem>
                                                                <MenuItem value="2033">2033</MenuItem>
                                                                <MenuItem value="2034">2034</MenuItem>
                                                            </Select>
                                                            <formHelperText className="fg-helper-text">{errorList['creditCardExpireYear'] ? <span style={{ color: "red" }}>{errorList['creditCardExpireYear']}</span> : ''} </formHelperText>
                                                        </FormControl>
                                                    </div>

                                                    <div className="col-sm-2">
                                                        <TextField fullWidth label="CVC" variant="standard" type="number" inputProps={{ className: "fg-number-no-arrows" }} helperText={errorList['creditCardCvv'] ? <span style={{ color: "red" }}>{errorList['creditCardCvv']}</span> : ''} style={{ marginBottom: "16px" }} value={payInfoObj.creditCardCvv || ''} onChange={(e) => { onValueChange(e, 'creditCardCvv', e.target.value) }} />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <TextField fullWidth label="Credit Card Last 4 Digits" variant="standard" inputProps={{ readOnly: true, }} style={{ marginBottom: "16px" }} value={payInfoObj.paymentRef || ''} onChange={(e) => { onValueChange(e, 'paymentRef', e.target.value) }} />
                                        }
                                    </div>
                                    : null}
                                {payInfoObj.paymentMethod == 'Check' ?
                                    <div>
                                        <TextField fullWidth label="Check #" variant="standard" style={{ marginBottom: "16px" }} helperText={errorList['paymentRef'] ? <span style={{ color: "red" }}>{errorList['paymentRef']}</span> : ''} value={payInfoObj.paymentRef || ''} onChange={(e) => { onValueChange(e, 'paymentRef', e.target.value) }} />
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        {payInfoObj.paymentMethod == 'Card' ?
                            <div className="segment">
                                <div className="segment-title" style={{ fontSize: "14px", fontWeight: "500" }}>Billing address associated with payment method (No PO Box)</div>
                                <div>
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <TextField fullWidth label="Street Address" variant="standard" style={{ marginBottom: "16px" }} helperText={errorList['billingAddress1'] ? <span style={{ color: "red" }}>{errorList['billingAddress1']}</span> : ''} value={payInfoObj.billingAddress1 || ''} onChange={(e) => { onValueChange(e, 'billingAddress1', e.target.value) }} />
                                        </div>
                                        <div className="col-sm-5">
                                            <TextField fullWidth label="Apt or Suite#" variant="standard" helperText={errorList['billingAddress2'] ? <span style={{ color: "red" }}>{errorList['billingAddress2']}</span> : ''} style={{ marginBottom: "16px" }} value={payInfoObj.billingAddress2 || ''} onChange={(e) => { onValueChange(e, 'billingAddress2', e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <TextField fullWidth label="City" variant="standard" helperText={errorList['billingCity'] ? <span style={{ color: "red" }}>{errorList['billingCity']}</span> : ''} style={{ marginBottom: "16px" }} value={payInfoObj.billingCity || ''} onChange={(e) => { onValueChange(e, 'billingCity', e.target.value) }} />
                                        </div>
                                        <div className="col-sm-3">
                                            <TextField fullWidth label="State/Province" variant="standard" style={{ marginBottom: "16px" }} helperText={errorList['billingState'] ? <span style={{ color: "red" }}>{errorList['billingState']}</span> : ''} value={payInfoObj.billingState || ''} onChange={(e) => { onValueChange(e, 'billingState', e.target.value) }} />
                                        </div>
                                        <div className="col-sm-3">
                                            <TextField fullWidth label="Zip/Postal" variant="standard" style={{ marginBottom: "16px" }} helperText={errorList['billingZip'] ? <span style={{ color: "red" }}>{errorList['billingZip']}</span> : ''} value={payInfoObj.billingZip || ''} onChange={(e) => { onValueChange(e, 'billingZip', e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <TextField fullWidth label="Country Code" variant="standard" style={{ marginBottom: "16px" }} inputProps={{ maxLength: 2 }} value={payInfoObj.billingCountryCode || ''} onChange={(e) => { onValueChange(e, 'billingCountryCode', e.target.value) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <button style={{ backgroundColor: "#AFE1AF", color: "black", border: "none", padding: "15px 15px 15px 15px", borderRadius: "4px", width: "100%", fontSize: "18px", fontWeight: "bold" }} onClick={(e) => { makePayment(); }}>Make Payment</button>
                    </div>
                </>
                : null}
        </>
    );
}

export default PaymentCtrl;
