import React, { useContext, useState, useEffect, useRef } from 'react';
import { DataBrokerContext } from '../PageBuilderCtrl';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import '../css/pagebuilderctrl.css';
import SimpleCodeEditor from '../../../pages/SimpleCodeEditor';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';
import CodeIcon from '@mui/icons-material/Code';

function InputCodeEditorCtrl({ propertyValue, propertyChangeCallback, defaultLanguage, propertyLabel}) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [propertyValueObj, setPropertyValueObj] = useState(propertyValue);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            


    function handleOnValueChange(value) {
        //var tmpPropertyValueObj = [...propertyValueObj];
        //tmpPropertyValueObj[propertyName] = value;
        setPropertyValueObj(value);
        propertyChangeCallback(value);
    }

    function handleOnBlur(value) {
        setPropertyValueObj(value);
        propertyChangeCallback(value);
    }

    function openCodeEditor(defaultLanguage) {
        var msg = '';
        let title = '';
        let code = propertyValueObj || '';
        switch (defaultLanguage) {
            case "javascript":
                title = 'Javascript Editor'
                break;
            case "css":
                title = 'CSS Editor'
                break;
        }
        setGenericDialogSchema({
            title: title, component: (dialogType, setReturnValue) => <SimpleCodeEditor defaultCode={code || ''} defaultLanguage={defaultLanguage} handlerReturnValue={dialogType, setReturnValue}></SimpleCodeEditor>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", fullWidth: true, maxWidth: "lg", hideBackdrop: true, paperProps: { sx: { width: 1200 } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleJavascriptChanged(returnValues); }, ignoreESCKey: true, ignoreBGClick: true
        })
        setOpenDialog(true);
    }

    function handleJavascriptChanged(returnValues) {
        handleOnValueChange(returnValues);
    }

    useEffect(() => {
        setPropertyValueObj(propertyValue);
    }, [propertyValue])

    function getColor() {
        let color = "#c0c0c0";
        if (propertyValueObj != null && propertyValueObj != '') {
            color = "#5E95DE"
        }
        return color;
    }
    return (
        <>

            <div>
                {propertyValueObj != undefined ?
                    <div style={{display:"grid",gridTemplateColumns:"1fr 40px"}}>
                        <div><button onClick={(e) => { openCodeEditor(defaultLanguage); }} style={{ width: "100%" }}>Edit...</button></div>
                        <div style={{textAlign:"center"}}><CodeIcon style={{color:getColor()}} /></div>
                    </div>
                    : null}

            </div>
            {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
        </>
    );

}

export default InputCodeEditorCtrl;

