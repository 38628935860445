import React, { useState, useEffect } from "react";

function BookingFormCtrl({ questions, answers, onAnswersChanged, onFormSubmit }) {

    const [answersObj, setAnswersObj] = useState({});
    const answerLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const [errorList, setErrorList] = useState({});

    function handleOnAnswerChange(questionId, value) {
        let tmpAnswers = { ...answersObj };
        tmpAnswers[questionId].answer = value;
        setAnswersObj(tmpAnswers);
    }

    function handleOnAnswerBlur(questionId, value) {
        let tmpAnswersObj = { ...answersObj };
        tmpAnswersObj[questionId].answer = value;
        setAnswersObj(tmpAnswersObj);
        onAnswersChanged(tmpAnswersObj);
    }

    function handleOnFormSubmit() {
        let tmpErrorList = [];
        let question = null;
        let errMsg = null;
        for (let qi = 0; qi < questions.length; qi++) {
            question = questions[qi];
            if (answersObj.hasOwnProperty(question.questionId)) {
                if (question.required == true) {
                    if (answersObj[question.questionId].answer == null || answersObj[question.questionId].answer == '') {
                        errMsg = "Is required";
                        tmpErrorList[question.questionId] = { id: question.questionId, errMsg: errMsg };
                    }
                }
            }
        }
        setErrorList(tmpErrorList);
        if (Object.keys(tmpErrorList).length == 0) {
            onFormSubmit();
        }            
        
    }

    const renderFormControl = (question) => {
        const questionType = question.questionType;
        if (questionType === "MultipleChoice") {
            return (
                <select type="text" style={{ marginTop: "5px", width: "100%", padding: "10px", border: "1px solid #e0e0e0", borderRadius: "4px", boxSizing: "border-box" }}>
                    <option value=""></option>
                    {question.answerOptions.map((answerOption, answerOptionIndex) => {
                        return (<option value={answerLabels[answerOptionIndex]}>{answerOption.text}</option>)
                    }) }                    
                </select >
            )
        } else if (questionType === "MultiSelect") {

        } else if (questionType === "True/False") {
            return (
                <select type="text" style={{ marginTop: "5px", width: "100%", padding: "10px", border: "1px solid #e0e0e0", borderRadius: "4px", boxSizing: "border-box" }}>
                    <option value=""></option>
                    <option value={true}>True</option>
                    <option value={false} >False</option>
                </select >
            )
        } else if (questionType === "Yes/No") {
            return (
                <select type="text" style={{ marginTop: "5px", width: "100%", padding: "10px", border: "1px solid #e0e0e0", borderRadius: "4px", boxSizing: "border-box" }}>
                    <option value=""></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select >
            )
        } else if (questionType === "ExactPhrase") {

        } else if (questionType === "FreeTextOneLine") {
            return <input type="text" style={{ marginTop: "5px", width: "100%", padding: "10px", border: "1px solid #e0e0e0", borderRadius: "4px", boxSizing: "border-box" }} value={answersObj[question.questionId].answer || ""} defaultValue={answersObj[question.questionId].answer || ""} onChange={(e) => { handleOnAnswerChange(question.questionId, e.target.value); }} onBlur={(e) => { handleOnAnswerBlur(question.questionId, e.target.value); }} />
        } else if (questionType === "FreeTextMultiLine") {
            return <textarea rows={4} style={{ marginTop: "5px", width: "100%", padding: "10px", border: "1px solid #e0e0e0", borderRadius: "4px", boxSizing: "border-box" }} onChange={(e) => { handleOnAnswerChange(question.questionId, e.target.value); }} onBlur={(e) => { handleOnAnswerBlur(question.questionId, e.target.value); }}>{answersObj[question.questionId].answer || ""}</textarea>
        }
    }

    useEffect(() => {
        setAnswersObj(answers);
    }, [answers])

    return (
        <>
            <div style={{ marginBottom: "20px", fontWeight: "bold" }}>Booking Form</div>
            {(Object.keys(answersObj).length != 0) ?
                
                <div>
                    {Object.keys(errorList).length > 0 ?
                        <div style={{ backgroundColor: "red", color: "white", fontSize: "16px", fontWeight: "bold", marginBottom: "20px", padding: "5px" }}>There are errors on this form</div>
                        : null}
                    {questions.map((question, index) => {
                        return (
                            <div style={{ marginBottom: "15px" }}>
                                <label>{question.question}</label>
                                <div>{renderFormControl(question)}</div>
                                {errorList.hasOwnProperty(question.questionId) == true ?
                                    <div style={{color: "red"}} >{errorList[question.questionId].errMsg}</div>
                                    : null}
                            </div>
                        )
                    })}
                    <div style={{ marginTop: "20px" }}>
                        <button style={{ backgroundColor: "#AFE1AF", color: "black", border: "none", padding: "15px 15px 15px 15px", borderRadius: "4px", width: "100%", fontSize: "18px", fontWeight: "bold" }} onClick={(e) => { handleOnFormSubmit();}}>Submit Form</button>
                    </div>
                </div>
                : null}
            
        </>
    )
}

export default BookingFormCtrl;