import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import helpers from '../../helpers/Helpers';
import Chip from '@mui/material/Chip';
import DialogCtrl from '../dialogctrl/dialogctrl';
import ContactSearch from '../../pages/ContactSearch';
import { BAFetchData } from '../../customhooks/useBAFetch';
import TextField from '@mui/material/TextField';
import ImageFieldCtrl from './ImageFieldCtrl';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

function ProductVariantsCtrl({ productId, productVariantList, attrList, subscriptionDiscount, onChange }) {

    const [variantList, setVariantList] = useState(productVariantList);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

    function onVariantAttrChange(e, variantIndex, attrIndex, propertyName, value) {
        var tmpVariantList = [...variantList];
        tmpVariantList[variantIndex].productVariantAttributeList[attrIndex][propertyName] = value;
        if (tmpVariantList[variantIndex].productVariantAttributeList[attrIndex].internalState != "added") {
            tmpVariantList[variantIndex].productVariantAttributeList[attrIndex].internalState = "modified";
        }
        onChange(tmpVariantList);
        setVariantList(tmpVariantList);
    }

    function onVariantChange(e, index, propertyName, value) {
        var tmpVariantList = [...variantList];
        if (propertyName == 'unitPrice') {
            tmpVariantList[index][propertyName] = value;
            tmpVariantList[index]['unitBillInstallment'] = (value - (value * (subscriptionDiscount / 100))).toFixed(2);
        } else {
            tmpVariantList[index][propertyName] = value;
        }

        if (tmpVariantList[index].internalState != "added") {
            tmpVariantList[index].internalState = "modified";
        }
        onChange(tmpVariantList);
        setVariantList(tmpVariantList);
    }

    function removeVariantDetailItem(e, index) {
        var tmpVariantList = [...variantList];
        if (tmpVariantList[index].internalState != "added") {
            tmpVariantList[index].internalState = "deleted";
        } else {
            tmpVariantList.splice(index, 1);
        }
        onChange(tmpVariantList);
        setVariantList(tmpVariantList);
    }


    useEffect(() => {     
       setVariantList(productVariantList);
    }, [productVariantList]);

    return (
        <>
            {variantList.map((variant, index) => (
                variant.internalState != 'deleted' ?
                <div key={"product_variant_detail_" + index} style={{ border: "1px solid rgba(0, 0, 0, 0.2)", padding: "15px", borderRadius: "6px", marginBottom: "20px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 60px", gridGap: "10px" }}>
                        <div>
                            <div style={{display:"inline-flex"}}>
                                {variant.productVariantAttributeList.map((variantAttr, vai) => (
                                    <>
                                        {variantAttr.attrName == 'Color-Swatch' ?
                                            <div style={{ display: "inline-block", width: "auto", marginRight: "10px" }}><ImageFieldCtrl imgSrc={variantAttr.attrValue} label={variantAttr.attrName} imgType="sm-img-only" returnFullPath={true}></ImageFieldCtrl></div>
                                            : null}                                   
                                        {(variantAttr.attrName != 'Color-Thumbnail' && variantAttr.attrName != 'Color-Swatch') ?
                                            <div style={{ display: "inline-block", width: "auto", marginRight: "10px" }} key={"product_variant_attr_detail_" + vai}><TextField fullWidth label={variantAttr.attrName} InputProps={{ readOnly: false, }} variant="standard" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: variantAttr.attrValue || '' }} value={variantAttr.attrValue || ''} onChange={(e) => { onVariantAttrChange(e, index, vai, 'attrValue', e.target.value); }} /></div>
                                         : null}
                                    </>
                                ))}                               
                            </div>
                                <div style={{ display: "grid", gridTemplateColumns: "150px 220px 100px 100px 70px 120px 60px 40px", gridGap: "10px", marginTop: "10px" }}>
                                <div><TextField fullWidth label="Sku #" variant="standard" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: variant.skuNum || '' }} value={variant.skuNum || ''} onChange={(e) => { onVariantChange(e,index,'skuNum',e.target.value);}} /></div>
                                    <div style={{ textAlign: "center" }}><ImageFieldCtrl imgSrc={variant.photo} label="Thumbnail" recType="product" recId={productId} imgType="inline" returnFullPath={true} onChange={(imgValue) => { onVariantChange(null, index, 'photo', imgValue); }}></ImageFieldCtrl></div>
                                    <div><TextField fullWidth label="Price" variant="standard" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: variant.unitPrice !== null && variant.unitPrice !== undefined && variant.unitPrice !== '' }} value={variant.unitPrice >= 0 ? variant.unitPrice : ''} onChange={(e) => { onVariantChange(e, index, 'unitPrice', e.target.value); }} /></div>
                                    <div><TextField fullWidth label="Install. Amt." variant="standard" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: variant.unitBillInstallment !== null && variant.unitBillInstallment !== undefined && variant.unitBillInstallment !== '' }} value={variant.unitBillInstallment >= 0 ? variant.unitBillInstallment : ''} onChange={(e) => { onVariantChange(e, index, 'unitBillInstallment', e.target.value); }} /></div>
                                    <div><TextField fullWidth label="Qty" variant="standard" style={{ marginBottom: "16px" }} InputLabelProps={{ shrink: variant.qtyOnHand !== null && variant.qtyOnHand !== undefined && variant.qtyOnHand !== '' }} value={variant.qtyOnHand >= 0 ? variant.qtyOnHand : ''} onChange={(e) => { onVariantChange(e, index, 'qtyOnHand', e.target.value); }} /></div>
                                <div>
                                    <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 120, marginBottom: "16px" }}>
                                        <InputLabel id="demo-simple-select-standard-label">Qty Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            label="Qty Status"
                                                value={variant.qtyStatus || ''}
                                                onChange={(e) => { onVariantChange(e, index, 'qtyStatus', e.target.value); }}
                                        >
                                            <MenuItem value="In Stock">In Stock</MenuItem>
                                            <MenuItem value="Out of Stock">Out of Stock</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <div style={{ fontSize: "13px", margin: "0px", padding: "0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Backorder</div>
                                        <Checkbox style={{ padding: "2px" }} checked={variant.allowBackorder} sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }} onChange={(e) => { onVariantChange(e, index, 'allowBackorder', e.target.checked); }} />
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <div style={{ fontSize: "13px", margin: "0px", padding: "0px", lineHeight: "90%", color: "rgba(0, 0, 0, 0.6)" }}>Active</div>
                                        <Checkbox style={{ padding: "2px" }} checked={variant.active} sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }} onChange={(e) => { onVariantChange(e, index, 'active', e.target.checked); }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                                <DeleteIcon onClick={(e) => { removeVariantDetailItem(e, index);}} />
                        </div>
                    </div>

                    </div>
                : null
            ))}
        </>
    );
}

export default ProductVariantsCtrl;
