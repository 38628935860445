export const mergeStateVars = (textValue, states, deploy) => {

    var regexp = regexp = /(%{)([a-zA-Z._(0-9)-]*)(}%)/gm;
    var matches = textValue.matchAll(regexp);

    for (const match of matches) {
        if (deploy != true) {
            const key = match[0].replaceAll('%{', '').replaceAll('}%', '');
            if (states[key] != undefined) {
                if (states[key].designValue != null && states[key].designValue != '') {
                    textValue = textValue.replaceAll(match[0], states[key].designValue);
                }                
            }            
        } else {
            const mergeValue = match[0].replaceAll('%{', '{').replaceAll('}%', '}');
            textValue = textValue.replaceAll(match[0], mergeValue);
        }
    }

    return textValue;

};

export const getStateList = (elements) => {

    let states = {};

    for (let key in elements) {
        let elItem = elements[key];
        if (elItem.hasOwnProperty('customInputs')) {
            let customInputs = elItem.customInputs;
            if (customInputs.hasOwnProperty('reactUseStates')) {
                let property = customInputs.reactUseStates.property;
                for (let propertyKey in property) {
                    if (propertyKey === 'states') {
                        let propertyKeyItem = property[propertyKey];
                        if (propertyKeyItem != null) {
                            for (let i = 0; i < propertyKeyItem.length; i++) {
                                states[propertyKeyItem[i].name] = { value: propertyKeyItem[i].value, designValue: propertyKeyItem[i].designValue, valueType: propertyKeyItem[i].valueType, isReferenceObj: propertyKeyItem[i].isReferenceObj, runAfter: propertyKeyItem[i].runAfter};
                                //states[propertyKeyItem[i].name] = propertyKeyItem[i].value;
                                //states.push({name:propertyKeyItem[i].name, value:propertyKeyItem[i].value});
                            }
                        }
                    }
                }
            }
        }        
    }
    return states;
};

export const getCustomCode = (elements, codePropertyName) => {

    let customCodes = [];

    for (let key in elements) {
        let elItem = elements[key];
        if (elItem.hasOwnProperty('customInputs')) {
            let customInputs = elItem.customInputs;
            if (customInputs.hasOwnProperty('customCode')) {
                let property = customInputs.customCode.property;
                if (property.hasOwnProperty(codePropertyName)) {
                    customCodes.push({code:property[codePropertyName]});
                }
            }
        }
    }
    return customCodes;
};