import React, { useEffect, useState } from "react";
import CalendarCtrl from "./CalendarCtrl";
import AvailCalSlots from "./AvailCalSlots";
import BookingItemsCtrl from "./BookingItemsCtrl";
import BookingFormCtrl from "./BookingFormCtrl";
import dayjs from "dayjs";
import BookingLocationsCtrl from "./BookingLocationsCtrl";
import BookingResourcesCtrl from "./BookingResourcesCtrl";
import BookingPaymentCtrl from "./BookingPaymentCtrl";
import helpers from "../../../helpers/Helpers";
import BookingConfirmCtrl from "./BookingConfirmCtrl";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PublicIcon from '@mui/icons-material/Public';
import EventIcon from '@mui/icons-material/Event';
import { BAFetchData } from "../../../customhooks/useBAFetch";
import DialogCtrl from "../../dialogctrl/dialogctrl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function BookingCtrl({ viewType = "client-booking", businessLogoUrl, resetId, highlightCurrentDate = true, showPastSelecteddDates = false, selectedDates, resources, daysInFuture = 7300, initialDate = new Date(), onSelectedDateChange, bookableItemList, onBookableItemChanged, onLocationChanged, onResourceChanged, selectedDate, availSlots, onFormSubmit, onComplete, defaultLocationId, defaultResourceId} ) {
    //const [selectedDate, setSelectedDate] = useState(null);
    const [bookableItemId, setBookableItemId] = useState(null);
    const [viewIndex, setViewIndex] = useState(0);
    const [timezoneName, setTimezoneName] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [confirmedDateTime, setConfirmedDateTime] = useState(null);
    const [selectedBookableItem, setSelectedBookableItem] = useState(null);
    const [selectedLocationId, setSelectedLocationId] = useState(defaultLocationId);
    const [selectedResourceId, setSelectedResourceId] = useState(defaultResourceId);
    const [answers, setAnswers] = useState({});
    const [payInfo, setPayInfo] = useState(buildPayInfoSchema());
    const [paymentAmt, setPaymentAmt] = useState(0);
    const [complete, setComplete] = useState(false);
    const [backIndex, setBackIndex] = useState(0);
    const [timezoneNameList, setTimezoneNameList] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            

        //const views =  ["event","calendar","form","confirmation"];
        //function onDateSelected(date){
        //    onSelectedDateChange
        //    setSelectedDate(date);
        //}

    function determineBackIndex() {
        let index = 0;
        if (defaultResourceId != null) {
            index = 3;
        } else if (defaultLocationId != null) {
            let tmpLocation = getLocation(defaultLocationId);
            if (tmpLocation.resources.length == 1) {
                index = 3;
            } else {
                index = 2;
            }            
        }
        setBackIndex(index);
    }

    function buildPayInfoSchema() {
        return { paid:false, transactionId: null,paymentAmt:0,paymentMethod: "Card", cardType: null, cardholderName: null, creditCardNumber: null, creditCardCvv: null, creditCardExpireMonth: null, creditCardExpireYear: null, paymentRef: null, billingAddress1: null, billingAddress2: null, billingCity: null, billingState: null, BillingZip: null, billingCountryCode: null, posted: false };
    }

    function onPaymentComplete() {
        setViewIndex(6);
        setComplete(true);
        onComplete(timezoneName, bookableItemId, selectedLocationId, selectedResourceId, confirmedDateTime, answers, payInfo);
    }

    function onConfirmed(confirmedDate, cost){
        setConfirmedDateTime(confirmedDate);
        setPaymentAmt(cost);
        setViewIndex(4);
    }

    function handleOnFormSubmit() {
        let cost = paymentAmt || selectedBookableItem.defaultCost || 'FREE';
        if (cost != 'FREE') {
            setViewIndex(5);
        } else {
            setComplete(true);
            onComplete(timezoneName, bookableItemId, selectedLocationId, selectedResourceId, confirmedDateTime, answers, payInfo);
            setViewIndex(6);
        }        
    }

    function handleOnResourceChanged(resourceId, resourceType) {
        onSelectedDateChange(null, null, null);
        setSelectedResourceId(resourceId);
        setConfirmedDateTime(null);
        setViewIndex(3);
        onResourceChanged(selectedBookableItem.bookableItemId, selectedLocationId, resourceId);
    }

    function handleOnLocationChanged(locationId) {
        let tmpLocation = getLocation(locationId);
        setSelectedLocationId(locationId);
        //onSelectedDateChange(null, selectedBookableItem.bookableItemId, locationId);
        setConfirmedDateTime(null);
        setViewIndex(2);
        onLocationChanged(selectedBookableItem.bookableItemId, locationId);
    }

    function changeBookableItem(bookableItemId) {
        setBookableItemId(bookableItemId);
        let tmpSelectedBookableItem = getBookableItem(bookableItemId);
        setSelectedBookableItem(tmpSelectedBookableItem);
        //onSelectedDateChange(null, bookableItemId);        
        //setConfirmedDateTime(null);
        setViewIndex(1);
        //onBookableItemChanged(bookableItemId);
    }


    function changeView(direction){
        if (direction === 'prev' && viewIndex > 0){
            let tmpViewIndex = viewIndex;
            tmpViewIndex--;
            setViewIndex(tmpViewIndex);
        }
    }

    function getBookableItem(bookableItemId){
        let tmpBookableItem = null;
        for (let i = 0; i < bookableItemList.length; i++) {
            if (bookableItemList[i].bookableItemId === bookableItemId){
                tmpBookableItem = {...bookableItemList[i]};
                break;
            }
        }
        return tmpBookableItem;
    }



    function getLocation(locationId) {
        
        let tmpLocationsConfig = selectedBookableItem.locationConfigurations;
        let tmpLocation;
        for (let i = 0; i < tmpLocationsConfig.length; i++) {

            if (tmpLocationsConfig[i].locationId === locationId) {
                tmpLocation = { ...tmpLocationsConfig[i] };
                break;
            }
        }
        return tmpLocation;
    }

    function getResource(resourceId) {
        let tmpResource = null;
        for (let ri = 0; ri < resources.length; ri++) {
            if (resources[ri].id == resourceId) {
                tmpResource = { ...resources[ri] };
                break;
            }
        }        
        return tmpResource;
    }

    function resetAnswerObject() {
        const questions = selectedBookableItem.questions;
        let tmpAnswers = {};
        for (const key in questions) {
            tmpAnswers[questions[key].questionId] = { questionId: questions[key].questionId, fieldName: questions[key].fieldName, answer: null }
        }
        setAnswers(tmpAnswers);
    }

    function getTimezoneNameList() {
        BAFetchData({
            callName: "GetTimezoneNameList",
            method: "GET",
            url: "time/timezone/gettimezonenames?filtering={}",
            token: "fg1234",
            toggleRefetch: null,
            onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case 'GetTimezoneNameList':
                setTimezoneNameList(data);
                break;
        }
    }

    function onAPIError(error, callName) {
        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }


    useEffect(() => {
        getTimezoneNameList();
    },[])

    useEffect(() => {
        if (selectedBookableItem != null) {
            resetAnswerObject();
            determineBackIndex();

        }
    },[selectedBookableItem])

    useEffect(() => {
        setViewIndex(0);
    },[resetId])

    useEffect(() => {   
        if (bookableItemList.length > 0) {
            if (bookableItemList.length == 1) {
                changeBookableItem(bookableItemList[0].bookableItemId);
            }
        }
    }, [bookableItemList])

    function getCost() {
        let cost = paymentAmt || selectedBookableItem.defaultCost || 'FREE';
        if (cost != 'FREE') {
            cost = helpers.formatCurrencyGlobal(cost);
        }
        return cost;
    }

    function buildSummary() {
        let tmpLocation = getLocation(selectedLocationId);
        let tmpResource = getResource(selectedResourceId);
        return (
            <>
                <div style={{ textAlign: "center", marginBottom: "10px", borderBottom:"1px solid #d2d2d2",paddingBottom:"20px"}}>
                    <img src={businessLogoUrl} alt="" style={{ maxWidth:"220px",maxHeight: "60px"}} />
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: "10px", marginBottom:"10px" }}>
                    <div style={{fontWeight:"bold",fontSize:"22px"} }>
                        {selectedBookableItem.title}
                    </div>                    
                    <div style={{fontSize:"16px"}}>
                        {selectedBookableItem.description}
                    </div>
                </div>
                {tmpResource != null ?
                    <>
                        <div style={{ marginTop:"16px",marginBottom: "5px",fontStyle:"italic"}}>You will be meeting with:</div>
                <div style={{ display: "grid", gridTemplateColumns: "65px 1fr", gridGap: "10px", borderBottom: "1px solid #d2d2d2", paddingBottom: "10px", marginBottom: "10px" }}>
                    <div>
                                <img src={tmpResource.profileImg} alt="" style={{ width: "65px", height: "65px", borderRadius:"50%", border:"1px solid #d2d2d2"}} />
                    </div>
                    <div style={{ fontWeight: "bold", fontSize: "18px", paddingTop:"15px" }}>
                        {tmpResource.name}
                    </div>
                        </div>
                </>
                    : null}
                <div style={{ display: "grid", gridTemplateColumns: "24px 1fr", gridGap: "10px" }}>
                    <div><AccessTimeIcon/></div>
                    <div style={{ paddingTop: "2px" }}>{selectedBookableItem.duration} minutes</div>
                    <div><AttachMoneyIcon /></div>
                    <div style={{ paddingTop: "2px" }}>{getCost()}</div>
                    {tmpLocation != null ?
                        <>
                        <div><PublicIcon /></div>
                        <div style={{ paddingTop: "2px" }}>
                            <div style={{ fontWeight: "bold" }}>{tmpLocation.placeName}</div>
                                {complete == true ?
                                    <div>{tmpLocation.address}</div>
                                    : null}
                        </div>
                        </>
                        : null}            
                    {confirmedDateTime != null ?
                        <>
                            <div><EventIcon /></div>
                            <div style={{ paddingTop: "2px" }}>
                                <div>
                                    {dayjs(confirmedDateTime).format('h:mma -- dddd, MMMM D, YYYY')}
                                </div>
                                <div>
                                    <span>Timezone:</span> {timezoneName || ''} 
                                </div>
                            </div>
                        </>
                        : null}                                
                </div>
            </>
        )
    }

    return (
        <>
            <div style={{ width: "480px", padding: "0px" }}>
            {complete == true ?
                <div style={{ textAlign: "center", marginBottom: "40px" }}>
                    <div style={{ fontWeight: "bold", fontSize: "22px" }}>Reservation Completed</div>
                    <div>Email confirmation has been sent to your inbox.</div>
                </div>
                : null}
            {selectedBookableItem != null ?
                <div style={{ marginBottom: "20px", backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "12px" }}>
                    {buildSummary()}
                </div>
                : null}

            {complete == false ?
                <>
            {viewIndex === 0 ?
                <div>
                    <BookingItemsCtrl bookableItemList={bookableItemList} selectedBookableItemId={bookableItemId} onChangeBookableItem={(bookableItemId) => { changeBookableItem(bookableItemId); }} />
            </div>
                : null}
            {viewIndex === 1 ?
                <div>
                    <BookingLocationsCtrl locationConfigurations={selectedBookableItem.locationConfigurations || []} selectedLocationId={selectedLocationId} onLocationChanged={(locationId) => { handleOnLocationChanged(locationId); }} />                    
                </div>
                : null}
            {viewIndex === 2 ?
                <div>
                    <BookingResourcesCtrl resources={resources || []} selectedResourceId={selectedResourceId} onResourceChanged={(resourceId,resourceType) => { handleOnResourceChanged(resourceId, resourceType); }} />
                </div>
                : null}
            {viewIndex === 3 ?
            <>
                <div style={{marginBottom:"20px",fontWeight:"bold"}}>Select a Date & Time</div>
                <div style={{display:"grid",gridTemplateColumns:"250px 1fr", gridGap:"10px"}}>
                    <div>
                    <CalendarCtrl highlightCurrentDate={true} daysInFuture={daysInFuture} selectedDates={selectedDates} handleOnDateSelected={(selectedDate) => { onSelectedDateChange(selectedDate, bookableItemId, selectedLocationId, selectedResourceId);}}/>
                    <div style={{ marginTop: "20px" }}>
                                            <Autocomplete
                                                size="small"
                                                options={timezoneNameList}
                                                onChange={(event, newValue) => { setTimezoneName(newValue); }}
                                                getOptionLabel={(option) => `${option}`}
                                                renderOption={(props, option) => (
                                                    <li {...props}>
                                                        {option}
                                                    </li>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Select Timezone" />}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                value={timezoneName}
                        />
                       </div>
                    </div>
                    <div>
                        <AvailCalSlots selectedDate={selectedDate || null} availSlots={availSlots || []} handleOnConfirm={(confirmedDate, cost) => {onConfirmed(confirmedDate, cost);}}/>
                    </div>
                </div>
            </>
            : null}
            {viewIndex === 4 ?
            <>
                    <BookingFormCtrl questions={selectedBookableItem.questions || []} answers={answers} onAnswersChanged={(value) => { setAnswers(value); }} onFormSubmit={() => { handleOnFormSubmit();}} />
            </>
                : null}
            {viewIndex === 5 ?
                <>
                    <BookingPaymentCtrl paymentAmt={paymentAmt} payInfo={payInfo} showPaymentMethod={false} onPayInfoChange={(payInfoValue) => { setPayInfo(payInfoValue) ;}} onPaymentComplete={(payInfoValue) => { onPaymentComplete(payInfoValue); } } />
                </>
                : null}
            {viewIndex === 6 ?
                <>
                    <BookingConfirmCtrl />
                </>
                        : null}
                </>
            : null}
            {(viewIndex > backIndex && complete == false) ?
            <div style={{marginTop:"20px"}}>
                <button style={{backgroundColor:"#666666", color:"white", border:"none",padding:"5px 15px 5px 15px",borderRadius:"4px"}} onClick={(e) => {changeView("prev");}}>Back</button>
            </div>
            : null}
        </div>

            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    )
}

export default BookingCtrl;