import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';


function CssFormFieldCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);


    function handleOnPropertyChange(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        //propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnBlur(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Field Settings</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Form Code</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.formCode} onChange={(e) => { handleOnPropertyChange('formCode', e.target.value); }} onBlur={(e) => { handleOnBlur("formCode", e.target.value); }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Control Type</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.fieldType} onChange={(e) => { handleOnPropertyChange('fieldType', e.target.value); }} onBlur={(e) => { handleOnBlur("fieldType", e.target.value); }}>
                                <option value="checkbox">Checkbox</option>
                                <option value="date">Date</option>
                                <option value="date-time">Date/Time</option>
                                <option value="email">Email</option>
                                <option value="html">HTML</option>
                                <option value="image">Image</option>
                                <option value="lookup">Lookup</option>
                                <option value="multilinetextbox">Multi-line Textbox</option>                               
                                <option value="password">Password</option>
                                <option value="phone">Phone</option>
                                <option value="select">Select</option>
                                <option value="switch">Switch</option>
                                <option value="textbox">Textbox</option>                                
                                <option value="tag">Tag</option>
                                <option value="time">Time</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Data Type</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.fieldDataType} onChange={(e) => { handleOnPropertyChange('fieldDataType', e.target.value); }} onBlur={(e) => { handleOnBlur("fieldDataType", e.target.value); }}>
                                <option value=""></option>
                                <option value="date">Date</option>
                                <option value="date-time">Date/Time</option>
                                <option value="decimal">Decimal</option>
                                <option value="email">Email</option>
                                <option value="number">Number</option>
                                <option value="phone">Phone</option>
                                <option value="string">String</option>                                                               
                                <option value="time">Time</option>
                                <option value="user-name">User Name</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Field Name</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.fieldName} onChange={(e) => { handleOnPropertyChange('fieldName', e.target.value); }} onBlur={(e) => { handleOnBlur("fieldName", e.target.value); }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Placeholder</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.fieldPlaceholder} onChange={(e) => { handleOnPropertyChange('fieldPlaceholder', e.target.value); }} onBlur={(e) => { handleOnBlur("fieldPlaceholder", e.target.value); }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Required</div>
                        <div>
                            <select className="form-control form-control-sm fg-pb-property-field" value={widgetObj.fieldRequired} onChange={(e) => { handleOnPropertyChange('fieldRequired', e.target.value); }} onBlur={(e) => { handleOnBlur("fieldRequired", e.target.value); }}>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Max Length</div>
                        <div>
                            <input type="Number" className="form-control form-control-sm fg-pb-property-field" value={widgetObj.fieldMaxCharacters} onChange={(e) => { handleOnPropertyChange('fieldMaxCharacters', e.target.value); }} onBlur={(e) => { handleOnBlur("fieldMaxCharacters", e.target.value); }} />
                        </div>
                    </div>
                    {widgetObj.fieldType == 'select' ?
                        <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                            <div className="fg-pb-property-sub-label">Options</div>
                            <div>
                                <textarea className="form-control form-control-sm fg-pb-property-field" value={widgetObj.fieldOptions || ''} defaultValue={widgetObj.fieldOptions || ''} onChange={(e) => { handleOnPropertyChange("fieldOptions", e.target.value); }} onBlur={(e) => { handleOnBlur("fieldOptions", e.target.value); }} rows={8}></textarea>
                            </div>
                        </div>
                        : null}
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Value</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.fieldValue} onChange={(e) => { handleOnPropertyChange('fieldValue', e.target.value); }} onBlur={(e) => { handleOnBlur("fieldValue", e.target.value); }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Default Val.</div>
                        <div>
                            <input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.fieldDefaultValue} onChange={(e) => { handleOnPropertyChange('fieldDefaultValue', e.target.value); }} onBlur={(e) => { handleOnBlur("fieldDefaultValue", e.target.value); }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CssFormFieldCtrl;

