import React, { useContext, useState, useEffect } from 'react';
import './css/pagebuilderctrl.css';
import DescriptionIcon from '@mui/icons-material/Description';
import DatasetIcon from '@mui/icons-material/Dataset';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';
import { DataBrokerContext } from "./PageBuilderCtrl";
import { BAFetchData } from '../../customhooks/useBAFetch';
import DataSourceSettingCtrl from './DataSourceSettingCtrl';
import DialogProCtrl from '../dialogctrl/dialogproctrl';
import Button from '@mui/material/Button';
import helpers from '../../helpers/Helpers';


function DataSourceListCtrl({ pageInfo, setPageInfo, setCopySnackbarOpen, onRenameDataSource }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseOver, onWidgetMouseLeave, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop, handleUpdateChanges, handleMergeVariables } = useContext(DataBrokerContext);
    const [dataSources, setDataSources] = useState(pageInfo.dataSources);
    const [dataSourcePanel, setDataSourcePanel] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});        
    const [currRenderNum, setCurrRenderNum] = useState(null);


    //function beginSearch() {
    //    var filtering = {};

    //    filtering = {
    //        "LogicalOperator": "and", "ConditionGroups": [{
    //            "LogicalOperator": "or", "Conditions": [
    //                { "Name": "siteid", "ConditionOperator": "EQUAL", "Value": siteId, "DataType": "String" },
    //            ]
    //        }]
    //    };
    //    BAFetchData({
    //        callName: "BeginSearch", method: "GET", url: "cms/sitepages/getlist?filtering=" + JSON.stringify(filtering),
    //        token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
    //    });
    //}

    function onDataSourceDelete(e, id) {
        e.preventDefault();
        e.stopPropagation(); 

        var tmpPageInfo = { ...pageInfo };
        var dataSources = tmpPageInfo.dataSources;
       
        if (dataSources != null) {
            for (var i = 0; i < dataSources.length; i++) {
                if (dataSources[i].id == id) {
                    dataSources.splice(i, 1);
                    tmpPageInfo.dataSources = dataSources;
                    setPageInfo((...pageInfo) => { return tmpPageInfo });     
                    break;
                }
            }
        }

    }

    function onPageDelete(e, sitePageId) {
        e.preventDefault();
        e.stopPropagation(); 
        //var tmpPageInfo = { ...pageInfo };
        //tmpPageInfo.sitePageId = sitePageId;
        //tmpPageInfo.name = pageName;
        //setPageInfo((...pageInfo) => { return tmpPageInfo });
        var test = "test";
        test = "water";


        BAFetchData({
            callName: "DeletePageRecord", method: "DELETE", url: "cms/sitepages/delete?sitepageid=" + sitePageId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });
    }

    function onAPISuccess(data, header, callName, packageObj) {
        switch (callName) {
            case "BeginSearch":
                //var headerXPagination = JSON.parse(header['x-pagination']);
                //setPaginationInfo((...paginationInfo) => { return { itemsPerPage: headerXPagination.itemsPerPage, totalPages: headerXPagination.totalPages, totalRecords: headerXPagination.totalCount, hasNext: headerXPagination.hasNext, hasPrevious: headerXPagination.hasPrevious } });
                //setPageListData((...pageListData) => { return { data: data, loading: false, error: null } });
                break;
            //case "DeletePageRecord":
            //    //resetResults(); //Reset search results and clear records selected.               
            //    var msg = "Page has been deleted.";
            //    setGenericDialogSchema({ title: 'Page Deleted', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog(); beginSearch(); handleOnPageDelete(); }});
            //    setOpenDialog(true);
            //    break;
            //case "GetPageInfo":
            //    handleOnPageClick(data.sitePageId, data);
            //    break;
        }
    }

    function onAPIError(error, callName) {

        var msg = error;
        setGenericDialogSchema({ title: 'API Call Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    }


    function onOnPageClick(e, sitePageId, pageName) {
        BAFetchData({
            callName: "GetPageInfo", method: "GET", url: "cms/sitepages/getrecord?sitepageid=" + sitePageId,
            token: "fg1234", body: null, onSuccess: onAPISuccess, onError: onAPIError
        });        
    }

    function openDataSource(e, id) {
        e.preventDefault();
        e.stopPropagation(); 

        var dataSources = pageInfo.dataSources;
        var dataSourceItem = null;

        if (dataSources != null) {
            for (var i = 0; i < dataSources.length; i++) {
                if (dataSources[i].id == id) {
                    dataSourceItem = dataSources[i];
                    break;
                }
            }
        }

        var msg = '';
        setGenericDialogSchema({
            title: 'Data Source Setting', component: (setReturnValue) => <DataSourceSettingCtrl dataSourceItem={dataSourceItem} handlerReturnValue={setReturnValue}></DataSourceSettingCtrl>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "lg", hideBackdrop: true, paperProps: { sx: { width: 800 } }, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleDataSourceChanged(returnValues); }, ignoreESCKey: true, ignoreBGClick: true });
        setOpenDialog(true);
    }

    function addNewDataSource(e) {
        openDataSource(e, null);
    }

    function handleDataSourceChanged(returnValues) {
        var found = false;
        var tmpPageInfo = { ...pageInfo };
        var dataSources = tmpPageInfo.dataSources;
        if (dataSources == null) {
            dataSources = [];
        }
        for (var i = 0; i < dataSources.length; i++) {
            if (dataSources[i].id == returnValues.id) {
                found = true;
                dataSources[i] = returnValues;
                break;
            }           
        }
        if (found == false) {
            dataSources.push(returnValues);
        }
        tmpPageInfo.dataSources = dataSources;
        setPageInfo((...pageInfo) => { return tmpPageInfo });     
        if (returnValues.oldSourceName != '') {
            if (returnValues.sourceName != returnValues.oldSourceName && returnValues.apiEndpoint == returnValues.apiEndpoint) {
                onRenameDataSource(returnValues.oldSourceName, returnValues.sourceName);
            }
        }
    }

    function onDataSourcePropertyClick(e, propertyName, sourceName) {
        e.preventDefault();
        e.stopPropagation(); 
        helpers.copyText('%%' + sourceName + '.' + propertyName + '~');
        setCopySnackbarOpen(true);
    }

    function handleDataSourceToggle(e, id) {
        e.preventDefault();
        e.stopPropagation(); 
        if (id != dataSourcePanel) {
            setDataSourcePanel(id);
        }else{
            setDataSourcePanel(null);
        }
        
    }

    //useEffect(() => {
    //    if (dataSources != null) {
    //        setDataSources(dataSources);
    //    }
    //}, [dataSources]);


    useEffect(() => {
        if (pageInfo.dataSources != null) {
            setDataSources(pageInfo.dataSources);
        }
    }, [pageInfo]);

    

    return (
        <>
            <div>
                <div style={{ marginTop: "10px", fontSize: "14px", lineHeight: "90%" }}>Click on the desired property to copy variable and then paste in desired widget property.</div>
                <Button variant="outlined" color="primary" size="small" fullWidth onClick={(e) => { addNewDataSource(e); }} style={{ marginTop: "10px", border: "1px solid rgba(0,0,0,.12)", padding: "4px 4px 0px 4px" }}>New Data Source</Button>
                <div className="fg-pb-widget-list">                             
                    {dataSources != null ?
                        dataSources.map((row, index) => (
                        <div>
                            <div key={"datasource_" + index} className="fg-pb-page-item" style={{ display: "grid", gridTemplateColumns: "30px 1fr 20px 20px 20px", gridGap: "10px", cursor: "pointer" }} >
                                <div><DatasetIcon></DatasetIcon></div>
                                <div>{row.sourceName}</div>                               
                                <div style={{ textAlign: "right" }} onClick={(e) => { openDataSource(e, row.id);}}><EditIcon></EditIcon></div>
                                <div onClick={(e) => { onDataSourceDelete(e, row.id); }} style={{ textAlign: "right" }}><DeleteForeverIcon></DeleteForeverIcon></div>
                                <div onClick={(e) => { handleDataSourceToggle(e, row.id); }} style={{ textAlign: "right" }} style={{ textAlign: "right" }}>{dataSourcePanel === row.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>                                
                            </div>
                                {row.id === dataSourcePanel ?
                                    <div>
                                        {Object.keys(row.properties).map((key, pi) => (
                                            <div key={"datasourceproperty_" + pi} style={{ padding: "2px 2px 2px 5px", backgroundColor: "white", margin: "4px 0px 4px 0px", cursor: "pointer" }} onClick={(e) => { onDataSourcePropertyClick(e, key, row.sourceName); }}>{key}</div>
                                        ))}
                                    </div>
                                : null}
                        </div>
                        )): null}

                </div>
            </div>
            {openDialog && <DialogProCtrl schema={genericDialogSchema}></DialogProCtrl>}
        </>
    );

}

export default DataSourceListCtrl;