import React, { useContext, useState, useEffect } from 'react';
import DialogCtrl from '../dialogctrl/dialogctrl';
import FileManager from '../../pages/FileManager';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';

function ButtonFieldCtrl({ value, onRemove, label, onClick, style, onChange, displayReadOnly = false }) {

    const [dataValue, setDataValue] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});

    function handleOnClick() {
        onClick();
    }

    function handleRemoveData() {
        onRemove();
    }

    function handleOnBlur(value) {
        setDataValue(value);
        onChange(value);
    }

    function handleOnChangeNoUpdate(value) {
        setDataValue(value);
        //onChange(value);
    }

    useEffect(() => {
        if (value != undefined) {
            setDataValue(value);
        } else {
            setDataValue('');
        }
        
    }, [value])

    return (
        <>
            {label != undefined ? <label style={{ fontSize: "13px", marginBottom: "2px" }}>{label}</label> : null}
            <div style={Object.assign({ display: "grid", gridTemplateColumns: "1fr 30px 30px", borderBottom: "1px solid #c0c0c0", padding: "10px 0px 3px 0px" }, style)}>
                <div><input className="form-control form-control fg-pb-property-field" readOnly={displayReadOnly} value={dataValue || ''} style={{ borderRadius: "0px", border: "none", height: "100%", paddingLeft: "0px" }} onChange={(e) => { handleOnChangeNoUpdate(e.target.value); }} onBlur={(e) => { handleOnBlur(e.target.value); }} title={dataValue || ''} /></div>
                <div style={{ textAlign: "center", color: "#757575", cursor: "pointer"}} onClick={(e) => { handleOnClick(e); }}><MoreHorizIcon /></div>
                <div style={{ textAlign: "right", color: "#757575", cursor: "pointer" }} onClick={(e) => { handleRemoveData(e); } }><CloseIcon /></div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );
}

export default ButtonFieldCtrl;