import React, { useEffect, useState} from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { BsBullseye, BsPersonCircle, BsList } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import './css/appheader.css';
import useWindowResize from '../../../customhooks/UseWindowResize';
import DropdownMenuCtrl from '../../dropdownmenuctrl/dropdownmenuctrl';
import useBAFetch from '../../../customhooks/useBAFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { setGlobalState, useGlobalState } from '../../../globalstates/GState';
import Avatar from '@mui/material/Avatar';
import SupportTicketSettings from '../../../pages/SupportTicketSettings';
import DialogPlusCtrl from '../../dialogctrl/dialogplusctrl';

function AppHeader() {
    const wlInfo = JSON.parse(localStorage.getItem("wl-info"));

    const [currentUser] = useGlobalState("userInfo");
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});   

    const navigate = useNavigate();
    var filtering = { "LogicalOperator": "and", "ConditionGroups": [{ "LogicalOperator": "and", "Conditions": [{ "Name": "object", "ConditionOperator": "EQUAL", "Value": "application_shell", "DataType": "String" }, { "Name": "category", "ConditionOperator": "EQUAL", "Value": "profile_menu", "DataType": "String" }, { "Name": "lang", "ConditionOperator": "EQUAL", "Value": document.documentElement.lang, "DataType": "String" }] }] };
    const { data, loading, error } = useBAFetch(
        { method: "GET", url: "crm/translation/getlist?filtering=" + JSON.stringify(filtering), token: "fg1234" }
    );
    const getTranslation = (object, category, key) => {
        var value = key;
        for (var i = 0; i < data.length; i++) {
            if (data[i].object == object && data[i].category == category && data[i].key == key) {
                value = data[i].translation;
                break;
            };
        }
        return value;
    }

    const windowSize = useWindowResize();

    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight
    })

    const openProfile = () => {
        navigate('/userprofile');
    }

    const createSupportTicket = () => {
        var msg = '';
        setGenericDialogSchema({
            title: '', component: (dialogType, setReturnValue, handleOKClick, setAcceptAfterUpdate) => <SupportTicketSettings handlerReturnValue={setReturnValue} cardId={0} setAcceptAfterUpdate={setAcceptAfterUpdate}></SupportTicketSettings>, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "1200px", showCancelBtn: true, showOKBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); onPopupOK('NewCard', returnValues); }
        })
        setOpenDialog(true);

    }

    function onPopupOK(returnCategory, returnValues) {

    }

    const openSecondWindow = () => {
        var url = window.location.href;
        window.open(url,'_blank');
    }

    const logout = () => {
        //setUser({});
        //setUsername("");
        //setPassword("");
        localStorage.clear();
        navigate("/login");
    }

    const menuItems = [];
    
    menuItems.push({ menuType: "UserProfile", name: "UserProfile", caption: currentUser.firstName + ' ' + currentUser.lastName, icon: currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0), link: null, callback: () => openProfile() });
    menuItems.push({ menuType: "MenuItem", name: "Profile", caption: getTranslation('application_shell', 'profile_menu', 'User Profile'), icon: null, link: null, callback: () => openProfile() });
    menuItems.push({ menuType: "MenuItem", name: "CreateSupportTicket", caption: getTranslation('application_shell', 'profile_menu', 'Create Support Ticket'), icon: null, link: null, callback: () => createSupportTicket() });
    menuItems.push({ menuType: "MenuItem", name: "OpenSecondWindow", caption: getTranslation('application_shell', 'profile_menu', 'Open in Second Window'), icon: null, link: null, callback: () => openSecondWindow()  });
    menuItems.push({ menuType: "Divider", name: "Divider", caption: null, icon: null, link: null, callback: null });
    menuItems.push({ menuType: "MenuItem", name: "Logout", caption: getTranslation('application_shell', 'profile_menu', 'Logout'), icon: "Logout", link: null, callback: () => logout() });

    const resizeAppLayout = () => {
        var appHeader = document.getElementById('app-header');
        var appSidebar = document.getElementById('app-sidebar');
        var appPageArea = document.getElementById('app-page-area');
        var headerHeight = appHeader.clientHeight;
        var windowHeight = window.innerHeight;
        appSidebar.style.height = (windowHeight - headerHeight) + 'px';
        appSidebar.style.marginTop = headerHeight + 'px';

        appPageArea.style.height = (windowHeight - headerHeight) + 'px';
        appPageArea.style.paddingTop = headerHeight + 'px';

        updatePageMenu('app-container');
    }

    function toggleMainMenu() {
        var element = document.getElementById('app-container');
        if (element.classList.contains("short-app-menu")) {
            element.classList.remove("short-app-menu");
            element.classList.add("full-app-menu");
        } else {
            element.classList.remove("full-app-menu");
            element.classList.add("short-app-menu");
        }
    }

    function handleToggleMenuClick(e) {
        toggleMainMenu();
    }

    const updatePageMenu = (elementId) => {
        var element = document.getElementById(elementId);
        if (window.innerWidth >= 1170) {
            element.classList.remove("short-app-menu");
            element.classList.add("full-app-menu");
        } else {
            element.classList.remove("full-app-menu");
            element.classList.add("short-app-menu");
        };
    }

    useEffect(() => {
        resizeAppLayout();
    }, [windowSize])


      return (
      <header>
              <div id="app-header" className="fgd-app-header">
                  <div className="fgd-app-header-left">
                      
                      <div className="fgd-brand-icon-only">
                          {wlInfo.wlIconLogoType == 'icon' ?
                              <span className={wlInfo.wlIconLogo} style={{ fontSize: "28px",paddingTop:"2px" }}/>
                              : 
                              <img src={wlInfo.wlIconLogo} style={{ width: "32px", height: "32px" }} />
                          }
                      </div>
                      <div className="fgd-brand-nameonly" style={{paddingTop:"1px"}}>{wlInfo.wlName}</div>
                  </div>
                  <div className="fgd-app-header-right">
                      <div className="fgd-app-mainnav-toggle-box" onClick={handleToggleMenuClick}><FaBars className="fgd-app-mainnav-toggle-btn" /></div>
                      <div style={{ paddingTop: "3px" }}>
                          <span style={{ color: "yellow" }}>Acct: {currentUser.currentBusinessAccountDTO != null ? currentUser.currentBusinessAccountDTO.businessName : "N/A"}</span>
                      </div>
                      <div className="fgd-app-links-area">
                          <div style={{ display:"inline-block" }} >
                              <DropdownMenuCtrl buttonType={currentUser.profileImg != null ? "Image" : "Avatar"} imageSrc={currentUser.profileImg} avatarText={currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)} toolTip={getTranslation('application_shell', 'profile_menu', 'Profile Menu')} menuItems={menuItems}></DropdownMenuCtrl>
                          </div>
                      </div>
                  </div>
              </div>
              {openDialog && <DialogPlusCtrl schema={genericDialogSchema}></DialogPlusCtrl>}
      </header>
    );
}



export default AppHeader;