import dayjs, { Dayjs } from 'dayjs';
const { v4: uuidv4 } = require('uuid');

const helpers = {

    capitalizeFirstChar: function (str) {
        if (!str) return str; // Handle empty string
        return str.charAt(0).toUpperCase() + str.slice(1);
    },

    passwordCheck: async function (pass) {
        var canContinue = true;
        var results = "";
        const strength = { 1: "very Weak", 2: "Weak", 3: "Meduim", 4: "Strong" }; 

        if (pass != "") {


            if (pass.length > 15) {
                results = "Password is too lengthy";
                canContinue = false;
            } else if (pass.length < 8) {
                results = "Password is too short";
                canContinue = false;
            }
            let regex =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!^%*?&]{8,15}$/;
            if (regex.test(pass)) {
                results = "Password is strong";
                canContinue = true;
            }
            let count = 0;
            let regex1 = /[a-z]/;
            if (regex1.test(pass)) count++;
            let regex2 = /[A-Z]/;
            if (regex2.test(pass)) count++;
            let regex3 = /[\d]/;
            if (regex3.test(pass)) count++;
            let regex4 = /[!@#$%^&*.?]/;
            if (regex4.test(pass)) count++;

            //console.log(pass, "Pasword is " + strength[count]); 
            if (strength[count] != undefined) {
                results = "Password is " + strength[count];
            }
        }
        return results;
    },

    getNewRuleSchema: function () {
        return { isRequired: false, dataType: null, pattern: null, minLength: null, maxLength: null, minValue: null, maxValue: null, minRecords: null, maxRecords: null };
    },

    permissionErrMsg: function (setGenericDialogSchema, setOpenDialog) {
        var msg = 'You do not have permission to perform action.';
        setGenericDialogSchema({ title: 'Permission Error', component: null, body: { innerHtml: { __html: msg } }, dialogType: "normal", fullWidth: true, maxWidth: "sm", showCancelBtn: false, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: () => { setOpenDialog() } })
        setOpenDialog(true);
    },

    permissionCategoryAllowed: function (functionList, categoryName) {
        var allowed = false;
        for (var key in functionList) {
            if (key.split('-')[0] == categoryName) {
                if (functionList[key].allow == true || functionList[key].allowRead == true || functionList[key].allowEdit == true || functionList[key].allowAdd == true || functionList[key].allowDelete == true) {
                    allowed = true;
                    break;
                }
            }
        }
        return allowed;
    },

    permissionFunctionAllowed: function (functionList, functionName) {
        var allowed = false;
        if (functionList[functionName].allow == true || functionList[functionName].allowRead == true || functionList[functionName].allowEdit == true || functionList[functionName].allowAdd == true || functionList[functionName].allowDelete == true) {
            allowed = true;
        }
        return allowed;
    },

    permissionAllowed: function (functionList, functionName, permissions) {
        var allowed = false;
        for (var pi = 0; pi < permissions.length; pi++) {
            if (functionList[functionName][permissions[pi]] == true) {
                allowed = true;
            }
        }    
        return allowed;
    },

    useCustomIdCheck: function (element) {
        var id = null;
        if (element != undefined) {
            id = element.id;
            if (element.customId != null && element.customId != '') {
                id = element.customId;
            }
        }
        return id;
    },

    getPlural: function (number) {
        var plural = "";
        if (number > 1) {
            plural = "s";
        }
        return plural;
    },

    slugify: function (value) {
        return value.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
    },

    pageNameUrlFormatter: function (value) {
        return value.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-');
    },

    fieldIdFormatter: function (value) {
        return value.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '_').replace(/^-+|-+$/g, '').replaceAll('-','');
    },

    getLastChar: function (value) {
        const stringLength = value.length;
        return value.charAt(stringLength - 1);
    },

    getDataSource: function (sourceName,dataSources) {
        var dataSource = null;
        if (sourceName != null) {
            for (var i = 0; i < dataSources.length; i++) {
                if (dataSources[i].sourceName == sourceName) {
                    dataSource = dataSources[i];
                    break;
                }
            }
        }
        return dataSource;
    },

    parseRelationInfo: function (relationInfoStr) {
        if (relationInfoStr != "" && relationInfoStr != undefined) {
            var relationInfoJson = JSON.parse(relationInfoStr);
            var from = relationInfoJson[0].from;
            var fromDataSourceName = from.split(".")[0];
            var fromFieldName = from.split(".")[1];
            var to = relationInfoJson[0].to;
            var toDataSourceName = to.split(".")[0];
            var toFieldName = to.split(".")[1];
            return [ fromDataSourceName, fromFieldName, toDataSourceName, toFieldName ];
        } else {
            return null
        }
    },

    filterDataSource: function (dataSource, filterValue, filterFieldName) {
        var filterList = [];
        var total = 0;
        for (var i = 0; i < dataSource.rows.length; i++) {
            if (dataSource.rows[i][filterFieldName] == filterValue) {
                filterList.push(dataSource.rows[i]);
            }
        }
        return filterList;
    },

    parseSourcePropertyName: function (sourceNameRaw, sourceKeys) {
        var parentSourceName = null;
        var parentPropertyName = null;
        var rowExists = false;
        switch (sourceKeys) {
            case 1:
                parentSourceName = sourceNameRaw;
                break;
            case 2:
                parentSourceName = sourceNameRaw.split('.')[0];
                parentPropertyName = sourceNameRaw.split('.')[1];
                break;
            case 3:
                rowExists = true;
                parentSourceName = sourceNameRaw.split('.')[0];
                parentPropertyName = sourceNameRaw.split('.')[2];
                break;
        }
        return [parentSourceName, rowExists, parentPropertyName];
    },

    getParentDataSource: function (elements, dataSources, collectionItemContext, collectionListId, parentCollectionListRowIndex) {
        var parentDataSource = null;
        if (dataSources != null) {
            var parentDataSourceRecord = null;
            var parentSourceNameRaw = null;
            var parentRowIndex = null;
            var sourceKeys = null;
            var parentSourceName = null;
            var parentPropertyName = null;
            if (collectionItemContext != undefined) {
                for (var i = 0; i < collectionItemContext.parents.length; i++) {
                    parentSourceNameRaw = collectionItemContext.parents[i].dataSourceName;
                    parentRowIndex = collectionItemContext.parents[i].rowIndex;
                    switch (collectionItemContext.parents[i].sourceType) {
                        case "collection":
                            if (parentDataSource == null) {
                                parentSourceName = parentSourceNameRaw;
                                parentDataSource = helpers.getDataSource(parentSourceName, dataSources);
                            }
                            break;
                        case "nested-collection":
                            if (parentDataSource == null) {
                                sourceKeys = parentSourceNameRaw.split('.').length;
                                const [parentSourceName, rowExists, parentPropertyName] = this.parseSourcePropertyName(parentSourceNameRaw, sourceKeys);
                                parentDataSource = helpers.getDataSource(parentSourceName, dataSources);
                                if (parentDataSource != null) {
                                    if (rowExists == false) {
                                        parentDataSource = parentDataSource.properties[parentPropertyName];
                                    } else if (rowExists == true) {
                                        parentDataSource = parentDataSource.rows[parentRowIndex][parentPropertyName];
                                    }
                                } //else {
                                  //  alert('Data source (' + parentSourceName + ') was not found');
                                //}
                            } else {
                                sourceKeys = parentSourceNameRaw.split('.').length;
                                const [parentSourceName, rowExists, parentPropertyName] = this.parseSourcePropertyName(parentSourceNameRaw, sourceKeys);
                                if (rowExists == false) {
                                    parentDataSource = parentDataSourceRecord.properties[parentPropertyName];
                                } else if (rowExists == true) {
                                    parentDataSource = parentDataSource.rows[parentRowIndex][parentPropertyName];
                                }
                            }
                            break;
                        case "nested-csv":
                            if (parentDataSource == null) {
                                sourceKeys = parentSourceNameRaw.split('.').length;
                                const [parentSourceName, rowExists, parentPropertyName] = this.parseSourcePropertyName(parentSourceNameRaw, sourceKeys);
                                parentDataSource = helpers.getDataSource(parentSourceName, dataSources);
                                if (parentDataSource != null) {
                                    if (rowExists == false) {
                                        if (parentDataSource.properties[parentPropertyName] != null) {
                                            parentDataSource = parentDataSource.properties[parentPropertyName].split(',');
                                        } else {
                                            parentDataSource = null;
                                        }
                                    } else if (rowExists == true) {
                                        parentDataSource = parentDataSource.rows[parentRowIndex][parentPropertyName].split(',');
                                    }
                                }
                            } else {
                                sourceKeys = parentSourceNameRaw.split('.').length;
                                const [parentSourceName, rowExists, parentPropertyName] = this.parseSourcePropertyName(parentSourceNameRaw, sourceKeys);
                                parentDataSource = parentDataSource[parentCollectionListRowIndex][parentPropertyName].split(',');
                            }
                            break;
                    }

                }
            }

            if (collectionListId != undefined && collectionListId != null) {

                var sourceType = elements[collectionListId].dataSourceType;
                var sourceNameRaw = elements[collectionListId].dataSourceName;
                var sourceKeys = sourceNameRaw.split('.').length;
                //var sourceName = null;
                //var propertyName = null;
                //const [sourceName, rowExists, propertyName] = parseSourcePropertyName(sourceNameRaw, sourceKeys);
                //var dataSource = null;
                //var dataSourceRecord = null;


                parentSourceNameRaw = elements[collectionListId].dataSourceName;
                switch (sourceType) {
                    case "collection":
                        if (parentDataSource == null) {
                            parentSourceName = parentSourceNameRaw;
                            parentDataSource = helpers.getDataSource(parentSourceName, dataSources);
                        }
                        break;
                    case "nested-collection":
                        if (parentDataSource == null) {
                            sourceKeys = parentSourceNameRaw.split('.').length;
                            const [parentSourceName, rowExists, parentPropertyName] = this.parseSourcePropertyName(parentSourceNameRaw, sourceKeys);
                            parentDataSource = helpers.getDataSource(parentSourceName, dataSources);
                            if (parentDataSource != null) {
                                if (rowExists == false) {
                                    parentDataSource = parentDataSource.properties[parentPropertyName];
                                } else if (rowExists == true) {
                                    parentDataSource = parentDataSource.rows[parentRowIndex][parentPropertyName];
                                }
                            }
                        } else {
                            sourceKeys = parentSourceNameRaw.split('.').length;
                            const [parentSourceName, rowExists, parentPropertyName] = this.parseSourcePropertyName(parentSourceNameRaw, sourceKeys);
                            if (rowExists == false) {
                                parentDataSource = parentDataSourceRecord[parentSourceName];
                            } else if (rowExists == true) {
                                parentDataSource = parentDataSource[parentRowIndex][parentPropertyName];
                            }
                        }
                        break;
                    case "nested-csv":
                        if (parentDataSource == null) {
                            sourceKeys = parentSourceNameRaw.split('.').length;
                            const [parentSourceName, rowExists, parentPropertyName] = this.parseSourcePropertyName(parentSourceNameRaw, sourceKeys);
                            parentDataSource = helpers.getDataSource(parentSourceName, dataSources);
                            if (parentDataSource != null) {
                                if (rowExists == false) {
                                    if (parentDataSource.properties[parentPropertyName] != null) {
                                        parentDataSource = parentDataSource.properties[parentPropertyName].split(',');
                                    } else {
                                        parentDataSource = null;
                                    }
                                } else if (rowExists == true) {
                                    parentDataSource = parentDataSource.rows[parentRowIndex][parentPropertyName].split(',');
                                }
                            }
                        } else {
                            sourceKeys = parentSourceNameRaw.split('.').length;
                            const [parentSourceName, rowExists, parentPropertyName] = this.parseSourcePropertyName(parentSourceNameRaw, sourceKeys);
                            parentDataSource = parentDataSource[parentRowIndex][parentPropertyName].split(',');
                        }
                        break;
                }
            }

            //if (sourceName != null && sourceName != '') {
            //    for (var i = 0; i < dataSources.length; i++) {
            //        if (dataSources[i].sourceName == sourceName) {
            //            switch (sourceKeys) {
            //                case 1:
            //                    list = dataSources[i];
            //                    break;
            //                case 2:
            //                    list = dataSources[i][propertyName];
            //                    break;
            //                case 3:
            //                    list = dataSources[i].rows.[propertyName];
            //                    break;
            //            }

            //        }
            //    }
            //}



            //if (sourceType != 'array') {
            //    var sourceName = elements[collectionListId].dataSourceName;
            //    if (sourceName != null && sourceName != '') {
            //        for (var i = 0; i < dataSources.length; i++) {
            //            if (dataSources[i].sourceName == sourceName) {
            //                var relationExists = false;
            //                if (dataSources[i].relationInfo != "" && dataSources[i].relationInfo != undefined) {
            //                    //var relationInfoJson = JSON.parse(dataSources[i].relationInfo);
            //                    const [fromDataSourceName, fromFieldName, toDataSourceName, toFieldName] = helpers.parseRelationInfo(dataSources[i].relationInfo);
            //                    var parentDataSource = helpers.getDataSource(toDataSourceName, dataSources);

            //                    var value = parentDataSource.rows[parentCollectionListRowIndex][toFieldName];
            //                    pageSize = getPageSizeByParentId(dataSources[i], value, fromFieldName);
            //                } else {
            //                    if (dataSources[i].pageSize != '') {
            //                        pageSize = dataSources[i].pageSize;
            //                    } else {
            //                        pageSize = dataSources[i].rows.length;
            //                    }
            //                }
            //                break;
            //            }
            //        }
            //    }
            //} else {
            //    var sourceName = elements[collectionListId].dataSourceName.split(".")[0];
            //    var propertyName = elements[collectionListId].dataSourceName.split(".")[2];
            //    //var sourceName = elements[collectionListId].dataSourceName;
            //    if (sourceName != null && sourceName != '') {
            //        for (var i = 0; i < dataSources.length; i++) {
            //            if (dataSources[i].sourceName == sourceName) {
            //                var list = dataSources[i].rows[parentCollectionListRowIndex][propertyName].split(',');
            //                pageSize = list.length;
            //                break;
            //            }
            //        }
            //    }
            //}

        }
        return parentDataSource;
    },

    formatNumber(value, decimal) {
        if (value == null) {
            value = 0;
        }
        return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString('en-US', {useGrouping:true,});
    },

    formatCurrencyGlobal(value) {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
        }).format(value);
    },

    formatCurrencyCustom(value, locale, currency) {
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currency
        }).format(value);
    },


    mergeSchemas: function (dbColumnSchemas, customSchemas) {
        for (var ccsi = 0; ccsi < customSchemas.length; ccsi++) {
            var customColumnSchema = customSchemas[ccsi];
            for (var dbcsi = 0; dbcsi < dbColumnSchemas.length; dbcsi++) {
                var columnSchema = dbColumnSchemas[dbcsi];
                if (columnSchema.columnName == customColumnSchema.columnName) {
                    for (var prop in columnSchema) {
                        if (prop != "columnName") {
                            if (customColumnSchema[prop] != null) {
                                columnSchema[prop] = customColumnSchema[prop];
                            }
                        }
                    }
                }
            }
        }
        for (var ccsi = 0; ccsi < customSchemas.length; ccsi++) {
            if (customSchemas[ccsi].nestedRecord == true) {
                dbColumnSchemas.push(JSON.parse(JSON.stringify(customSchemas[ccsi])));
            }
        }

        return dbColumnSchemas;
    },

    updateSchemaAliases: function (columnSchemas, formData) {
        var tempColumnSchemas = JSON.parse(JSON.stringify(columnSchemas));
        //loop through dbColumnSchemas
        for (const prop in formData) {
            var formDataField = prop.toLowerCase();
            for (var dbcsi = 0; dbcsi < tempColumnSchemas.length; dbcsi++) {
                var dbColumnSchema = tempColumnSchemas[dbcsi];
                if (dbColumnSchema.columnName == formDataField) {
                    dbColumnSchema.alias = prop;
                }
            }
        }
        return tempColumnSchemas;
    },

    isBasicFormValidationValid: function (columnSchemas, formData, setErrorList) {
        var column = null;
        var value = null;
        var isValid = true;
        var tempErrorList = {};
        for (var csi = 0; csi < columnSchemas.length; csi++) {
            column = columnSchemas[csi];
            if (formData.hasOwnProperty(column.alias)) {
                if (column.nestedRecord != true) {
                    value = formData[column.alias];
                    //Check required
                    if (column.isRequired == true) {
                        if (value === null || value === '') {
                            if ((column.isPrimaryKey == false && column.isForeignKey == false) || (column.isForeignKey == true && column.columnName != 'baid')) {
                                isValid = false;
                                tempErrorList[column.alias] = "Is required.";
                            }
                        }
                    }
                    if (value != null) {
                        //Check data type
                        switch (column.udtName.toLowerCase()) {
                            case 'bool':
                                break;
                            case 'int8', 'money':
                                if (helpers.isNumeric(value) == false) {
                                    isValid = false;
                                    tempErrorList[column.alias] = "Should be numeric values only.";
                                }
                                break;
                            case 'timestamp':
                                break;
                        }
                        //Check max length
                        if (column.maxLength != null) {
                            if (value.length > column.maxLength) {
                                isValid = false;
                                tempErrorList[column.alias] = "This field should not be greater than " + column.maxLength + " characters.";
                            }
                        }
                    }
                } else {
                    //Check for min records needed for nested records
                    if (column.minRecords != null) {
                        if (formData[column.alias].length < column.minRecords) {
                            isValid = false;
                            tempErrorList[column.alias] = "There should be a minimum of " + column.minRecords + " record(s).";
                        }
                    }
                }
            }
        }

        setErrorList(tempErrorList);
        return isValid;
    },

    copyText: function (value) {
        navigator.clipboard.writeText(value);
    },

    createColumnSchema: function (id, tableSchema, tableName, columnName, columnDefault, dataType, isRequired, minLength, maxLength, minRecords, maxRecords, numericPrecision, numericScale, udtName) {
        return { id: id, tableSchema: tableSchema, tableName: tableName, columnName: columnName, columnDefault: columnDefault, isRequired: isRequired, dataType: dataType, minLength: minLength, maxLength: maxLength, minRecords: minRecords, maxRecords: maxRecords, numericPrecision: numericPrecision, numericScale: numericScale, udtName: udtName };
    },

    isListEmpty: function (list) {
        var empty = false;
        var count = 0;
        for (var i = 0; i < list.length; i++) {
            if (list[i].internalState != 'deleted') {
                count++;
            }
        }
        if (count == 0) {
            empty = true;
        }
        return empty;
    },

    isListQtyAboveZero: function (list) {
        var result = false;
        var count = 0;
        for (var i = 0; i < list.length; i++) {
            if (list[i].internalState != 'deleted') {
                if (list[i].qty == 0) {
                    result = true
                    break;
                }
            }
        }
        return result;
    },

    isNumeric: function (str) {
        return !isNaN(parseFloat(str)) && isFinite(str);
        //if (typeof str != "string") return false // we only process strings!  
        //return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        //    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },

    isValidTextFormat: function (str, regExToTest) {
        return regExToTest.test(str);
    },


    performValidations: function (formValues, validationRules) {
        let valid = true;
        let error = {};

        const listCount = (list) => {
            var count = 0;
            for (let i = 0; i < list.length; i++) {
                if (list[i].internal_state != 'deleted') {
                    count++;
                }
            }
            return count;
        }



        const isNumeric = (str) => {
            if (typeof str != "string") return false // we only process strings!  
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        };

        const isFieldValid = (field, fieldValue, rules) => {
            let errorList = [];
            let isValid = true

            if (rules.isRequired != null) {
                if (rules.isRequired == true) {
                    if (fieldValue == null || fieldValue == '') {
                        errorList.push("This field is required.");
                        isValid = false;
                    }
                }
            }

            if (isValid == true) {
                if (fieldValue != null) {
                    if (rules.dataType != null) {
                        if (rules.dataType.toLowerCase() == "number") {
                            if (isNumeric(fieldValue) == false) {
                                errorList.push("This field should be a number.");
                                isValid = false;
                            }
                        //} else if (rules.dataType.toLowerCase() == "date") {
                        //    var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                        //   if (!pattern.test(fieldValue)) {
                        //        errorList.push("This is not a valid email format.");
                        //        isValid = false;
                        //    }
                        } else if (rules.dataType.toLowerCase() == "email") {
                            var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                            if (!pattern.test(fieldValue)) {
                                errorList.push("This is not a valid email format.");
                                isValid = false;
                            }
                        } else if (rules.dataType.toLowerCase() == "phone") {
                            var pattern = /^(1[ \-\+]{0,3}|\+1[ -\+]{0,3}|\+1|\+)?((\(\+?1-[2-9][0-9]{1,2}\))|(\(\+?[2-8][0-9][0-9]\))|(\(\+?[1-9][0-9]\))|(\(\+?[17]\))|(\([2-9][2-9]\))|([ \-\.]{0,3}[0-9]{2,4}))?([ \-\.][0-9])?([ \-\.]{0,3}[0-9]{2,4}){2,3}$/;
                            if (!pattern.test(fieldValue)) {
                                errorList.push("This is not a valid phone format.");
                                isValid = false;
                            }
                        }
                    }
                    if (rules.minLength != null) {
                        if (fieldValue.length < rules.minLength) {
                            errorList.push("This field should be at least " + rules.minLength + " characters.");
                            isValid = false;
                        }
                    }
                    if (rules.maxLength != null) {
                        if (fieldValue.length > rules.maxLength) {
                            errorList.push("This field should not be greater than " + rules.maxLength + " characters.");
                            isValid = false;
                        }
                    }
                    if (rules.minRecords != null) {
                        if (listCount(fieldValue) < rules.minRecords) {
                            errorList.push("This field should have a least " + rules.minRecords + " item(s).");
                            isValid = false;
                        }
                    }
                    if (rules.maxRecords != null) {
                        if (listCount(fieldValue) > rules.maxRecords) {
                            errorList.push("This field should not have more than " + rules.maxRecords + " item(s).");
                            isValid = false;
                        }
                    }
                }
            }

            return { fieldValid: isValid, fieldErrors: errorList };
        };


        for (let i = 0; i < validationRules.fields.length; i++) {
            var fieldName = validationRules.fields[i].fieldName;
            var rules = validationRules.fields[i].rules;
            const { fieldValid, fieldErrors } = isFieldValid(fieldName, formValues[fieldName], rules)
            if (fieldValid == false) {
                for (let ei = 0; ei < fieldErrors.length; ei++) {
                    if (error.hasOwnProperty(fieldName) == false) {
                        error[fieldName] = fieldErrors[ei];
                    } else {
                        error[fieldName] += fieldErrors[ei];
                    }
                    //errorList.push({ field: fieldName, error: fieldErrors[ei] });
                }
            }
        }
        if (JSON.stringify(error) != '{}') {
            valid = false;
        }
        return { isValid: valid, error: error };
    },



    updateRecordState: function (tmpRecordsState, setRecordsState, list, action, id) {
        //var tmpRecordsState = { ...recordsState }
        if (tmpRecordsState.hasOwnProperty(list) == false) {
            tmpRecordsState[list] = { [action]: [] };
        } else {
            if (tmpRecordsState[list].hasOwnProperty(action) == false) {
                tmpRecordsState[list][action] = [];
            }
        }       

        if (tmpRecordsState[list][action].indexOf(id) == -1) {
            switch (action) {
                case 'add':
                    tmpRecordsState[list][action].push(id);
                    break;
                case 'change':
                    var index = tmpRecordsState[list]["add"].indexOf(id);
                    if (index == -1) {
                        tmpRecordsState[list][action].push(id);
                    }

                case 'delete':
                    //** only add to change or delete if it's not a new record.
                    var index = tmpRecordsState[list]["add"].indexOf(id);
                    if (index == -1) {
                        tmpRecordsState[list][action].push(id);
                    } else {
                        tmpRecordsState[list].add.splice(index, 1);
                    }
                    break;
            }
        }
        setRecordsState(tmpRecordsState);
        //return tmpRecordsState; //setRecordsState(tmpRecordsState);
    },

    //callApi: function (options) {
    //    let requestOptions = {
    //        method: options.method, 
    //        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + options.token },
    //        mode: 'cors'
    //    }

    //    if (options.hasOwnProperty('body')) {
    //        if (options.body != null) {
    //            requestOptions['body'] = JSON.stringify(options.body);
    //        }
    //    }

    //    fetch(options.url, requestOptions)


    //},

    callApi: function (options, onDataReadyFunction, onErrorFunction) {
        let requestOptions = {
            method: options.method,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + options.token },
            mode: 'cors'
        };
        if (options.hasOwnProperty('body')) {
            if (options.body != null) {
                requestOptions['body'] = JSON.stringify(options.body);
            }
        }

        //setIsLoading(true);

        fetch(options.url, requestOptions)
            .then(async response => {    
                let data = null
                let error = '';
                if (!response.ok) {
                    if (response.status == 404 || response.status == 405) {
                        error = '<p>Code: ' + response.status + ' -- URL not found or fetch method is wrong.</p>';
                    } else {
                        data = await response.json();
                        if (data.title != undefined) {
                            error = '<p>Code: ' + data.status + ' -- ' + data.title + '</p>';
                            error += '<ul>';
                            for (const key in data.errors) {
                                if (data.errors.hasOwnProperty(key)) {
                                    error += '<li>' + key + ' - ' + data.errors[key] + '</li>';
                                }
                            }
                            error += '</ul>';
                        }
                    }
                    return Promise.reject(error);
                }
                
                data = await response.json();
                if (onDataReadyFunction != null) {
                    switch (options.method) {
                        case 'GET':
                            var paginationStatus = null;
                            for (var pair of response.headers.entries()) {
                                if (pair[0] == 'x-pagination') {
                                    paginationStatus = JSON.parse(pair[1]);
                                    break;
                                }
                            }
                            onDataReadyFunction(data, paginationStatus);
                            break;
                        case 'POST':
                            onDataReadyFunction(data);
                            break;
                        case 'PUT':
                            onDataReadyFunction(data);
                            break;
                        case 'DELETE':
                            onDataReadyFunction();
                            break;
                    }
                }
                
            })
            .catch(error => {
                onErrorFunction(error);
                //let msg = '<p>' + error + '</p><p>Need help? Contact support.</p>';
                //setGenericModalSchema({ title: 'Save Failed', body: { innerHtml: { __html: msg } }, showCancelBtn: false, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-danger', closeModal: () => { setOpenModalGeneric() }, okModal: () => { setOpenModalGeneric() } })
                //setOpenModalGeneric(true);
            });
            //.finally(() => {
            //    setIsLoading(false);
            //});          
    },

    getRootElementId: function (tmpElements) {
        var id = null;
        for (var key in tmpElements) {
            id = key;
            break;
        }
        return id;
    },

    getPageContainerElementId: function (tmpElements) {
        var id = null;
        for (var key in tmpElements) {
            if (tmpElements[key].elementName == "pagecontainer") {
                id = key;
                break;
            }
        }
        return id;
    },

    getUUID: function () {
        //const s4 = () => {
        //    return Math.floor((1 + Math.random()) * 0x10000)
        //        .toString(16)
        //        .substring(1);
        //}
        //var guid = s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
        //var guid = crypto.randomUUID().toString().replaceAll('-', '');
        var guid = uuidv4().toString().replaceAll('-', '');
        return guid;
    },

    

    updateInternalState: function (tmpArray, index, action) {
        switch (action) {
            case 'modified':
                if (tmpArray[index].internal_state != "added") {
                    tmpArray[index].internal_state = "modified";
                }
                break;
            case 'deleted':
                if (tmpArray[index].internal_state == "added") {
                    tmpArray.splice(index, 1);
                } else {
                    tmpArray[index].internal_state = "deleted";
                }
                break;
        }
                
    },

    convertShorthandToMin: function (val) {
        val = val.toLowerCase();
        var days = parseInt(0);
        var hours = parseInt(0);
        var minutes = parseInt(0);
        if (val.indexOf('d') > -1) {
            days = val.split('d')[0];
            val = val.replace(days + 'd', '');
        }
        if (val.indexOf('h') > -1) {
            hours = val.split('h')[0];
            val = val.replace(hours + 'h', '');
        }
        if (val.indexOf('m') > -1) {
            minutes = val.split('m')[0];
        }

        return parseInt(days * 480) + parseInt(hours * 60) + parseInt(minutes);
        //var durArray = { length: parseInt(days * 1440) + parseInt(hours * 60) + parseInt(minutes), type: 'minutes' }
        //return durArray;
    },

    convertMinToShorthand: function (val) {
        var dayMin = parseInt(480);
        var hourMin = parseInt(60);
        var min = parseInt(1);

        var remain = parseInt(val);

        var days = parseInt(0);
        var hours = parseInt(0);
        var minutes = parseInt(0);

        var duration = '';

        days = Math.floor(remain / dayMin);
        remain = remain - (days * dayMin);
        hours = Math.floor(remain / hourMin);
        remain = remain - (hours * hourMin);
        minutes = remain;

        if (days > 0) {
            duration += days + 'd';
        }
        if (hours > 0) {
            duration += hours + 'h';
        }
        if (minutes > 0) {
            duration += minutes + 'm';
        }

        //if (remain < 60) {
        //    duration = remain + 'm';
        //} else {
        //    duration = Math.ceil((remain / hourMin)) + 'h';
        //}

        return duration;
    },

    convertMinToShorthandReadable: function (val) {
        var dayMin = parseInt(480);
        var hourMin = parseInt(60);
        var min = parseInt(1);

        var remain = parseInt(val);

        var days = parseInt(0);
        var hours = parseInt(0);
        var minutes = parseInt(0);
        
        var duration = '';

        days = Math.floor(remain / dayMin);
        remain = remain - (days * dayMin);
        hours = Math.floor(remain / hourMin);
        remain = remain - (hours * hourMin);
        minutes = remain;

        if (days > 0) {
            duration += days + ' ' + (days > 1 ? 'days' : 'day');
        }
        if (hours > 0) {
            var prefix = '';
            if (days > 0 && minutes == 0) {
                prefix = ' and ';
            } else if (days > 0 && minutes > 0) {
                prefix = ', ';
            }
            
            duration += prefix + hours + ' ' + (hours > 1 ? 'hours' : 'hour');
        }
        if (minutes > 0) {
            var prefix = '';
            if (duration != '') {
                prefix = ' and ';
            }
            duration += prefix + minutes + ' ' + (minutes > 1 ? 'minutes' : 'minute');
        }

        //if (remain < 60) {
        //    duration = remain + 'm';
        //} else {
        //    duration = Math.ceil((remain / hourMin)) + 'h';
        //}

        return duration;
    },

    convertMinToHourMin: function (val) {
        var dayMin = parseInt(480);
        var hourMin = parseInt(60);
        var min = parseInt(1);

        var remain = parseInt(val);

        var days = parseInt(0);
        var hours = parseInt(0);
        var minutes = parseInt(0);

        var duration = '';

        //days = Math.floor(remain / dayMin);
        //remain = remain - (days * dayMin);
        hours = Math.floor(remain / hourMin);
        remain = remain - (hours * hourMin);
        minutes = remain;

        //if (days > 0) {
        //    duration += days + 'd';
        //}
        if (hours > 0) {
            duration += hours + 'h';
        }
        if (minutes > 0) {
            duration += minutes + 'm';
        }

        //if (remain < 60) {
        //    duration = remain + 'm';
        //} else {
        //    duration = Math.ceil((remain / hourMin)) + 'h';
        //}

        return duration;
    },

    intFormatDate: function (date, dateFormat) {
        var formattedDate = dayjs(date).format(dateFormat);
        return formattedDate;
    }

}

export default helpers