import { BAFetchData } from "../customhooks/useBAFetch";
import helpers from "./Helpers";
import dayjs from 'dayjs';


const ProductEngineUtil = {  

    GetVariantSummary: function (variantRow) {
        var summary = "";
        var attrInfo = null;
        var column = "";
        if (variantRow != null) {
            for (var i = 0; i < variantRow.productVariantAttributeList.length; i++) {
                attrInfo = variantRow.productVariantAttributeList[i];
                if (attrInfo.attrName != 'Color-Swatch' && attrInfo.attrName != 'Color-Thumbnail') {
                    summary += column + attrInfo.attrName + ": " + attrInfo.attrValue;
                    column = "|";
                }                
            }
        }
        return summary;
    },

    GetSelectedProductCheckoutInfo: function (paymentOption, productRow, variantRow) {
        var options = this.GetPaymentOptions(productRow, variantRow);
        var info = {
            "recurType": productRow.recurType, "paymentOption": paymentOption, "productId": productRow.productId,
            "variantId": variantRow != null ? variantRow.variantId : null, "productName": productRow.productName,
            "summary": options[paymentOption].summary, "taxable": productRow.taxable, "hasUsageFees": productRow.hasUsageFees, "cardRequiredFree": productRow.cardRequiredFree,
            "price": options[paymentOption].price, "totalDueToday": options[paymentOption].totalDueToday, "setupFee": productRow.setupFee, "downpayment": 0, "variantSummary": this.GetVariantSummary(variantRow),            
        };

        if (variantRow == null) {
            info["skuNum"] = productRow.skuNum;
        } else {
            info["skuNum"] = variantRow.skuNum;
        }

        var useRecurInfo = false;
        if (productRow.recurType == 'SubscriptionOnly-Static' && paymentOption == 'option1') {
            useRecurInfo = true;
        } else if (productRow.recurType == 'Subscription-Static' && paymentOption == 'option2') {
            useRecurInfo = true;
        } else if (productRow.recurType == 'SubscriptionOnly' && paymentOption == 'option1') {
            useRecurInfo = true;
        } else if (productRow.recurType == 'Subscription' && paymentOption == 'option2') {
            useRecurInfo = true;
        }
        if (useRecurInfo == true) {
            if (variantRow == null) {
                info["recurInfo"] = { "planPrice": productRow.planPrice, "downpayment": productRow.downpayment, "subscriptionDiscount": productRow.subscriptionDiscount, "freeTrialInterval": productRow.freeTrialInterval, "freeTrialPeriod": productRow.freeTrialPeriod, "billLength": productRow.billLength, "billInterval": productRow.billInterval, "billPeriod": productRow.billPeriod, "billInstallment": productRow.billInstallment, "syncDay": productRow.syncDay, "prorate": productRow.prorate };
            } else {
                info["recurInfo"] = { "planPrice": productRow.planPrice, "downpayment": productRow.downpayment, "subscriptionDiscount": productRow.subscriptionDiscount, "freeTrialInterval": productRow.freeTrialInterval, "freeTrialPeriod": productRow.freeTrialPeriod, "billLength": productRow.billLength, "billInterval": productRow.billInterval, "billPeriod": productRow.billPeriod, "billInstallment": variantRow.unitBillInstallment, "syncDay": productRow.syncDay, "prorate": productRow.prorate };
            }
        } else {
            info["recurInfo"] = null;
        }

        //switch (productRow.recurType) {
        //    case "":
        //        if (variantRow == null) {
        //            info.price = productRow.price;
        //        } else {
        //            info.price = variantRow.unitPrice;
        //        }
        //        break;
        //    case "Payment Plan":
        //        if (variantRow == null) {
        //            info.downpayment = productRow.downpayment;
        //            if (productRow.downpayment == 0) {
        //                info.price = productRow.billInstallment;
        //            }
        //        } else {
        //            info.downpayment = productRow.downpayment;
        //            if (productRow.downpayment == 0) {
        //                info.price = variantRow.unitBillInstalment;
        //            }
        //        }
        //        break;
        //    case "SubscriptionOnly-Static":
        //        if (variantRow == null) {
        //            if (productRow.freeTrialInterval == 0) {
        //                info.price = productRow.billInstallment;
        //            }                    
        //        } else {
        //            if (productRow.freeTrialInterval == 0) {
        //                info.price = variantRow.unitBillInstalment;
        //            }
        //        }
        //        break;
        //    case "Subscription-Static":
        //        if (variantRow == null) {
        //            if (productRow.freeTrialInterval == 0) {
        //                info.price = productRow.billInstallment;
        //            }
        //        } else {
        //            if (productRow.freeTrialInterval == 0) {
        //                info.price = variantRow.unitBillInstalment;
        //            }
        //        }
        //        break;
        //    case "SubscriptionOnly":
        //        if (variantRow == null) {
        //            if (productRow.freeTrialInterval == 0) {
        //                info.price = productRow.billInstallment;
        //            }
        //        } else {
        //            if (productRow.freeTrialInterval == 0) {
        //                info.price = variantRow.unitBillInstalment;
        //            }
        //        }
        //        break;
        //    case "Subscription":
        //        if (variantRow == null) {
        //            if (productRow.freeTrialInterval == 0) {
        //                info.price = productRow.billInstallment;
        //            }
        //        } else {
        //            if (productRow.freeTrialInterval == 0) {
        //                info.price = variantRow.unitBillInstalment;
        //            }
        //        }
        //        break;
        //}
        return info;
    },

    GetPriceSummary: function (productRow) {
        var priceSummary = "";
        var additionalMsg = "";
        switch (productRow.recurType) {
            case "":
                if (productRow.maxPrice > 0) {
                    priceSummary +=  "$" + productRow.price + " - $" + productRow.maxPrice;
                } else {
                    priceSummary += "$" + productRow.price;
                }
                if (productRow.setupFee > 0) {
                    priceSummary += ". Plus setup fee of $" + productRow.setupFee + ".";
                }
                break;   
            case "Payment Plan":
                if (productRow.variantsExists == false) {
                    priceSummary += "$" + productRow.price;
                    priceSummary += " or " + productRow.billLength + " payment(s) of $" + productRow.billInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        priceSummary += productRow.billInterval + " "
                    }
                    priceSummary += productRow.billPeriod + ".";
                    if (productRow.setupFee > 0) {
                        priceSummary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }
                } else {
                    if (productRow.maxPrice > 0) {
                        priceSummary += "$" + productRow.price + " - $" + productRow.maxPrice;
                    } else {
                        priceSummary += "$" + productRow.price;
                    }
                    priceSummary += " or ";
                    if (productRow.downpayment > 0) {
                        priceSummary += "downpayment of $" + productRow.downpayment + " and then... ";
                    }
                    priceSummary += productRow.billLength + " payment(s) starting at $" + productRow.billInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        priceSummary += productRow.billInterval + " "
                    }
                    priceSummary += productRow.billPeriod + ".";
                    if (productRow.setupFee > 0) {
                        priceSummary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }
                }
                break;
            case "Subscription-Static":
                if (productRow.variantsExists == false) {
                    priceSummary += "$" + productRow.price;
                    priceSummary += " or subscribe for $" + productRow.billInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        priceSummary += productRow.billInterval + " "
                    }
                    priceSummary += productRow.billPeriod + ".";
                    if (productRow.billLength > 0) {
                        priceSummary += " (" + productRow.billLength + " total payments).";
                    }
                    if (productRow.setupFee > 0) {
                        priceSummary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }
                } else {
                    if (productRow.maxPrice > 0) {
                        priceSummary += "$" + productRow.price + " - $" + productRow.maxPrice;
                    } else {
                        priceSummary += "$" + productRow.price;
                    }
                    priceSummary += " or ";
                    priceSummary += " or subscriptions starting at $" + productRow.billInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        priceSummary += productRow.billInterval + " "
                    }
                    priceSummary += productRow.billPeriod + ".";
                    if (productRow.billLength > 0) {
                        priceSummary += " (" + productRow.billLength + " total payments).";
                    }
                    if (productRow.setupFee > 0) {
                        priceSummary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }
                }
                break;
            case "SubscriptionOnly-Static":
                if (productRow.variantsExists == false) {
                    if (productRow.freeTrialInterval > 0) {
                        priceSummary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... ";
                    }
                    priceSummary += "$" + productRow.billInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        priceSummary += productRow.billInterval + " "
                    }
                    priceSummary += productRow.billPeriod + ".";
                    if (productRow.billLength > 0) {
                        priceSummary += " (" + productRow.billLength + " total payments).";
                    }
                    if (productRow.setupFee > 0) {
                        priceSummary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }
                } else {
                    if (productRow.freeTrialInterval > 0) {
                        priceSummary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... Subscriptions starting at ";
                    } else {
                        priceSummary += "Starting at ";
                    }
                    priceSummary += "$" + productRow.billInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        priceSummary += productRow.billInterval + " "
                    }
                    priceSummary += productRow.billPeriod + ".";
                    if (productRow.billLength > 0) {
                        priceSummary += " (" + productRow.billLength + " total payments).";
                    }
                    if (productRow.setupFee > 0) {
                        priceSummary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }
                }
                break;
            case "Subscription":
                if (productRow.variantsExists == false) {
                    priceSummary += "$" + productRow.price;
                    priceSummary += " or subscribe for $" + productRow.billInstallment + ".";
                    if (productRow.setupFee > 0) {
                        priceSummary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }
                    additionalMsg += "Customer will determine subscription billing/delivery options at checkout.";
                } else {
                    if (productRow.maxPrice > 0) {
                        priceSummary += "$" + productRow.price + " - $" + productRow.maxPrice;
                    } else {
                        priceSummary += "$" + productRow.price;
                    }
                    priceSummary += " or ";
                    priceSummary += " or subscriptions starting at $" + productRow.billInstallment + ".";
                    if (productRow.setupFee > 0) {
                        priceSummary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }
                    additionalMsg += "Customer will determine subscription billing/delivery options at checkout.";
                }
                break;
            case "SubscriptionOnly":
                if (productRow.variantsExists == false) {
                    if (productRow.freeTrialInterval > 0) {
                        priceSummary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... ";
                    }
                    priceSummary += "Subscribe for $" + productRow.billInstallment + ".";                   
                    if (productRow.setupFee > 0) {
                        priceSummary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }
                    additionalMsg += "Customer will determine subscription billing/delivery options at checkout.";
                } else {
                    if (productRow.freeTrialInterval > 0) {
                        priceSummary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... Subscriptions starting at ";
                    } else {
                        priceSummary += "Subscriptions starting at ";
                    }
                    priceSummary += "$" + productRow.billInstallment + "";
                    if (productRow.setupFee > 0) {
                        priceSummary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }
                    additionalMsg += "Customer will determine subscription billing/delivery options at checkout.";
                }
                break;
        }
        return {"summary": priceSummary, "additionalMsg": additionalMsg};
    },

    GetPaymentOptions: function (productRow, variantRow) {
        var options = {
            "option1": { "summary": "", "price": parseFloat(0), "totalDueToday": parseFloat(0) },
            "option2": { "summary": "", "price": parseFloat(0), "totalDueToday": parseFloat(0) }
        };
        var priceSummary = "";
        var additionalMsg = "";
        var totalDueToday = parseFloat(0);
        switch (productRow.recurType) {
            case "":
                if (variantRow == null) {
                    options.option1.summary = "$" + productRow.price;
                    if (productRow.setupFee > 0) {
                        options.option1.totalDueToday += productRow.setupFee;
                        options.option1.summary += ". Plus setup fee of $" + productRow.setupFee + ".";
                    }
                    options.option1.price = productRow.price;
                    options.option1.totalDueToday += productRow.price;
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";
                } else {
                    options.option1.summary = "$" + variantRow.unitPrice;
                    if (productRow.setupFee > 0) {
                        options.option1.totalDueToday += productRow.setupFee;
                        options.option1.summary += ". Plus setup fee of $" + productRow.setupFee + ".";
                    }
                    options.option1.price = productRow.price;
                    options.option1.totalDueToday += productRow.price;
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";
                }
                break;
            case "Payment Plan":
                if (productRow.variantsExists == false) {
                    
                    options.option1.summary += "$" + productRow.price;

                    options.option1.price += productRow.price;

                    options.option1.totalDueToday += productRow.price;
                    if (productRow.setupFee > 0) {
                        options.option1.totalDueToday += productRow.setupFee;
                        options.option1.summary += ". Plus setup fee of $" + productRow.setupFee + ".";
                    }
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";

                    if (productRow.downpayment > 0) {
                        options.option2.summary += "Downpayment of $" + productRow.downpayment + " and then... ";
                    }
                    options.option2.summary += productRow.billLength + " payment(s) of $" + productRow.billInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        options.option2.summary += productRow.billInterval + " "
                    }
                    options.option2.summary += productRow.billPeriod + ".";
                    if (productRow.setupFee > 0) {
                        options.option2.summary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }

                    if (productRow.downpayment == 0) {
                        options.option2.price += productRow.price;
                    }

                    options.option2.totalDueToday += productRow.setupFee;
                    options.option2.totalDueToday += productRow.downpayment;
                    if (productRow.downpayment == 0) {
                        options.option2.totalDueToday += productRow.price;
                    }

                    options.option2.summary += ". Total due today... $" + options.option2.totalDueToday + ".";
                    options.option2.summary += " Next payment due on " + dayjs().add(productRow.billInterval, productRow.billPeriod).format('MM/DD/YYYY') + ".";

                } else {
                    options.option1.summary += "$" + variantRow.unitPrice;

                    options.option1.price += variantRow.unitPrice;

                    options.option1.totalDueToday += variantRow.unitPrice;
                    if (productRow.setupFee > 0) {
                        options.option1.totalDueToday += productRow.setupFee;
                    }
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";


                    if (productRow.downpayment > 0) {
                        options.option2.summary += "Downpayment of $" + productRow.downpayment + " and then... ";
                    }
                    options.option2.summary += productRow.billLength + " payment(s) of $" + variantRow.unitBillInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        options.option2.summary += productRow.billInterval + " "
                    }
                    options.option2.summary += productRow.billPeriod + ".";
                    if (productRow.setupFee > 0) {
                        options.option2.summary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }

                    if (productRow.downpayment == 0) {
                        options.option2.price += variantRow.unitBillInstallment;
                    }

                    options.option2.totalDueToday += productRow.setupFee;
                    options.option2.totalDueToday += productRow.downpayment;
                    if (productRow.downpayment == 0) {
                        options.option2.totalDueToday += variantRow.unitBillInstallment;
                    }
                    options.option2.summary += ". Total due today... $" + options.option2.totalDueToday + ".";
                    options.option2.summary += " Next payment due on " + dayjs().add(productRow.billInterval, productRow.billPeriod).format('MM/DD/YYYY') + ".";
                }
                break;
            case "Subscription-Static":
                if (productRow.variantsExists == false) {

                    options.option1.summary += "$" + productRow.price;

                    options.option1.price += productRow.price;

                    options.option1.totalDueToday += productRow.price;
                    if (productRow.setupFee > 0) {
                        options.option1.totalDueToday += productRow.setupFee;
                    }
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";

                    if (productRow.freeTrialInterval > 0) {
                        options.option2.summary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... ";
                    }

                    options.option2.summary += "$" + productRow.billInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        options.option2.summary += productRow.billInterval + " "
                    }
                    options.option2.summary += productRow.billPeriod + "";
                    if (productRow.billLength > 0) {
                        options.option2.summary += "(" + productRow.billLength + " total payments)";
                    }
                    options.option2.summary += ".";

                    if (productRow.setupFee > 0) {
                        options.option2.summary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }

                    if (productRow.freeTrialInterval == 0) {
                        options.option2.price += productRow.billInstallment;
                    }

                    options.option2.totalDueToday += productRow.setupFee;
                    if (productRow.freeTrialInterval == 0) {
                        options.option2.totalDueToday += productRow.billInstallment;
                    }
                    options.option2.summary += ". Total due today... $" + options.option2.totalDueToday + ".";
                    if (productRow.freeTrialInterval > 0) {
                        options.option2.summary += " Next payment due on " + dayjs().add(productRow.freeTrialInterval, productRow.freeTrialPeriod).format('MM/DD/YYYY') + ".";
                    } else {
                        options.option2.summary += " Next payment due on " + dayjs().add(productRow.billInterval, productRow.billPeriod).format('MM/DD/YYYY') + ".";
                    }
                    

                } else {
                    options.option1.summary += "$" + variantRow.unitPrice;

                    options.option1.price += variantRow.unitPrice;

                    options.option1.totalDueToday += variantRow.unitPrice;
                    if (productRow.setupFee > 0) {
                        options.option1.totalDueToday += productRow.setupFee;
                    }
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";

                    if (productRow.freeTrialInterval > 0) {
                        options.option2.summary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... ";
                    }

                    options.option2.summary += "$" + variantRow.unitBillInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        options.option2.summary += productRow.billInterval + " "
                    }
                    options.option2.summary += productRow.billPeriod + "";
                    if (productRow.billLength > 0) {
                        options.option2.summary += "(" + productRow.billLength + " total payments)";
                    }
                    options.option2.summary += ".";

                    if (productRow.setupFee > 0) {
                        options.option2.summary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }

                    if (productRow.freeTrialInterval == 0) {
                        options.option2.price += variantRow.unitBillInstallment;
                    }

                    options.option2.totalDueToday += productRow.setupFee;
                    if (productRow.freeTrialInterval == 0) {
                        options.option2.totalDueToday += variantRow.unitBillInstallment;
                    }
                    options.option2.summary += ". Total due today... $" + options.option2.totalDueToday + ".";
                    if (productRow.freeTrialInterval > 0) {
                        options.option2.summary += " Next payment due on " + dayjs().add(productRow.freeTrialInterval, productRow.freeTrialPeriod).format('MM/DD/YYYY') + ".";
                    } else {
                        options.option2.summary += " Next payment due on " + dayjs().add(productRow.billInterval, productRow.billPeriod).format('MM/DD/YYYY') + ".";
                    }
                }
                break;
            case "SubscriptionOnly-Static":
                if (productRow.variantsExists == false) {

                    if (productRow.freeTrialInterval > 0) {
                        options.option1.summary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... ";
                    }
                    options.option1.summary += "$" + productRow.billInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        options.option1.summary += productRow.billInterval + " "
                    }
                    options.option1.summary += productRow.billPeriod + "";
                    if (productRow.billLength > 0) {
                        options.option1.summary += "(" + productRow.billLength + " total payments)";
                    }
                    options.option1.summary += ".";

                    if (productRow.setupFee > 0) {
                        options.option1.summary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }

                    if (productRow.freeTrialInterval == 0) {
                        options.option1.price += productRow.billInstallment;
                    }

                    options.option1.totalDueToday += productRow.setupFee;
                    if (productRow.freeTrialInterval == 0) {
                        options.option1.totalDueToday += productRow.billInstallment;
                    }
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";
                    if (productRow.freeTrialInterval > 0) {
                        options.option1.summary += " Next payment due on " + dayjs().add(productRow.freeTrialInterval, productRow.freeTrialPeriod).format('MM/DD/YYYY') + ".";
                    } else {
                        options.option1.summary += " Next payment due on " + dayjs().add(productRow.billInterval, productRow.billPeriod).format('MM/DD/YYYY') + ".";
                    }

                } else {
                    if (productRow.freeTrialInterval > 0) {
                        options.option1.summary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... ";
                    }
                    options.option1.summary += "$" + variantRow.unitBillInstallment + " every ";
                    if (productRow.billInterval > 1) {
                        options.option1.summary += productRow.billInterval + " "
                    }
                    options.option1.summary += productRow.billPeriod + "";
                    if (productRow.billLength > 0) {
                        options.option1.summary += "(" + productRow.billLength + " total payments)";
                    }
                    options.option1.summary += ".";

                    if (productRow.setupFee > 0) {
                        options.option1.summary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }

                    if (productRow.freeTrialInterval == 0) {
                        options.option1.price += variantRow.unitBillInstallment;
                    }

                    options.option1.totalDueToday += productRow.setupFee;
                    if (productRow.freeTrialInterval == 0) {
                        options.option1.totalDueToday += variantRow.unitBillInstallment;
                    }
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";
                    if (productRow.freeTrialInterval > 0) {
                        options.option1.summary += " Next payment due on " + dayjs().add(productRow.freeTrialInterval, productRow.freeTrialPeriod).format('MM/DD/YYYY') + ".";
                    } else {
                        options.option1.summary += " Next payment due on " + dayjs().add(productRow.billInterval, productRow.billPeriod).format('MM/DD/YYYY') + ".";
                    }
                }
                break;
            case "Subscription":
                if (productRow.variantsExists == false) {

                    options.option1.summary += "$" + productRow.price;

                    options.option1.price += productRow.price;

                    options.option1.totalDueToday += productRow.price;
                    if (productRow.setupFee > 0) {
                        options.option1.totalDueToday += productRow.setupFee;
                    }
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";


                    if (productRow.freeTrialInterval > 0) {
                        options.option2.summary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... ";
                    }

                    options.option2.summary += "Subcribe for $" + productRow.billInstallment + ".";

                    if (productRow.setupFee > 0) {
                        options.option2.summary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }

                    if (productRow.freeTrialInterval == 0) {
                        options.option2.price += productRow.billInstallment;
                    }

                    options.option2.totalDueToday += productRow.setupFee;
                    if (productRow.freeTrialInterval == 0) {
                        options.option2.totalDueToday += productRow.billInstallment;
                    }
                    options.option2.summary += ". Total due today... $" + options.option2.totalDueToday + ".";
                    if (productRow.freeTrialInterval > 0) {
                        options.option2.summary += " Next payment due on " + dayjs().add(productRow.freeTrialInterval, productRow.freeTrialPeriod).format('MM/DD/YYYY') + ".";
                    } else {
                        options.option2.summary += " Next payment due on " + dayjs().add(productRow.billInterval, productRow.billPeriod).format('MM/DD/YYYY') + ".";
                    }
                } else {
                    options.option1.summary += "$" + variantRow.unitPrice;

                    options.option1.price += variantRow.unitPrice;

                    options.option1.totalDueToday += variantRow.unitPrice;
                    if (productRow.setupFee > 0) {
                        options.option1.totalDueToday += productRow.setupFee;
                    }
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";


                    if (productRow.freeTrialInterval > 0) {
                        options.option2.summary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... ";
                    }

                    options.option2.summary += "$" + variantRow.unitBillInstallment + ".";
                    if (productRow.setupFee > 0) {
                        options.option2.summary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }

                    if (productRow.freeTrialInterval == 0) {
                        options.option2.price += variantRow.unitBillInstallment;
                    }

                    options.option2.totalDueToday += productRow.setupFee;
                    if (productRow.freeTrialInterval == 0) {
                        options.option2.totalDueToday += variantRow.unitBillInstallment;
                    }
                    options.option2.summary += ". Total due today... $" + options.option2.totalDueToday + ".";
                    if (productRow.freeTrialInterval > 0) {
                        options.option2.summary += " Next payment due on " + dayjs().add(productRow.freeTrialInterval, productRow.freeTrialPeriod).format('MM/DD/YYYY') + ".";
                    } else {
                        options.option2.summary += " Next payment due on " + dayjs().add(productRow.billInterval, productRow.billPeriod).format('MM/DD/YYYY') + ".";
                    }
                }
                break;
            case "SubscriptionOnly":
                if (productRow.variantsExists == false) {
                    if (productRow.freeTrialInterval > 0) {
                        options.option1.summary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... ";
                    }
                    options.option1.summary += "$" + productRow.billInstallment + ".";

                    if (productRow.setupFee > 0) {
                        options.option1.summary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }

                    if (productRow.freeTrialInterval == 0) {
                        options.option1.price += productRow.billInstallment;
                    }

                    options.option1.totalDueToday += productRow.setupFee;
                    if (productRow.freeTrialInterval == 0) {
                        options.option1.totalDueToday += productRow.billInstallment;
                    }
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";
                    if (productRow.freeTrialInterval > 0) {
                        options.option1.summary += " Next payment due on " + dayjs().add(productRow.freeTrialInterval, productRow.freeTrialPeriod).format('MM/DD/YYYY') + ".";
                    } else {
                        options.option1.summary += " Next payment due on " + dayjs().add(productRow.billInterval, productRow.billPeriod).format('MM/DD/YYYY') + ".";
                    }
                } else {
                    if (productRow.freeTrialInterval > 0) {
                        options.option1.summary += "Free trial for " + productRow.freeTrialInterval + " " + productRow.freeTrialPeriod + "(s) and then... ";
                    }
                    options.option1.summary += "$" + variantRow.unitBillInstallment + ".";

                    if (productRow.setupFee > 0) {
                        options.option1.summary += " Plus setup fee of $" + productRow.setupFee + ".";
                    }

                    if (productRow.freeTrialInterval == 0) {
                        options.option1.price += variantRow.unitBillInstallment;
                    }

                    options.option1.totalDueToday += productRow.setupFee;
                    if (productRow.freeTrialInterval == 0) {
                        options.option1.totalDueToday += variantRow.unitBillInstallment;
                    }
                    options.option1.summary += ". Total due today... $" + options.option1.totalDueToday + ".";
                    if (productRow.freeTrialInterval > 0) {
                        options.option1.summary += " Next payment due on " + dayjs().add(productRow.freeTrialInterval, productRow.freeTrialPeriod).format('MM/DD/YYYY') + ".";
                    } else {
                        options.option1.summary += " Next payment due on " + dayjs().add(productRow.billInterval, productRow.billPeriod).format('MM/DD/YYYY') + ".";
                    }
                }
                break;
        }
        return options;
    }, 

  
}

export default ProductEngineUtil

