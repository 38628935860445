import React, { useEffect, useState } from "react";
import "./css/availcalslots.css"
import dayjs from "dayjs";
import PaymentCtrl from "../payment/PaymentCtrl";

function BookingPaymentCtrl({ payInfo, showPaymentMethod, onPayInfoChange, onPaymentComplete }){

    //useEffect(() => {
    //    if (selectedDate != oldSelectedDate){
    //        setSelectedSlot(null);
    //        setOldSelectedDate(selectedDate);
    //    }        
    //},[selectedDate])


    return (
        <>
            <div>
                <PaymentCtrl showPaymentMethod={showPaymentMethod} payInfo={payInfo} onPayInfoChange={(payInfoValue) => { onPayInfoChange(payInfoValue); }} errorList={[]} cardOnly={true} onPaymentComplete={(payInfoValue) => { onPaymentComplete (payInfoValue)}} />
            </div>
        </>
    )
}

export default BookingPaymentCtrl;