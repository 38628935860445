import React, { useContext, useState, useEffect } from 'react';
import '../css/pagebuilderctrl.css';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { DataBrokerContext} from '../PageBuilderCtrl';
import DialogCtrl from '../../dialogctrl/dialogctrl';
import ColorSelector from '../../colors/ColorSelectorCtrl';
import ColorSelectorCtrl from '../../colors/ColorSelectorCtrl';
import ColorFieldCtrl from '../../formcontrols/ColorFieldCtrl';
import SliderCtrl from '../../formcontrols/SliderCtrl';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HeightIcon from '@mui/icons-material/Height';

import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CloseIcon from '@mui/icons-material/Close';

import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import HtmlSizingCtrl from '../../formcontrols/HtmlSizingCtrl';
import FileManager from '../../../pages/FileManager';
import LoaderSpinnerCtrl from '../../loaderspinnerctrl/loaderspinnerctrl';
import CssAiContentCtrl from './CssAiContentCtrl';


function CssImageCtrl({ widgetData, propertyChangeCallback }) {
    const { elements, setElements, currentBreakpoint, orderedBreakpoints, mobileFirst, getMergedStyleToBP, onWidgetClick, onContextMenuItemClick, onWidgetMouseEnter, onWidgetMouseLeave, onWidgetMouseOver, handleOnDragFromList, handleOnDragFromCanvas, handleOnDragOver, handleOnDragLeave, handleOnDrop } = useContext(DataBrokerContext);
    const [widgetObj, setWidgetObj] = useState(widgetData);
    const [openDialog, setOpenDialog] = useState(false);
    const [genericDialogSchema, setGenericDialogSchema] = useState({});            


    function handleOnOtherChange(propertyName,value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    function handleOnOtherChangeNoUpdate(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        //propertyChangeCallback(tmpWidgetObj);
    }


    function handleOnBlur(propertyName, value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj[propertyName] = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }


    function openFileManager(mode) {
        var returnCategory = mode;
        var actionType = "FullPath";
        var msg = 'This is a test';
        setGenericDialogSchema({ title: 'Tag Search', component: (dialogType, setReturnValue) => <FileManager handlerReturnValue={dialogType, setReturnValue} returnFullPath={true}></FileManager>, body: { innerHtml: { __html: msg } }, dialogType: "lookup", maxWidth: "lg", hideBackdrop: false, showCancelBtn: true, showOKBtn: true, cancelBtnText: 'Cancel', okBtnText: 'OK', colorClass: 'fg-modal-success', closeModal: () => { setOpenDialog() }, okModal: (returnValues) => { setOpenDialog(); handleUpdateImage(returnValues, actionType, returnCategory); } })
        setOpenDialog(true);
    }

    function handleUpdateImage(imgUrl, actionType, returnCategory) {
        if (returnCategory == 'design-time') {
            handleOnOtherChange('designSrc', imgUrl);
        } else if (returnCategory == 'run-time') {
            handleOnOtherChange('src', imgUrl);
        }
        
    }

    function handleAiImageChange(value) {
        var tmpWidgetObj = { ...widgetObj };
        tmpWidgetObj.aiImage = value;
        setWidgetObj((...widgetObj) => { return tmpWidgetObj });
        propertyChangeCallback(tmpWidgetObj);
    }

    useEffect(() => {
        setWidgetObj(widgetData);
    }, [widgetData])



    return (
        <>
             <div>
                <div className="fg-pg-panel-sub-section">Image</div>
                <div style={{ backgroundColor: "white", padding: "5px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Image</div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 30px 30px" }}>
                            <div><input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.src || ''} onChange={(e) => { handleOnOtherChangeNoUpdate('src', e.target.value); }} onBlur={(e) => { handleOnBlur('src', e.target.value); }} /></div>
                            <div style={{ textAlign: "center", color: "#757575", cursor:"pointer" }} onClick={() => { openFileManager('run-time'); } }><MoreHorizIcon /></div>
                            <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }}><CloseIcon/></div>
                        </div>
                        <div className="fg-pb-property-sub-label">Design-Time Image</div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 30px 30px" }}>
                            <div><input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.designSrc || ''} onChange={(e) => { handleOnOtherChangeNoUpdate('designSrc', e.target.value); }} onBlur={(e) => { handleOnBlur('designSrc', e.target.value); }} /></div>
                            <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }} onClick={() => { openFileManager('design-time'); }}><MoreHorizIcon /></div>
                            <div style={{ textAlign: "center", color: "#757575", cursor: "pointer" }}><CloseIcon /></div>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "70px 1fr", marginBottom: "5px" }}>
                        <div className="fg-pb-property-sub-label">Alt Text</div>
                        <div>
                            <div><input className="form-control form-control-sm fg-pb-property-field" value={widgetObj.alt} onChange={(e) => { handleOnOtherChange('alt',e.target.value); }} /></div>
                        </div>
                    </div>
                    <div style={{fontSize:"12px",fontWeight:"500"}}>Dynamic sizeable image placeholder:</div>
                    <div style={{fontSize:"12px",marginBottom:"10px"}}>https://dummyimage.com/600x338/aaa/fff</div>
                    {widgetData.hasOwnProperty('aiImage') ?
                        (widgetData.aiImage != null ?
                            <CssAiContentCtrl contentType="Image" aiImageData={widgetData.aiImage} propertyChangeCallback={(value) => { handleAiImageChange(value); }} />
                            : null)
                        : null
                    }
                </div>
            </div>
            {openDialog && <DialogCtrl schema={genericDialogSchema}></DialogCtrl>}
        </>
    );

}

export default CssImageCtrl;

